import React from "react";
import { Link } from "react-router-dom";

const Freemium = () => {
  return (
    <>
      <div className="container content_info_tab">
        {/* <div className="row">
          <div className="col-sm-12 col-md-6">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/freemium/mkt_freemium.svg`}
              alt=""
              className="mx-w-100"
            />
          </div>
          <div className="col-sm-12 col-md-6 text_info_one_container">
            <div className="text_info_one">
              <h6>Ubbitt Freemium es la única plataforma:</h6>
              <ul>
                <li>
                  <i className="icon-check"></i>
                  Te elimina el 100% en tus costos fijos de Call Center Ventas
                </li>
                <li>
                  <i className="icon-check"></i>
                  Cambia todo tu Call Center a CPA
                </li>
                <li>
                  <i className="icon-check"></i>
                  Ubbittiza a tus agentes de Call Center Ventas
                </li>
              </ul>
              <Link to="/empresas">Agenda un Demo</Link>
            </div>
          </div>
        </div> */}
        <div className="row row_sncd_container">
          <div className="col-sm-12 col-md-6 text_info_one_container">
            <div className="text_info_one">
              <h6>Con Ubbitt Freemium:</h6>
              <ul>
                <li>
                  <i className="mini_dots"></i>
                  Cambia tu Call Center de Ventasa costo variable al 100%.
                </li>
                <li>
                  <i className="mini_dots"></i>
                  Paga solo venta cobrada.
                </li>
                <li>
                  <i className="mini_dots"></i>
                  Operación 100% digitalizada.
                </li>
                <li>
                  <i className="mini_dots"></i>
                  Mayor conversión garantizada.
                </li>
              </ul>
              <Link to="/empresas">Agenda un Demo</Link>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/freemium/ui_freemium.png`}
              alt=""
              className="mx-w-100"
            />
          </div>
        </div>
        <hr />
        <div className="features_container">
          <div className="text-center">
            <p class="subttl_section">
              <span>// </span>Features
            </p>
            <h5 class="ttl_section">¿Qué incluye?</h5>
            <div className="row">
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-ecommerce1"></i>
                    </span>
                    Ecommerce & Landing pages
                  </p>
                  <hr />
                  <p className="info_card">
                    Partial or Full ecommerce integration with vendors service
                    catalogue, payment Gateway and full ecommerce purchase
                    process.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-support"></i>
                    </span>
                    On Demand Sales Contact Center
                  </p>
                  <hr />
                  <p className="info_card">
                    Ubbitt Partners are ready to join any Vendor Aquisition
                    campaign or sales team according to the volumen or service
                    level Vendor requires.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-transformation"></i>
                    </span>
                    Digital Transformation of Sales Process
                  </p>
                  <hr />
                  <p className="info_card">
                    Partial or Full ecommerce integration with vendors service
                    catalogue, payment Gateway and full ecommerce purchase
                    process.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-marketplace"></i>
                    </span>
                    Ubbitt Market Place App
                  </p>
                  <hr />
                  <p className="info_card">
                  Aplicación del proveedor de mercado abierto, para acceder a los servicios del mismo dentro de ubbitt Marketplace.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-marketplace"></i>
                    </span>
                    Ubbitt 360
                  </p>
                  <hr />
                  <p className="info_card">
                  Cuadro de mando de informes y análisis en tiempo real de los proveedores.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-mkt"></i>
                    </span>
                    MKT Automation
                  </p>
                  <hr />
                  <p className="info_card">
                    Through CRM partners like Hubspot Ubbitt unleashes full mkt
                    automated workflows and CRM features.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-elearning"></i>
                    </span>
                    E-learning
                  </p>
                  <hr />
                  <p className="info_card">
                    Our online course platform where the sales process and
                    culture of our Vendors is transfered to our partners.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-partner"></i>
                    </span>
                    Partner App
                  </p>
                  <hr />
                  <p className="info_card">
                  Mercado de trabajo de socios, donde las aplicaciones de los proveedores están disponibles para inscripción, formación, recompensas Universidad Ubbitt , KPIS dashbaord , perfil y más.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-security"></i>
                    </span>
                    Cybersecurity
                  </p>
                  <hr />
                  <p className="info_card">
                    Ubbitt is currently PCI Compliant , we are also in current
                    PCI DSS, SOC2, ISO 27001 and other certifications
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-cloud"></i>
                    </span>
                    Cloud Comunication Services
                  </p>
                  <hr />
                  <p className="info_card">
                    Powered by Callpickers Solutions Ubbitt delivers all the
                    power of Cloud Calls to the customers.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-ubbittmessenger"></i>
                    </span>
                    Ubbitt Messenger
                  </p>
                  <hr />
                  <p className="info_card">
                    Multimessenger connected to Whatsapp, Facebook Messenger ,
                    Videocall, Telephone.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="modelo_container">
          <div className="text-center">
            <p class="subttl_section">
              <span>// </span>Modelo de negocio
            </p>
            <h5 class="ttl_section">¿Cómo funciona?</h5>
            <img
              className="w-100"
              src={`${process.env.PUBLIC_URL}/assets/images/freemium/diagrama_freemium.png`}
              alt=""
            />
          </div>
        </div>
        <hr />
        <div className="calculator_container">
          <div className="text-center">
            <p class="subttl_section">
              <span>// </span>Ubbitt Freemium
            </p>
            <h5 class="ttl_section">Calculadora Ubbitt Freemium</h5>
            <h4>Hagamos un ejercicio</h4>
          </div>
          <form action="" className="calculator">
            {/* <h6>
              Inversión Mínima de <span>$300,000</span> por 3 meses.
            </h6> */}
            <div class="row">
              <div class="form-group col-sm-6 col-md-2">
                <label for="inputEmail4">Total de llamadas</label>
                <input type="number" class="form-control" id="inputEmail4" />
              </div>
              <div class="form-group col-sm-6 col-md-2">
                <label for="inputPassword4">Conversión llamadas</label>
                <input type="number" class="form-control" id="inputPassword4" />
              </div>
              <div class="form-group col-sm-6 col-md-2">
                <label for="inputCity">Ventas (U)</label>
                <input type="number" class="form-control" id="inputCity" />
              </div>
              <div class="form-group col-sm-6 col-md-2">
                <label for="inputCity">Conversión</label>
                <input type="number" class="form-control" id="inputCity" />
              </div>
              <div class="form-group col-sm-6 col-md-2">
                <label for="inputZip">Ticket promedio</label>
                <input type="number" class="form-control" id="inputZip" />
              </div>
              <div class="form-group col-sm-6 col-md-2">
                <label for="inputZip">Ventas ($)</label>
                <input type="number" class="form-control" id="inputZip" />
              </div>
            </div>
            <div className="row set-center">
            <div class="form-group col-sm-6 col-md-4">
                <label for="inputEmail4">CPA (15%)</label>
                <input type="number" class="form-control" id="inputEmail4" disabled />
              </div>
              <div class="form-group col-sm-6 col-md-4">
                <label for="inputPassword4">ROI</label>
                <input type="number" class="form-control" id="inputPassword4" disabled />
              </div>
            </div>
          </form>
        </div>
        <div className="agenda_demo">
          <div className="row">
            <div className="col-sm-12 col-md-6 set-center">
              <img
                className=""
                src={`${process.env.PUBLIC_URL}/assets/images/view_empresas/dudas.svg`}
                alt=""
              />
            </div>
            <div className="col-sm-12 col-md-6 set-center-left">
              <div>
                <h2>
                  ¿Ahora te parece más <br /> interesante?
                </h2>
                <p>
                  Reduce costos e incrementa tus ventas con Ubbitt Freemium.
                </p>
                <Link to="/empresas">Agenda un Demo</Link>
              </div>
            </div>
          </div>
          <div className="bg_gray"></div>
        </div>
      </div>
    </>
  );
};

export default Freemium;
