import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";

// Common

import Footer from "../common/footer/Footer";

// Components
import Video from "../components/Empresas/Video";
import SobreNosotros from "../components/Empresas/SobreNosotros";
import Solucion from "../components/Empresas/Solucion";
import Garantia from "../components/Empresas/Garantia";
import Planes from "../components/Empresas/Planes";
import Dudas from "../components/Empresas/Dudas";
import FormEmpresas from "../components/Empresas/FormEmpresas";
import Clientes from "../components/Empresas/Clientes";
import NavProductos from "../common/header/NavProductos";

const Empresas = () => {
  return (
    <>
      <SEO title="Empresas" />
      <main className="page-wrapper">
      
        <NavProductos/>
        <div className="container empresas_view">
          <div className="row flex-row-reverse">
            <div className="col-sm-12 col-lg-4">
              <div className="mobile_elm">
                <p className="empresas_txt">Empresas</p>
                <hr />
              </div>
            <FormEmpresas />
            </div>
            <div className="col-sm-12 col-lg-8">
       

              <Video />
              <SobreNosotros />
              <Solucion />
              <Garantia />
              <Clientes/>
              <Planes />
              <Dudas />
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Empresas;
