import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";

// Common
import NavLegal from "../common/header/NavLegal";
import Footer from "../common/footer/Footer";

const Cybersecurity = () => {
  return (
    <>
      <SEO title="Übbitt" />
      <main className="page-wrapper">
        <NavLegal />
        <div className="container_cyber">
          <div className="container   text-center">
            {/* <p>Cybersecurity compliance</p> */}
            <p>
              <span>// </span>Cumplimiento, ciberseguridad y competitividad
            </p>
            <h6>Cybersecurity compliance</h6>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <img
                  className="logo_cyber w-50"
                  src={`${process.env.PUBLIC_URL}/assets/images/ciberseguridad/iso.png`}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <img
                  className="logo_cyber w-50"
                  src={`${process.env.PUBLIC_URL}/assets/images/ciberseguridad/ssl.png`}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <img
                  className="logo_cyber w-50"
                  src={`${process.env.PUBLIC_URL}/assets/images/ciberseguridad/pci.png`}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Cybersecurity;
