import React from "react";

const SociosDescripcion = () => {
    return (
        <div className="socios__descripcion">
            <img src={`${process.env.PUBLIC_URL}/assets/images/logo_soyubbitt_socios.svg`} alt="" />
            <h4>Genera MÁS ingresos con Ubbitt</h4>
            <p>Únete y forma parte del mejor equipo.</p>
            <ul>
                <li>Genera ingresos mayores a 20 mil pesos mensuales.</li>
                <li>Sólo 6 hrs al día.</li>
                <li>Capacitación pagada.</li>
                <li>Contratación directa: sueldo base y prestaciones de ley.</li>
             
            </ul>
        </div>
    )
}

export default SociosDescripcion;