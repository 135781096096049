import React from "react";
import SEO from "../common/SEO";

// Common
import NavLegal from "../common/header/NavLegal";
import Footer from "../common/footer/Footer";

const AvisoDePrivacidad = () => {
  return (
    <>
      <SEO title="Aviso de privacidad" />

      <main className="page-wrapper">
        <NavLegal />

        <div className="container">
          <div className="container_legales col-10">
            <h3 className="main_ttl_legal">AVISO DE PRIVACIDAD INTEGRAL</h3>
            En UBBITT, estamos comprometidos con la protección de los datos
            personales de nuestros usuarios. Cualquier dato personal que sea
            compartido con nosotros será almacenado de forma segura, por lo que
            el presente Aviso de Privacidad tiene como fin señalar de manera
            puntual, la forma en la que recopilamos datos y cómo los usamos,
            además de explicar en detalle el tipo de datos que se recopilan de
            conformidad con la Ley Federal de Protección de Datos Personales en
            Posesión de los Particulares, su reglamento, los lineamientos de
            aviso de privacidad y demás disposiciones legales aplicables.
            <h5>RESPONSABLE DEL TRATAMIENTO DE LOS DATOS PERSONALES.</h5>
            El responsable de los datos personales que usted proporciona es
            UBBITT S.A. DE C.V. (en lo sucesivo UBBITT) domiciliada en Oxford
            36, Colonia Juárez, Alcaldía Cuauhtémoc, Ciudad de México, C.P.
            06600, quien funge como agente autorizado del servicio del cual
            Usted desea información, y al respecto le informa lo siguiente:
            <h5>TIPOS DE DATOS</h5>
            UBBITT solicitará información de Usted para poder llevar a cabo las
            finalidades de: Cotización del servicio, Emisión de propuestas,
            Contactarlo para concretar la venta del servicio, Direccionarlo con
            la empresa prestadora del servicio a los fines del contrato final,
            por lo que, para cumplir con las finalidades del tratamiento
            señaladas en el presente Aviso de Privacidad, es necesario el
            tratamiento de los siguientes datos personales:
            <h5>Datos personales consecuencia del servicio</h5>
            Los datos personales que UBBITT va a tratar pertenecen a las
            siguientes categorías: Datos de identificación personal y contacto:
            Nombre y apellidos completos, domicilio, nacionalidad, correo
            electrónico, fecha de nacimiento, genero, teléfono de contacto.
            Datos personales de terceros: en el caso solicitar alguna cotización
            o información para un tercero. Al proporcionar esta información
            Usted reconoce haber informado a dichos terceros sobre el uso de sus
            datos y haber obtenido de forma previa el consentimiento de estos
            últimos para que UBBITT pueda tratarlos para los fines antes
            señalados. Datos de menores de edad: UBBITT no recaba información
            directamente de menores de edad por lo que, de ser necesario, dicha
            información deberá ser proporcionada, en su caso, por el padre,
            tutor y/o persona con patria potestad. En aquellos casos en los que
            se identifique que un menor de edad ha proporcionado su información
            personal sin el consentimiento de la persona que ejerce su patria
            potestad, se procederá a la cancelación de dicha información. En
            caso de que UBBITT solicite datos para contactarlo por email o
            WhatsApp: Cuando como Usted, como el Titular de los datos acepta
            suscribirse a los mensajes de comunicación de ofertas y de marketing
            mediante WhatsApp, se recabarán los siguientes datos: Nombre
            completo y número de teléfono. No se solicitarán datos sensibles.
            <h5>FINALIDADES DEL TRATAMIENTO</h5>
            Los datos personales recabados por UBBITT a través de medios
            electrónicos, serán utilizados para los siguientes fines: Llevar a
            cabo el proceso de Cotización del servicio al cual Usted desea
            información. Brindar al Titular toda la información del servicio que
            solicita, mediante el contacto continuo, seguimiento, actualización
            y confirmación y/o cierre de venta en cuanto a los servicios que
            pretende contratar, así como para fines de facturación.
            <h5>Finalidades secundarias</h5>
            De manera adicional, UBBITT utilizará sus datos personales para
            llevar a cabo alguna o todas las siguientes finalidades: i) fines
            mercadotécnicos; ii) fines publicitarios; iii) conocer la
            experiencia a través de encuestas, evaluaciones o similares; y /o,
            iv) hacer llegar promociones u ofertas exclusivas. Si usted no desea
            que UBBITT trate sus datos personales para alguna de las finalidades
            descritas en este apartado, por favor envíe un correo electrónico a:{" "}
            <a href="mailto:soporte@ubbitt.com">soporte@ubbitt.com</a>, dentro
            de los siguientes 5 (cinco) días manifestando su negativa, de lo
            contrario se entenderá que el titular otorga su consentimiento.
            Dicha negativa no será motivo para que UBBITT rechace la relación de
            servicios. Es importante que tenga presente que los datos personales
            proporcionados se conservarán mientras se mantenga la relación con
            el titular y seguirá siendo válido incluso cuando hubiere terminado
            la relación, lo que significa que UBBITT podrá seguir tratando sus
            datos personales necesarios para cumplir con las presentes
            finalidades secundarias.
            <h5>TRANSFERENCIA DE DATOS PERSONALES</h5>
            UBBITT implementará las medidas de seguridad, técnicas,
            administrativas y físicas, necesarias para procurar la integridad de
            sus datos personales y evitar su daño, pérdida, alteración,
            destrucción o el uso, acceso o tratamiento no autorizado. Únicamente
            el personal autorizado, que ha cumplido y observado los
            correspondientes requisitos de confidencialidad, podrá participar en
            el tratamiento de sus datos personales. El personal autorizado tiene
            prohibido permitir el acceso de personas no autorizadas y utilizar
            sus datos personales para fines distintos a los establecidos en el
            presente Aviso de Privacidad. La obligación de confidencialidad de
            las personas que participan en el tratamiento de sus datos
            personales subsiste aun después de terminada la relación con UBBITT.
            Por ello, UBBITT sólo podrá transferir los datos personales del
            titular, tanto a entidades nacionales como extranjeras, sin requerir
            su consentimiento en los siguientes supuestos específicos: Personas
            morales necesarias para la prestación del servicio que se pretende
            contratar. Autoridades competentes y terceros en los casos
            legalmente previstos. A las empresas del grupo, afiliadas o
            subsidiarias, para que éstas le puedan contactar, directa o
            indirectamente, para llevar a cabo actividades de promoción y/u
            ofrecimiento de productos y/o servicios que dichas empresas pueden
            comercializar y/o prestar de manera conjunta con UBBITT o de manera
            independiente. Le informamos que las empresas del grupo operamos
            bajo las mismas políticas de privacidad que cumplen con las
            disposiciones aplicables correspondientes.
            <h5>DERECHOS ARCO Y MECANISMO PARA EJERCERLOS</h5>
            De conformidad con la ley, el titular tiene en todo momento el
            derecho a conocer que datos personales se recopilan, para que se
            utilizan y las condiciones de uso que se les da estipuladas en los
            incisos anteriormente señalados, así como a ejercer cualquiera de
            los derechos de Acceso, Rectificación, Cancelación u Oposición
            (ARCO) y revocar su consentimiento para el tratamiento de sus datos
            personales. En el ejercicio de los derechos antes señalados, el
            titular podrá solicitar la corrección de su información personal en
            caso de que esté desactualizado, sea inexacta o incompleta; de igual
            manera, tiene derecho a que su información se elimine de nuestros
            registros o base de datos cuando se considere que la misma no está
            siendo utilizada de manera adecuada; así como también a oponerse al
            uso de datos personales para fines específicos.
            <h2>
              {" "}
              Para ejercer los Derechos ARCO, se deberá enviar la solicitud vía
              mail a <a href="mailto:soporte@ubbitt.com">
                soporte@ubbitt.com
              </a>{" "}
              con la información respectiva que contenga:
            </h2>
            Nombre
            <br /> <br />
            Domicilio
            <br /> <br />
            Documento que acredite su persona.
            <br /> <br />
            Medio en el que se le pueda comunicar la resolución de su solicitud.
            <br /> <br />
            Señalamiento de los datos personales que quiere rectificar,
            cancelar, oponerse y acceder, en caso de rectificación deberá
            acompañar su solicitud de la documentación que sustente su petición
            y las modificaciones a realizarse.
            <br /> <br />
            Es importante dejar claro, que muchos de estos datos no podrán dejar
            de tratarse mientras esté vigente la relación de servicios, por
            cuanto es indispensable para ésta. Una vez recibida la solicitud, se
            revisará por el área legal de la compañía y se emitirá una
            resolución que se hará de conocimiento del solicitante vía mail, en
            un plazo de 5 (cinco) días, en caso de ser aceptada será efectiva
            dentro de los 15 días hábiles siguientes a la fecha en que se emita
            la respuesta. Los plazos antes referidos podrán ser ampliados una
            sola vez, por un periodo igual, siempre y cuando así lo justifiquen
            las circunstancias del caso. En los casos de rectificación o
            cancelación o de solicitudes de oposición al tratamiento, se
            informará la resolución correspondiente debidamente fundada de
            conformidad con lo establecido por la Ley Federal de Protección de
            Datos en Posesión de los Particulares. Además, es importante tener
            en cuenta que no en todos los casos se podrá atender su solicitud o
            concluir el uso de forma inmediata, es posible que por alguna
            obligación legal sea necesario seguir tratando los datos personales.
            <h5>LIMITACIÓN DEL USO O DIVULGACIÓN DE LOS DATOS PERSONALES</h5>
            El titular podrá limitar el uso o divulgación de sus datos
            personales enviando su solicitud a la dirección de correo:{" "}
            <a href="mailto:soporte@ubbitt.com">soporte@ubbitt.com</a> dentro de
            los 5 días siguientes al conocimiento de éste aviso. Los requisitos
            para acreditar su identidad, así como el procedimiento para atender
            su solicitud se regirán por los mismos criterios señalados en el
            apartado anterior. En caso de que su solicitud resulte procedente,
            UBBITT lo registrará en el listado de exclusión propio y ante la
            Procuraduría Federal de Protección al Consumidor (PROFECO).
            <h5>COOKIES Y/O WEB BEACONS</h5>
            Le informamos que en nuestros sitios de internet y aplicaciones
            podemos hacer uso de cookies, web beacons y otras tecnologías de
            rastreo que nos permiten cumplir con las finalidades informadas en
            el presente Aviso de Privacidad. Todos nuestros sitios cuentan con
            una política de cookies aplicable.
            <h5>MODIFICACIONES</h5>
            UBBITT, se reserva el derecho de actualizar periódicamente el
            presente Aviso de Privacidad para reflejar los cambios en nuestras
            prácticas de información. Es responsabilidad del titular revisar
            periódicamente el contenido del Aviso de Privacidad en donde se
            publicarán los cambios realizados conjuntamente con la fecha de la
            última actualización. UBBITT, entenderá que de no expresar lo
            contrario, significa que el titular ha leído, entendido y acordado
            los términos ahí expuestos, lo que constituye su consentimiento a
            los cambios y/o actualizaciones respecto al tratamiento de sus Datos
            Personales.
            <h5>INFORMACIÓN COMPLEMENTARIA</h5>
            Si usted tiene dudas, quejas, reclamaciones o desea solicitar
            cualquier aclaración en relación con el tratamiento de sus datos
            personales, puede escribirnos en cualquier momento al siguiente
            correo electrónico: soporte@ubbitt.com. Como titular de datos
            personales, usted tiene el derecho para acudir al Instituto Nacional
            de Transparencia, Acceso a la Información y Protección de Datos
            Personales (INAI), si considera que ha sido vulnerado su derecho a
            la protección de datos personales.
            <br /> <br />
            Última actualización: Septiembre 2021
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default AvisoDePrivacidad;
