import React from "react";

import { Link } from "react-router-dom";

const Beyond = () => {
  return (
    <>
      <div className="container content_info_tab">
        <ul className="ul_beyond">
          <li>
            Somos la única plataforma de Campañas de Adquisición de Venta.{" "}
          </li>
          <li>Crea campañas de cobranza , renovación y cross selling. </li>
          <li>
            Incrementa el flujo de efectivo que tu cartera de clientes produce.{" "}
          </li>
          <li>
            Elimina los costos fijos y solo paga Costo por Adquisición del monto
            cobrado.
          </li>
        </ul>
        <br />
        <br />
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <div className="card_beyond">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/beyond/tel.svg`}
                alt=""
              />
              <hr />
              <p>
                Ahorra entre 1 a 3 millones al año en un Call Center.{" "}
                <span>¡Nosotros lo incluimos!</span>
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="card_beyond">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/beyond/circles.svg`}
                alt=""
              />
              <hr />
              <p>Solo cobramos comisión por lo pagado.</p>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="card_beyond">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/beyond/chart.svg`}
                alt=""
              />
              <hr />
              <p>Visualiza tus analytics en tiempo real.</p>
            </div>
          </div>
        </div>
        <br />
        <br />
        <p class="subttl_section">
          <span>// </span>Ubbitt Beyond
        </p>
        <h5 class="ttl_section">Soluciones</h5>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/beyond/mac_beyond.svg`}
              alt=""
              className="mx-w-100"
            />
          </div>
          <div className="col-sm-12 col-md-6 text_info_one_container">
            <div className="text_info_one">
              <h6>¡Nosotros lo hacemos posible!</h6>
              <ul>
                <li>
                  <i className="icon-check"></i>
                  Mas conversión en cartera!. Maximiza tus ingreso por cobranza y renovación.
                </li>
                <li>
                  <i className="icon-check"></i>
                  Fuerza de ventas digital Ondemand en Costo por Adquisición. 
                </li>
                <li>
                  <i className="icon-check"></i>
                  Elimina costos fijos de cobranza y renovación y conviértelos en costo variable.
                </li>
              </ul>
              <Link to="/empresas">Agenda un Demo</Link>
            </div>
          </div>
        </div>
        <br />
        <br />
        <p className="ttl_transacciones">
          Más de <span>5000 transacciones</span> al rededor del mundo y más de{" "}
          <span>$300 millones</span>
          de pesos en ventas en Ubbitt.
        </p>
        <br />
        <br />
        <img
          className="img_world d-block mx-auto"
          src={`${process.env.PUBLIC_URL}/assets/images/beyond/world_beyond.svg`}
          alt=""
        />
        <br />
        <br />
        <div className="features_container">
          <div className="text-center">
            <p class="subttl_section">
              <span>// </span>Features
            </p>
            <h5 class="ttl_section">¿Qué incluye?</h5>
            <div className="row">
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-ecommerce1"></i>
                    </span>
                    Ecommerce & Landing pages
                  </p>
                  <hr />
                  <p className="info_card">
                    Partial or Full ecommerce integration with vendors service
                    catalogue, payment Gateway and full ecommerce purchase
                    process.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-support"></i>
                    </span>
                    On Demand Sales Contact Center
                  </p>
                  <hr />
                  <p className="info_card">
                    Ubbitt Partners are ready to join any Vendor Aquisition
                    campaign or sales team according to the volumen or service
                    level Vendor requires.
                  </p>
                </div>
              </div>

              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-marketplace"></i>
                    </span>
                    Ubbitt Market Place App
                  </p>
                  <hr />
                  <p className="info_card">
                    Vendor open market app to acces Vendor services within
                    ubbitt Marketplace.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-performance"></i>
                    </span>
                    Digital performance MKT
                  </p>
                  <hr />
                  <p className="info_card">
                    Digital marketing where brands only pay Ubbitt campaigns
                    once their business goals are met or when specific actions
                    are completed, such as sales or leads.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-support"></i>
                    </span>
                    On Demand Sales Contact Center
                  </p>
                  <hr />
                  <p className="info_card">
                    Ubbitt Partners are ready to join any Vendor Aquisition
                    campaign or sales team according to the volumen or service
                    level Vendor requires.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-transformation"></i>
                    </span>
                    Digital Transformation of Sales Process
                  </p>
                  <hr />
                  <p className="info_card">
                    Partial or Full ecommerce integration with vendors service
                    catalogue, payment Gateway and full ecommerce purchase
                    process.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-ubbitt360"></i>
                    </span>
                    Ubbitt 360
                  </p>
                  <hr />
                  <p className="info_card">
                    Vendor real time reporting and analytics dashbaord.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-mkt"></i>
                    </span>
                    MKT Automation
                  </p>
                  <hr />
                  <p className="info_card">
                    Through CRM partners like Hubspot Ubbitt unleashes full mkt
                    automated workflows and CRM features.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-elearning"></i>
                    </span>
                    E-learning
                  </p>
                  <hr />
                  <p className="info_card">
                    Our online course platform where the sales process and
                    culture of our Vendors is transfered to our partners.
                  </p>
                </div>
              </div>

              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-partner"></i>
                    </span>
                    Partner App
                  </p>
                  <hr />
                  <p className="info_card">
                    Partners Job Market Place where vendor apps are available
                    for sign up, training, rewards Ubbitt University , KPIS
                    dashbaord , Profile and more.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-security"></i>
                    </span>
                    Cybersecurity
                  </p>
                  <hr />
                  <p className="info_card">
                    Ubbitt is currently PCI Compliant , we are also in current
                    PCI DSS, SOC2, ISO 27001 and other certifications
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-cloud"></i>
                    </span>
                    Cloud Comunication Services
                  </p>
                  <hr />
                  <p className="info_card">
                    Powered by Callpickers Solutions Ubbitt delivers all the
                    power of Cloud Calls to the customers.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-ubbittmessenger"></i>
                    </span>
                    Ubbitt Messenger
                  </p>
                  <hr />
                  <p className="info_card">
                    Powered by Callpickers Solutions Ubbitt delivers all the
                    power of Cloud Calls to the customers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <p className="ttl_transacciones">
          <span>Disminuye las cuentas incobrables, ahorra costos</span>{" "}
          automatizando tu cobranza única o recurrente,{" "}
          <span>retén a tus clientes, y recupera antigüos clientes.</span>
        </p>
        <br />
        <br />
        <div className="modelo_container">
          <div className="text-center">
            <p class="subttl_section">
              <span>// </span>Modelo de negocio
            </p>
            <h5 class="ttl_section">¿Cómo funciona?</h5>
            <img
              className="w-100"
              src={`${process.env.PUBLIC_URL}/assets/images/beyond/diagrama_beyond.png`}
              alt=""
            />
          </div>
        </div>
        <br />
        <br />
        <div className="calculator_container">
          <div className="text-center">
            <p class="subttl_section">
              <span>// </span>Solicita un demo
            </p>
            <h5 class="ttl_section">Candidato</h5>
            <h4>
              ¡ Empieza a maximizar tu cobranza en 3 semanas a partir de tu
              contratación!
            </h4>
          </div>
          <form action="" className="calculator">
            {/* <h6>
              Inversión Mínima de <span>$300,000</span> por 3 meses.
            </h6> */}
            <div class="row">
              <div class="form-group col-sm-6 col-md-2">
                <label for="inputEmail4">Contactos para gestión</label>
                <input type="number" class="form-control" id="inputEmail4" />
              </div>
              <div class="form-group col-sm-6 col-md-2">
                <label for="inputPassword4">Llamadas totales</label>
                <input type="number" class="form-control" id="inputPassword4" />
              </div>
              <div class="form-group col-sm-6 col-md-2">
                <label for="inputCity">% Llamadas efectivas</label>
                <input type="number" class="form-control" id="inputCity" />
              </div>
              <div class="form-group col-sm-6 col-md-2">
                <label for="inputZip">Ticket promedio ($))</label>
                <input type="number" class="form-control" id="inputZip" />
              </div>
              <div class="form-group col-sm-6 col-md-2">
                <label for="inputCity">% Llamadas efectivas</label>
                <input type="number" class="form-control" id="inputCity" />
              </div>
              <div class="form-group col-sm-6 col-md-2">
                <label for="inputZip">% Cobranza efectiva</label>
                <input type="number" class="form-control" id="inputZip" />
              </div>
            </div>
            <div className="row set-center">
              <div class="form-group col-sm-6 col-md-6">
                <label for="inputEmail4">INGRESOS</label>
                <input
                  type="number"
                  class="form-control"
                  id="inputEmail4"
                  disabled
                />
              </div>
            </div>
          </form>
        </div>
        <div className="agenda_demo">
          <div className="row">
            <div className="col-sm-12 col-md-6 set-center">
              <img
                className=""
                src={`${process.env.PUBLIC_URL}/assets/images/view_empresas/dudas.svg`}
                alt=""
              />
            </div>
            <div className="col-sm-12 col-md-6 set-center-left">
              <div>
                <h2>¡Conócenos!</h2>
                <p>
                  Sorpréndete con lo que <br /> Übbitt tiene para ti.
                </p>
                <Link to="/empresas">Agenda un Demo</Link>
              </div>
            </div>
          </div>
          <div className="bg_gray"></div>
        </div>
      </div>
    </>
  );
};

export default Beyond;
