import React, { Component } from "react";

class JuntaDirectiva extends Component {
  render() {
    return (
      <div className="juntadirectiva-wrapper">
        {/* <br />
        <br /> */}
        <p className="fst_info">
          La junta directiva es una combinación sólida y equilibrada de líderes
          mundiales de la industria cuyo conocimiento excepcional y considerable
          experiencia guían y respaldan estratégicamente la entrega de valor a
          largo plazo para la Compañía.l
        </p>

        <div className="row row_junta_directiva">
          <div className="col-sm-12 col-md-4">
            <div className="card_juntadirectiva">
              <div>
                <h1>Luis Nieto</h1>
                <span>CEO</span>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="card_juntadirectiva">
              <div>
                <h1>Fernanda Valdéz</h1>
                <span>HR Manager</span>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="gobierno--documentacion">
          <div className="text-center">
            <p class="subttl_section">
              <span>// </span>Gobierno corporativo
            </p>
            <h5 class="ttl_section">Documentación</h5>
          </div>
          <div className="row set-center">
            <div className="col-sm-12 col-md-2">
              <div className="card_docu">
                <div>
                  <i className="icon-pdf"></i>
                  <p>Código de ética</p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-2">
              <div className="card_docu">
                <div>
                  <i className="icon-pdf"></i>
                  <p>Politica anticorrupción</p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-2">
              <div className="card_docu">
                <div>
                  <i className="icon-pdf"></i>
                  <p>Valores de Gobierno corporativos</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="gobierno--contacto">
          <div className="text-center">
            <p class="subttl_section">
              <span>// </span>Contacto
            </p>
            <h5 class="ttl_section">Ponerse en contacto con la Junta</h5>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <p>
                Los accionistas y otras partes interesadas en comunicarse
                directamente con el Presidente de la Junta o con los directores
                no administrativos como grupo pueden hacerlo escribiendo a:
              </p>
              <p>
                Presidente de la Junta <br /> Compañía Ubbitt y <br />
                Avenida Montes Urales 424, Colonia Lomas de Chapultepec, V
                Sección, <br />
                Alcaldía Miguel Hidalgo, C.P. 11000, Ciudad de México <br />
              </p>
              <p>
                El Comité de Gobernanza y Nominación de la Junta ha aprobado un
                proceso para manejar las cartas recibidas por la Compañía y
                dirigidas a los miembros de la Junta que no pertenecen a la
                gerencia.
              </p>
            </div>
            <div className="col-sm-12 col-md-6">
              <p>
                Bajo ese proceso, el Secretario Corporativo de la Compañía
                revisa toda la correspondencia y envía regularmente a la Junta
                un resumen de toda la correspondencia y copias de toda la
                correspondencia que, en la opinión del Secretario Corporativo,
                trata sobre las funciones de la Junta o los comités. de los
                mismos o que de otro modo determine requiere su atención. Los
                directores pueden en cualquier momento revisar un registro de
                toda la correspondencia recibida por la Compañía que está
                dirigida a los miembros de la Junta y solicitar copias de dicha
                correspondencia.
              </p>
              <p>
                Las inquietudes relacionadas con la contabilidad, los controles
                internos o los asuntos de auditoría se comunican de inmediato al
                departamento de auditoría interna de la Compañía y se manejan de
                acuerdo con los procedimientos establecidos por el Comité de
                Auditoría con respecto a dichos asuntos.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default JuntaDirectiva;
