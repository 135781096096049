import React, { useState }  from "react";
import SEO from "../common/SEO";

import Nav from "../common/header/Nav";
import Footer from "../common/footer/Footer";
import {Products} from "../data/products/products";
// import GridProducts from "../components/OurProducts/GridProducts";

const Home = () => {
  const [query, setQuery] = useState("");
  console.log(query);
  return (
    <>
      <SEO title="Übbitt" />
      <main className="page-wrapper">
        <Nav />

        <div className="container ourproducts">
          <div className="col-12 mx-auto">
            <img
              className="logo"
              src={`${process.env.PUBLIC_URL}/assets/images/logo_ubbitt_nav.svg`}
            />
            {/* <SearchBar /> */}
            {/* <GridProducts /> */}
            <div className="input-group input-group-searchbar">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  <i className="icon-buscar"></i>
                </span>
              </div>
              <input
                type="text"
                className="form-control input_search"
                placeholder="Busca un servicios de internet, seguros, telefonía…"
                aria-label="Buscar"
                aria-describedby="basic-addon1"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <p className="search_text">
              Cotiza en Línea o contacta Atención Personal.Ubbitt te ayudara de
              forma fácil y rápida a encontrar <br /> el servicio que deseas
              contratar.
            </p>
            <div className="row grid__productos">
                {Products.filter(product => product.name.toLocaleLowerCase().includes(query)).map((product) => (
                     <div
                     key={product.id}
                     className="col-12 col-sm-6 col-md-6 col-lg-4 wrapper_col--card-product"
                   >
                     <div className="card_producto default_style">
                       <div className="header_card_pro">
                         <span>{product.name}</span>
                         <i className={product.icon}></i>
                       </div>
                       <hr />
                       <img
                         src={`${process.env.PUBLIC_URL + product.img}`}
                         alt={product.name}
                       />
                       <div className="row_reviews">
                         <div className="app_score">
                           <div className="review_stars">
                             <i className="icon-star_card"></i>
                             <i className="icon-star_card"></i>
                             <i className="icon-star_card"></i>
                             <i className="icon-star_card"></i>
                             <i className="icon-star_card"></i>
                           </div>
                           <span>{product.stars}</span>
                         </div>
                         <div className="user_reviews">
                           <div className="app_review">
                             <i className="icon-comentarios"></i>
                             <span>{product.reviews}</span>
                           </div>
                           <span className="separator">|</span>
                           <div className="app_users">
                             <i className="icon-user_card"></i>
                             <span>{product.users}</span>
                           </div>
                         </div>
                       </div>
                       <div className="row_btn_actions">
                         <a href={"tel:+" + product.call_center}>
                           <i class="icon-support"></i> Atención personal
                         </a>
                         <a href={product.ecommerce} target="_blank">
                           <i class="icon-ecommerce"></i> Cotiza en línea
                         </a>
                       </div>
                     </div>
                   </div>
                ))}
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Home;
