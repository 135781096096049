import React, { Component } from "react";

class MxFlag extends Component {
  render() {
    return (
      <span class="icon-flag_mex">
        <span class="path1"></span>
        <span class="path2"></span>
        <span class="path3"></span>
        <span class="path4"></span>
        <span class="path5"></span>
        <span class="path6"></span>
        <span class="path7"></span>
        <span class="path8"></span>
        <span class="path9"></span>
        <span class="path10"></span>
        <span class="path11"></span>
      </span>
    );
  }
}

export default MxFlag;
