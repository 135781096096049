import React from "react";

const Garantia = () => {
    return (
        <div className="warranty__container">
            {/* <p className="subttl_section">
                <span>// </span>
                Garantía
            </p> */}
            <h5 className="ttl_section">
                ¿Qué puedo lograr con Ubbitt?
            </h5>

            <img className="garantia-img mb-60" src={`${process.env.PUBLIC_URL}/assets/images/empresas/empresas_2.png`} alt="" />
            <p className="info">
                Con Übbitt puedes recuperar a antiguos clientes, mejorar los procesos de atención y optimizar las campañas de marketing.</p>
            <p className="info">El funcionamiento está plenamente orientado hacia el cliente con el objetivo de mantener una atención personalizada y diagnosticar sus necesidades.</p>
            <p className="info">Unificamos la gestión de inversión digital, fuerza de ventas humana, canal digital, ecommerce y la administración de relaciones con el cliente.</p>
        </div>
    )
}

export default Garantia;