import React from "react";

const Dudas = () => {
  return (
    <div className="dudas__container">
      <div className="row">
        <div className="col-6 col-md-4">
          <img
            className="w-100"
            src={`${process.env.PUBLIC_URL}/assets/images/view_empresas/dudas.svg`}
            alt=""
          />
        </div>
        <div className="col-12 col-md-8 dudas_txt">
          <h1>
            <span>¿Tienes dudas?</span> <br />
            Te ayudamos a encontrar la mejor solución para tu empresa.
          </h1>

          <a href="" className="contact_btn">
            Contáctanos
          </a>
        </div>
      </div>
    </div>
  );
};

export default Dudas;
