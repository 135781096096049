import React from "react";

const SobreNosotros = () => {
  return (
    <div className="about_us">
      <p className="subttl_section">
        <span>// </span>
        Garantiza ventas con tu inversión
      </p>
      <h5 className="ttl_section">¿Qué es Übbitt?</h5>
      <p className="info">
        Ubbitt es el primer <span>Marketplace</span> de tres puntos de este
        tipo. Ubbitt conecta a los <span>proveedores</span> de servicios
        intangibles con los <span>clientes</span> a través de{" "}
        <span>socios</span> expertos en ventas.
      </p>
      {/* <img
        className="w-100"
        src={`${process.env.PUBLIC_URL}/assets/images/empresas/empresas_1.png`}
        alt=""
      /> */}
      <div className="row">
        <div className="col-12">
          <img
            className="w-100"
            src={`${process.env.PUBLIC_URL}/assets/images/view_empresas/planes_empresas.png`}
            alt=""
          />
        </div>
        {/* <div className="col-sm-12 col-md-4">
        <img
            className="w-100"
            src={`${process.env.PUBLIC_URL}/assets/images/view_empresas/usuario_empresas.png`}
            alt=""
          />
        </div>
        <div className="col-sm-12 col-md-4">
        <img
            className="w-100"
            src={`${process.env.PUBLIC_URL}/assets/images/view_empresas/vendor_empresas.png`}
            alt=""
          />
        </div> */}
      </div>
    </div>
  );
};

export default SobreNosotros;
