import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
import Premium from "./Premium";
import Beyond from "./Beyond";
import Freemium from "./Freemium";

const Form = () => {
  const [plan, setPlan] = useState("freemium");

  const [freemiumCalendarVisible, setFreemiumCalendarVisible] = useState(false);
  const [beyondCalendarVisible, setBeyondCalendarVisible] = useState(false);
  const [premiumCalendarVisible, setPremiumCalendarVisible] = useState(false);

  useEffect(() => {
    plan === "freemium"
      ? setFreemiumCalendarVisible(true)
      : setFreemiumCalendarVisible(false);
    plan === "beyond"
      ? setBeyondCalendarVisible(true)
      : setBeyondCalendarVisible(false);
    plan === "premium"
      ? setPremiumCalendarVisible(true)
      : setPremiumCalendarVisible(false);
  }, [plan]);

  const handleOnChange = (e) => {
    console.log(e.target.value);
    setPlan(e.target.value);
  };

  const makeFirstLetterCapital = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const renderResult = () => {
    let result;
    plan === "selectPlan"
      ? (result = "Agenda tu plan Ubbitt")
      : (result = makeFirstLetterCapital(plan));
    return result;
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const initialFormData = Object.freeze({
    nombre: "",
    cargo: "",
    email: "",
    tel: "",
    empresa: "",
    industria: "",
    nEmpleados: "",
    pais: "",
    plan: "",
    terminos: "",
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, updateFormData] = React.useState(initialFormData);
  const handleChange = (e) => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const onSubmit = (data) => {
    console.log(JSON.stringify(data));
    // userData = JSON.stringify(data);
    // console.log(userData.Plan);

    handleShow();
    // console.log(formData);
    reset();
  };
  console.log(errors);

  return (
    <>
      <div className="empresas__form">
        <form onSubmit={handleSubmit(onSubmit)} id="form_empresas">
          <h5 className="text-center">Regístrate y solicita un demo</h5>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Nombre"
              name="nombre"
              required
              {...register("Nombre", { required: true })}
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="cargo"
              required
              placeholder="Cargo"
              {...register("Cargo", { required: true })}
            />
          </div>

          <div className="form-group">
            <input
              type="email"
              className="form-control"
              name="email"
              required
              placeholder="Correo electrónico"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                },
              })}
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="tel"
              required
              placeholder="Teléfono"
              {...register("Teléfono", { required: true })}
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Empresa"
              name="empresa"
              required
              {...register("Empresa", { required: true })}
            />
          </div>

          <div className="form-group">
            <select
              className="form-control"
              defaultValue=""
              name="industria"
              required
              {...register("Industria", { required: true })}
            >
              <option value="" disabled>
                Industria
              </option>

              <option value="Agricultura, plantaciones y sectores rurales">
                Agricultura, plantaciones y sectores rurales
              </option>
              <option value="Alimentación, bebidas y tabaco">
                Alimentación, bebidas y tabaco
              </option>
              <option value="Comercio">Comercio</option>
              <option value="Construcción">Construcción</option>
              <option value="Educación">Educación</option>
              <option value="Fabricación de material de transporte">
                Fabricación de material de transporte
              </option>
              <option value="Función pública">Función pública</option>
              <option value="Hotelería, restauración y turismo">
                Hotelería, restauración y turismo
              </option>
              <option value="Industrias químicas">Industrias químicas</option>
              <option value="Ingeniería mecánica y eléctrica">
                Ingeniería mecánica y eléctrica
              </option>
              <option value="Medios de comunicación, cultura y gráficos">
                Medios de comunicación, cultura y gráficos
              </option>
              <option value="Minería, tanto de carbón como de otros tipos">
                Minería, tanto de carbón como de otros tipos
              </option>
              <option value="Petróleo, producción de gas y refinación">
                Petróleo, producción de gas y refinación
              </option>
              <option value="Producción de metales básicos">
                Producción de metales básicos
              </option>
              <option value="Servicios de correos y telecomunicaciones">
                Servicios de correos y telecomunicaciones
              </option>
              <option value="Servicios de salud">Servicios de salud</option>
              <option value="Servicios financieros y servicios profesionales">
                Servicios financieros y servicios profesionales
              </option>
              <option value="Servicios públicos (agua, gas y electricidad)">
                Servicios públicos (agua, gas y electricidad)
              </option>
              <option value="Silvicultura, madera, celulosa y papel">
                Silvicultura, madera, celulosa y papel
              </option>
              <option value="Textiles, vestido, cuero y calzado">
                Textiles, vestido, cuero y calzado
              </option>
              <option value="Transporte">Transporte</option>
            </select>
          </div>

          <div className="form-group">
            <select
              className="form-control"
              defaultValue=""
              name="nEmpleados"
              required
              {...register("N° de empleados", { required: true })}
            >
              <option value="" disabled>
                No. De empleados
              </option>

              <option value="0 a 100">0 a 100</option>
              <option value="100 a 200">100 a 200</option>
              <option value="100 a 200">100 a 200</option>
              <option value="200 a 300">200 a 300</option>
              <option value="300 a 400">300 a 400</option>
              <option value="400 a 500">400 a 500</option>
              <option value="más de 500">más de 500</option>
            </select>
          </div>

          <div className="form-group">
            <select
              className="form-control"
              defaultValue=""
              name="pais"
              required
              {...register("País", { required: true })}
            >
              <option value="" disabled>
                País
              </option>
              <option value="mx">Mexico</option>
              <option value="cd">Canada</option>
              <option value="cc">Chile</option>
              <option value="col">Colombia</option>
              <option value="esp">España</option>
              <option value="usa">Estados Unidos</option>
            </select>
          </div>

          <div className="form-group">
            {/* <select
              className="form-control"
              defaultValue=""
              name="plan"
              onChange={handleOnChange}
              required
              {...register("Plan", { required: true })}
            > */}
            <select
              className="form-control"
              defaultValue=""
              name="plan"
              required
              {...register("Plan", {
                required: true,
                onChange: (e) => {
                  handleOnChange(e);
                },
              })}
            >
              <option value="" disabled>
                Plan
              </option>
              <option value="freemium">Freemium</option>
              <option value="premium">Premium</option>
              <option value="beyond">Beyond</option>
            </select>
          </div>

          <div className="form-group form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              name="terminos"
              required
              {...register("Término y Condiciones", { required: true })}
            />
            <label className="form-check-label" htmlFor="exampleCheck1">
              <span className="mr-1">Acepto </span>
              <Link
                to="/terminos-y-condiciones-del-uso-del-software-de-ubbitt"
                target="_blank"
              >
                Término y Condiciones
              </Link>
            </label>
          </div>

          {/* <button className="btn btn_form_empresas">Solicitar demo</button> */}
          <input className="btn btn_form_empresas" type="submit" />
        </form>
      </div>
      <Modal
        className="modal_calendar_hubspot"
        show={show}
        onHide={handleClose}
      >
        <Modal.Body>
          <Button className="btn btn_close_modal" onClick={handleClose}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
              alt=""
            />
          </Button>

          <Helmet>
            <script
              type="text/javascript"
              src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
            ></script>
          </Helmet>
          {freemiumCalendarVisible && <Freemium />}
          {beyondCalendarVisible && <Beyond />}
          {premiumCalendarVisible && <Premium />}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Form;
