import React from "react";
import SEO from "../common/SEO";
import { Tabs, Tab, AppBar } from "@mui/material";
import { Link } from "react-router-dom";

// Common
import NavLegal from "../common/header/NavLegal";
import Footer from "../common/footer/Footer";

//Components
import Faqs from "../components/GobiernoCorporativo/Faqs";
import JuntaDirectiva from "../components/GobiernoCorporativo/JuntaDirectiva";
import ResponsabilidadSocial from "../components/GobiernoCorporativo/ResponsabilidadSocial";

const GobiernoCorporativo = (props) => {
  const { match, history } = props;
  const { params } = match;
  const { page } = params;

  const tabNameToIndex = {
    0: "juntadirectiva",
    1: "responsabilidadsocial",
    2: "faqs",
  };

  const indexToTabName = {
    juntadirectiva: 0,
    responsabilidadsocial: 1,
    faqs: 2,
  };

  //   console.log(page);
  const [selectedTab, setSelectedTab] = React.useState(indexToTabName[page]);

  const handleChange = (event, newValue) => {
    history.push(`/gobierno-corporativo/${tabNameToIndex[newValue]}`);
    setSelectedTab(newValue);
  };
  return (
    <>
      <SEO title="GobiernoCorporativo" />
      <main className="page-wrapper">
        <NavLegal />
        <div className="container plans_container">
          <p className="ttl_plan">
            <span>//</span> Gobierno Corporativo
          </p>
          <h6 className="descriptions_gobierno">
            Estamos comprometidos a operar nuestros negocios con la máxima
            integridad, adoptando políticas de gobierno que promuevan la
            representación reflexiva e independiente de los intereses de
            nuestros accionistas.
          </h6>
          <br /><br />
          <Tabs value={selectedTab} onChange={handleChange}>
            <Tab className="tab_plan" label="Junta directiva" />
            <Tab className="tab_plan" label="Responsabilidad Social" />
            <Tab className="tab_plan" label="Preguntas frecuentes" />
          </Tabs>
          {selectedTab === 0 && <JuntaDirectiva />}
          {selectedTab === 1 && <ResponsabilidadSocial />}
          {selectedTab === 2 && <Faqs />}
        </div>
        <Footer />
      </main>
    </>
  );
};

export default GobiernoCorporativo;
