import React, { Component } from "react";

class Premium extends Component {
  render() {
    return (
      <>
       
        <div className="meetings-iframe-container" data-src="https://meetings.hubspot.com/valeria-guadalupe/demo-ubbitt-premium?embed=true"></div>
      </>
    );
  }
}

export default Premium;
