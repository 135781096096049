import React from "react";

import { Link } from "react-router-dom";

const Premium = () => {
  return (
    <>
      <div className="container content_info_tab">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/premium/premium_img_1.svg`}
              alt=""
              className="mx-w-100"
            />
          </div>
          <div className="col-sm-12 col-md-6 text_info_one_container">
            <div className="text_info_one">
              <h6>Ubbitt Premium es la única plataforma:</h6>
              <ul>
                <li>
                  <i className="icon-check"></i>
                  Que corre Campañas de Adquisición de Ventas.
                </li>
                <li>
                  <i className="icon-check"></i>
                  Convierte tu inversión de marketing digital en nuevos clientes
                  y ventas cobradas.
                </li>
                <li>
                  <i className="icon-check"></i>
                  Que garantiza un 100% de retorno de inversión en ventas.
                </li>
              </ul>
              <Link to="/empresas">Agenda un Demo</Link>
            </div>
          </div>
        </div>
        <div className="row row_sncd_container">
          <div className="col-sm-12 col-md-6 text_info_one_container">
            <div className="text_info_one">
              <h6>Soluciones:</h6>
              <ul>
                <li>
                  <i className="mini_dots"></i>
                  Conversión en tus campañas en tiempo real.
                </li>
                <li>
                  <i className="mini_dots"></i>
                  Transforma digitalmente tus ventas.
                </li>
                <li>
                  <i className="mini_dots"></i>
                  Gestionamos el rendimiento de todo.
                </li>
                <li>
                  <i className="mini_dots"></i>
                  Soluciona tu contact center a un costo variable.
                </li>
                <li>
                  <i className="mini_dots"></i>
                  Soluciona las campañas de mkt que no convierten analiticos en
                  tiempo real.
                </li>
                <li>
                  <i className="mini_dots"></i>
                  Corre campañas de adquisición.
                </li>
                <li>
                  <i className="mini_dots"></i>
                  Ventas garantizadas.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/premium/premium_img_2.svg`}
              alt=""
              className="mx-w-100"
            />
          </div>
        </div>
        <hr />
        <div className="features_container">
          <div className="text-center">
            <p class="subttl_section">
              <span>// </span>Features
            </p>
            <h5 class="ttl_section">¿Qué incluye?</h5>
            <div className="row">
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-ecommerce1"></i>
                    </span>
                    Ecommerce & Landing pages
                  </p>
                  <hr />
                  <p className="info_card">
                    Partial or Full ecommerce integration with vendors service
                    catalogue, payment Gateway and full ecommerce purchase
                    process.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-support"></i>
                    </span>
                    On Demand Sales Contact Center
                  </p>
                  <hr />
                  <p className="info_card">
                    Ubbitt Partners are ready to join any Vendor Aquisition
                    campaign or sales team according to the volumen or service
                    level Vendor requires.
                  </p>
                </div>
              </div>

              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-marketplace"></i>
                    </span>
                    Ubbitt Market Place App
                  </p>
                  <hr />
                  <p className="info_card">
                    Vendor open market app to acces Vendor services within
                    ubbitt Marketplace.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-performance"></i>
                    </span>
                    Digital performance MKT
                  </p>
                  <hr />
                  <p className="info_card">
                    Digital marketing where brands only pay Ubbitt campaigns
                    once their business goals are met or when specific actions
                    are completed, such as sales or leads.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-transformation"></i>
                    </span>
                    Digital Transformation of Sales Process
                  </p>
                  <hr />
                  <p className="info_card">
                    Partial or Full ecommerce integration with vendors service
                    catalogue, payment Gateway and full ecommerce purchase
                    process.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-ubbitt360"></i>
                    </span>
                    Ubbitt 360
                  </p>
                  <hr />
                  <p className="info_card">
                    Vendor real time reporting and analytics dashbaord.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-mkt"></i>
                    </span>
                    MKT Automation
                  </p>
                  <hr />
                  <p className="info_card">
                    Through CRM partners like Hubspot Ubbitt unleashes full mkt
                    automated workflows and CRM features.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-elearning"></i>
                    </span>
                    E-learning
                  </p>
                  <hr />
                  <p className="info_card">
                    Our online course platform where the sales process and
                    culture of our Vendors is transfered to our partners.
                  </p>
                </div>
              </div>

              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-partner"></i>
                    </span>
                    Partner App
                  </p>
                  <hr />
                  <p className="info_card">
                    Partners Job Market Place where vendor apps are available
                    for sign up, training, rewards Ubbitt University , KPIS
                    dashbaord , Profile and more.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-security"></i>
                    </span>
                    Cybersecurity
                  </p>
                  <hr />
                  <p className="info_card">
                    Ubbitt is currently PCI Compliant , we are also in current
                    PCI DSS, SOC2, ISO 27001 and other certifications
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-cloud"></i>
                    </span>
                    Cloud Comunication Services
                  </p>
                  <hr />
                  <p className="info_card">
                    Powered by Callpickers Solutions Ubbitt delivers all the
                    power of Cloud Calls to the customers.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-ubbittmessenger"></i>
                    </span>
                    Ubbitt Messenger
                  </p>
                  <hr />
                  <p className="info_card">
                    Powered by Callpickers Solutions Ubbitt delivers all the
                    power of Cloud Calls to the customers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="modelo_container">
          <div className="text-center">
            <p class="subttl_section">
              <span>// </span>Modelo de negocio
            </p>
            <h5 class="ttl_section">¿Cómo funciona?</h5>
            <img
              className="w-100"
              src={`${process.env.PUBLIC_URL}/assets/images/premium/diagrama_premium.png`}
              alt=""
            />
          </div>
        </div>
        <hr />
        <div className="calculator_container">
          <div className="text-center">
            <p class="subttl_section">
              <span>// </span>Solicita un demo
            </p>
            <h5 class="ttl_section">Calculadora Ubbitt Premium</h5>
            <h4>Hagamos un ejercicio</h4>
          </div>
          <form action="" className="calculator">
              <h6>
              Inversión Mínima de <span>$300,000</span> por 3 meses.
            </h6>
            <div class="row">
              <div class="form-group col-sm-6 col-md-2">
                <label for="inputEmail4">Tu inversión</label>
                <input type="number" class="form-control" id="inputEmail4" />
              </div>
              <div class="form-group col-sm-6 col-md-2">
                <label for="inputPassword4">Tu ticket promedio</label>
                <input type="number" class="form-control" id="inputPassword4" />
              </div>
              <div class="form-group col-sm-6 col-md-2">
                <label for="inputCity">CPL</label>
                <input type="number" class="form-control" id="inputCity" />
              </div>
              <div class="form-group col-sm-6 col-md-2">
                <label for="inputCity">Leads</label>
                <input type="number" class="form-control" id="inputCity" />
              </div>
              <div class="form-group col-sm-6 col-md-2">
                <label for="inputZip">Ventas (U)</label>
                <input type="number" class="form-control" id="inputZip" />
              </div>
              <div class="form-group col-sm-6 col-md-2">
                <label for="inputZip">Ventas ($)</label>
                <input type="number" class="form-control" id="inputZip" />
              </div>
            </div>
            <div className="row set-center">
            <div class="form-group col-sm-6 col-md-4">
                <label for="inputEmail4">Ventas ($)</label>
                <input type="number" class="form-control" id="inputEmail4" disabled />
              </div>
              <div class="form-group col-sm-6 col-md-4">
                <label for="inputPassword4">ROI</label>
                <input type="number" class="form-control" id="inputPassword4" disabled />
              </div>
            </div>
          </form>
        </div>
        <div className="agenda_demo">
          <div className="row">
            <div className="col-sm-12 col-md-6 set-center">
              <img
                className=""
                src={`${process.env.PUBLIC_URL}/assets/images/view_empresas/dudas.svg`}
                alt=""
              />
            </div>
            <div className="col-sm-12 col-md-6 set-center-left">
              <div>
                <h2>
                ¡Conócenos!
                </h2>
                <p>
                Sorpréndete con lo que <br/> Übbitt tiene para ti.
                </p>
                <Link to="/empresas">Agenda un Demo</Link>
              </div>
            </div>
          </div>
          <div className="bg_gray"></div>
        </div>
      </div>
    </>
  );
};

export default Premium;
