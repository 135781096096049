import React, { Component } from "react";

class ResponsabilidadSocial extends Component {
  render() {
    return (
      <div className="responsabilidad_social_container">
        <div className="row row_info">
          <div className="col-sm-12 col-md-6">
            <img
              className="w-100"
              src={`${process.env.PUBLIC_URL}/assets/images/responsabilidad-social/proposito.svg`}
              alt=""
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <h5>El compromiso del liderazgo con la diversidad</h5>
            <p>
              Crear mayor igualdad es una prioridad para Ubbitt. Esto significa
              aumentar la diversidad demográfica y convertirnos en una empresa
              antirracista y en una aliada de las comunidades a las que
              prestamos servicios.
            </p>
            <p>
              Cada miembro del equipo de liderazgo ejecutivo hace su parte para
              que se convierta en realidad, especialmente, al asegurarse de que
              toda la organización se comprometa con esto. El aumento de la
              diversidad, la igualdad y la inclusión es una parte fundamental de
              la estrategia de la empresa, y el compromiso con ella comienza en
              nuestros directivos.
            </p>
          </div>
        </div>
        <p className="mini_legal_subject">
          Proporcionamos consciencia sobre la identidad y la interseccionalidad.
          Übbitt siempre se preocupará y ocupara de ofrecer oportunidades de
          crecimiento, desarrollo de liderazgo para los miembros de nuestro
          equipo.
        </p>
        <div className="features_container">
          <div className="text-center">
            <p class="subttl_section">
              <span>// </span>Features
            </p>
            <h5 class="ttl_section">¿Qué incluye?</h5>
            <div className="row">
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-marketplace"></i>
                    </span>
                    Capacidades Ubbitt
                  </p>
                  <hr />
                  <p className="info_card">
                    La comunidad de colaboradores con capacidades distintas.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-performance"></i>
                    </span>
                    Inmigrates Ubbitt
                  </p>
                  <hr />
                  <p className="info_card">
                    La comunidad de colaboradores provenientes de otros países.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-transformation"></i>
                    </span>
                    Diversidad Cultural y Religiosa en Übbitt
                  </p>
                  <hr />
                  <p className="info_card">
                    La comunidad de colaboradores con diferentes creencias y
                    culturas.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-ubbitt360"></i>
                    </span>
                    Igualdad Übbitt
                  </p>
                  <hr />
                  <p className="info_card">
                    Promovemos la igualdad socioeconómica,cultural, de género y
                    racial.
                  </p>
                </div>
              </div>

              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-mkt"></i>
                    </span>
                    Padres Ubbitt
                  </p>
                  <hr />
                  <p className="info_card">
                    La comunidad para padres y madres de familia.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-elearning"></i>
                    </span>
                    Ubbitt Pride
                  </p>
                  <hr />
                  <p className="info_card">
                    Estamos orgullosos de que la comunidad LGBTTQ+ nos elija
                    para trabajar con nosotros.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-partner"></i>
                    </span>
                    Mujeres Ubbitt
                  </p>
                  <hr />
                  <p className="info_card">
                    La comunidad Ubbitt para nuestras mujeres.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-ecommerce1"></i>
                    </span>
                    Sabiduría Ubbitt
                  </p>
                  <hr />
                  <p className="info_card">
                    La comunidad de colaboradores de todas las generaciones.
                  </p>
                </div>
              </div>

              <div className="col-sm-12 col-md-3">
                <div className="card_features">
                  <p className="ttl_features">
                    <span>
                      <i className="icon-support"></i>
                    </span>
                    Medio ambiente Ubbitt
                  </p>
                  <hr />
                  <p className="info_card">
                    Cuidamos el medio ambiente y nos preocupamos por las
                    emisiones de gas al trabajar desde casa .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <p class="subttl_section text-center">
          <span>// </span>La comunidad de colaboradores con capacidades
          distintas
        </p>
        <h5 class="ttl_section text-center">Capacidades Ubbitt</h5>
        <p className="mini_legal_subject">
          Nuestra misión es fortalecer y sostener los esfuerzos de diversidad e
          inclusión de Uber para los empleados que viven y apoyan a aquellos con
          discapacidades físicas, mentales y emocionales.
        </p>
        <div className="row infografia_row">
          <div className="col-12 col-md-4">
            <div className="card_infografia">
              <img
                className="d-block mx-auto"
                src={`${process.env.PUBLIC_URL}/assets/images/respon_civil/representamos.svg`}
                alt=""
              />
              <p className="ttl_infografia">Lo que representamos</p>
              <hr />
              <p>
                Oportunidad y conocimiento de los recursos. Garantizar la
                igualdad de oportunidades para que las personas con
                discapacidades consideren la posibilidad de unirse a Übbitt.
                Desarrollar nuevos recursos y aumentar el conocimiento de los
                beneficios existentes para los empleados actuales y nuevos que
                puedan tener una discapacidad.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card_infografia">
              <img
                className="d-block mx-auto"
                src={`${process.env.PUBLIC_URL}/assets/images/respon_civil/familia.svg`}
                alt=""
              />
              <p className="ttl_infografia">Lo que representamos</p>
              <hr />
              <p>
                Oportunidad y conocimiento de los recursos. Garantizar la
                igualdad de oportunidades para que las personas con
                discapacidades consideren la posibilidad de unirse a Übbitt.
                Desarrollar nuevos recursos y aumentar el conocimiento de los
                beneficios existentes para los empleados actuales y nuevos que
                puedan tener una discapacidad.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card_infografia">
              <img
                className="d-block mx-auto"
                src={`${process.env.PUBLIC_URL}/assets/images/respon_civil/publico_internet.svg`}
                alt=""
              />
              <p className="ttl_infografia">Lo que representamos</p>
              <hr />
              <p>
                Oportunidad y conocimiento de los recursos. Garantizar la
                igualdad de oportunidades para que las personas con
                discapacidades consideren la posibilidad de unirse a Übbitt.
                Desarrollar nuevos recursos y aumentar el conocimiento de los
                beneficios existentes para los empleados actuales y nuevos que
                puedan tener una discapacidad.
              </p>
            </div>
          </div>
        </div>

        <div className="politicas_ubbitt">
          <h1>POLÍTICAS DE ÜBBITT S.A. DE C.V.</h1>
          <p>
            Nuestras políticas internas son un conjunto de directrices
            documentadas que establecen normas y procedimientos apropiados para
            regular el comportamiento de nuestros colaboradores. Permiten
            uniformar los valores, criterios y cultura en el seno de la empresa.
            Dictan, en definitiva, las conductas que esperamos de los
            colaboradores que se relacionan con nuestra empresa y el modo de
            desarrollarlas. También las conductas indeseadas y la forma de
            evitarlas o actuar frente a ellas. Las políticas de este documento
            son obligatorias a toda persona que participe como colaborador en
            ÜBBITT S.A. DE C.V., Estamos comprometidos a respetar los Derechos
            Humanos y la integridad de todos nuestros colaboradores, es por ello
            que formalizamos nuestro compromiso con los derechos humanos y
            laborales reconocidos en la Ley Federal del Trabajo vigente de los
            Estados Unidos Mexicanos y ante la Comisión Nacional de Derechos
            Humanos alineados con nuestros principios. Creemos y reconocemos que
            los negocios solo pueden prosperar en las sociedades donde se
            protegen y respetan los derechos humanos y laborales, para
            contribuir de manera positiva sobre ellos.
          </p>
        </div>

        <div className="row infografia_row">
          <div className="col-12 col-md-4">
            <div className="card_infografia">
              <img
                className="d-block mx-auto"
                src={`${process.env.PUBLIC_URL}/assets/images/respon_civil/practicas_laborales.svg`}
                alt=""
              />
              <p className="ttl_infografia">Prácticas laborales</p>
              <hr />
              <p>
                Rechazo al trabajo forzoso u obligatorio y al trabajo infantil.
                La Empresa rechaza el uso de cualquier forma de trabajo forzoso
                u obligatorio y no confiscó dinero ni documentos de identidad al
                inicio de la relación laboral con el objeto de retener a los
                colaboradores en contra de su voluntad. Se respetan los derechos
                de los niños y rechaza el uso de mano de obra infantil.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card_infografia">
              <img
                className="d-block mx-auto"
                src={`${process.env.PUBLIC_URL}/assets/images/respon_civil/rechazo_disciminacion.svg`}
                alt=""
              />
              <p className="ttl_infografia">
                Rechazo a la discriminación laboral
              </p>
              <hr />
              <p>
                Ubbitt rechaza toda forma de discriminación y mantiene el
                compromiso de velar por que todos sus colaboradores sean
                tratados con respeto hacia su diversidad, promoviendo así mismo
                la igualdad de oportunidades al establecerse la relación
                laboral.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card_infografia">
              <img
                className="d-block mx-auto"
                src={`${process.env.PUBLIC_URL}/assets/images/respon_civil/seguridad.svg`}
                alt=""
              />
              <p className="ttl_infografia">Seguridad y salud laboral</p>
              <hr />
              <p>
                Ubbitt tiene el compromiso de velar porque en los lugares de
                trabajo se respeten las mejores condiciones de seguridad y salud
                laboral, se promueve la difusión y refuerzo de una cultura de la
                seguridad, desarrollando la concienciación sobre el riesgo, y
                fomenta el comportamiento responsable por parte de sus
                colaboradores compartiendo información relevante.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card_infografia">
              <img
                className="d-block mx-auto"
                src={`${process.env.PUBLIC_URL}/assets/images/respon_civil/condiciones_trabajo_justas.svg`}
                alt=""
              />
              <p className="ttl_infografia">
                Condiciones de trabajo justas y favorables
              </p>
              <hr />
              <p>
                Con el fin de mantener un entorno de trabajo positivo y
                respetuoso, Ubbitt rechaza toda forma de acoso —ya sea verbal,
                físico, sexual o psicológico—amenaza o intimidación en el lugar
                de trabajo.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card_infografia">
              <img
                className="d-block mx-auto"
                src={`${process.env.PUBLIC_URL}/assets/images/respon_civil/confidencialidad.svg`}
                alt=""
              />
              <p className="ttl_infografia">Confidencialidad</p>
              <hr />
              <p>
                Tolerancia cero con la corrupción en todas sus formas, tanto
                directa como indirecta. La Empresa respeta la confidencialidad y
                el derecho a la intimidad de todas las personas con quienes
                interactúa, y mantiene el compromiso de utilizar correctamente
                toda la información y los datos que obran en su poder.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card_infografia">
              <img
                className="d-block mx-auto"
                src={`${process.env.PUBLIC_URL}/assets/images/respon_civil/soporte.svg`}
                alt=""
              />
              <p className="ttl_infografia">Soporte</p>
              <hr />
              <p>
                Le damos importancia a la disposición de un remedio efectivo
                siempre que se produzcan efectos relativos a los derechos
                humanos a través de mecanismos de reclamación mediante la
                empresa y mantenemos el compromiso de mejorar la capacidad de
                nuestros directivos para identificar y responder efectivamente
                ante estas inquietudes.Vemos la diversidad la inclusión como una
                fortaleza. Seguimos los principios de privacidad e implementamos
                prácticas razonables y apropiadas.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card_infografia">
              <img
                className="d-block mx-auto"
                src={`${process.env.PUBLIC_URL}/assets/images/respon_civil/soporte_legal.svg`}
                alt=""
              />
              <p className="ttl_infografia">Soporte legal</p>
              <hr />
              <p>
                Toda persona tiene todos los derechos y libertades proclamados
                en estas Políticas sin distinción de raza, color, sexo, idioma,
                religión, opinión política o de cualquier otra índole, origen
                nacional o social, posición económica, nacimiento o cualquier
                otra condición. Además, no se hará distinción alguna fundada en
                la condición política, jurídica o internacional del país o
                territorio de cuya jurisdicción dependa una persona, tanto si se
                trata de un país independiente, como de un territorio bajo
                administración fiduciaria, no autónomo o sometido a cualquier
                otra limitación de soberanía.(art. 2 de la Declaración Universal
                de Derechos Humanos)
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card_infografia">
              <img
                className="d-block mx-auto"
                src={`${process.env.PUBLIC_URL}/assets/images/respon_civil/protocolo.svg`}
                alt=""
              />
              <p className="ttl_infografia">Protocolo general</p>
              <hr />
              <p>
                1. Establecer medidas de prevención informativas respecto al
                tema. <br /> 2. Definir los mecanismos para orientar y acompañar
                a las víctimas. <br /> 3. Señalar las instancias competentes
                para resolver estas conductas. <br /> 4. Contar con un registro
                de los casos sucedidos. <br /> 5. Primer contacto: la víctima
                podrá informar al área interna correspondiente de dar
                seguimiento (RH). <br /> 6. Atención Especializada: se debe
                identificar el tipo de apoyo o intervención. <br /> 7.
                Investigación: se atenderá el caso e informará a las instancias
                oficiales correspondientes. <br /> 8. Sanción: se impondrá la
                sanción administrativa y legal correspondiente. <br /> 9.
                Gravedad: determinada dependiendo el caso (llamada de atención,
                acta administrativa, rescisión de contrato justificado o
                demanda).
              </p>
            </div>
          </div>
        </div>
        <hr />

        <p class="subttl_section text-center">
          <span>// </span>La comunidad de colaboradores con capacidades
          distintas
        </p>
        <h5 class="ttl_section text-center">Datos demográficos del personal</h5>
        <div className="row row_charts">
          <div className="col-12 col-md-6">
            <div className="card_infografia">
              <img
                className="d-block mx-auto charts"
                src={`${process.env.PUBLIC_URL}/assets/images/respon_civil/grafica_poblacion.svg`}
                alt=""
              />
              <p className="ttl_infografia text-center">Distribución de población</p>
              <hr />
              <p>
              A continuación, te mostramos una representación más detallada de nuestro personal durante los últimos 3 años.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card_infografia">
              <img
                className="d-block mx-auto charts"
                src={`${process.env.PUBLIC_URL}/assets/images/respon_civil/grafica_lideres.svg`}
                alt=""
              />
              <p className="ttl_infografia text-center">Nuestros líderes</p>
              <hr />
              <p>
              La mayoría de nuestros líderes son mujeres.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResponsabilidadSocial;
