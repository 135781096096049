import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';

import logo from './logo.svg';
// import './App.css';
import PageScrollTop from './components/pageToTop/PageScrollTop';

//Views
import Home from './pages/Home';
import Empresas from './pages/Empresas';
import Socios from './pages/Socios';
import AvisoDePrivacidad from './pages/AvisoDePrivacidad';
import Anticorrupcion from "./pages/Anticorrupcion";
import TerminosLicencia from "./pages/TerminosLicencias";
import CodigoEtica from './pages/CodigoEtica';
import SeguridadDatos from "./pages/SeguridadDatos";
import SeguridadPrivacidad from './pages/SeguridadPrivacidad';
import TerminosCondiciones from './pages/TerminosCondiciones';
import Cybersecurity from './pages/Cibersecurity';
import Planes from './pages/Planes';
import GobiernoCorporativo from './pages/Gobierno';
import RelacionInversionistas from './pages/RelacionInversionistas';

// Import Css Here 
import './assets/scss/main.scss';
import 'bootstrap/dist/js/bootstrap.bundle';



function App() {
  return (
    <Router>
      <PageScrollTop>
        <Switch>
          {/* <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Home} /> */}
          {/* <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Empresas} /> */}
          <Redirect exact from="/" to="/empresas" />
          <Route path={`${process.env.PUBLIC_URL + "/home"}`} exact component={Home} />
          <Route path={`${process.env.PUBLIC_URL + "/empresas"}`} exact component={Empresas} />
          <Route path={`${process.env.PUBLIC_URL + "/soy-ubbitt"}`} exact component={Socios} />
          <Route path={`${process.env.PUBLIC_URL + "/aviso-de-privacidad"}`} exact component={AvisoDePrivacidad} />
          <Route path={`${process.env.PUBLIC_URL + "/politica-de-anticorrupcion"}`} exact component={Anticorrupcion} />
          <Route path={`${process.env.PUBLIC_URL + "/terminos-licencia-de-uso-ubbitt"}`} exact component={TerminosLicencia} />
          <Route path={`${process.env.PUBLIC_URL + "/codigo-de-etica"}`} exact component={CodigoEtica} />
          <Route path={`${process.env.PUBLIC_URL + "/politicas-de-seguridad-de-datos"}`} exact component={SeguridadDatos} />
          <Route path={`${process.env.PUBLIC_URL + "/politicas-de-seguridad-y-privacidad"}`} exact component={SeguridadPrivacidad} />
          <Route path={`${process.env.PUBLIC_URL + "/terminos-y-condiciones-del-uso-del-software-de-ubbitt"}`} exact component={TerminosCondiciones} />
          <Route path={`${process.env.PUBLIC_URL + "/cibersecurity"}`} exact component={Cybersecurity} />
          <Route path={`${process.env.PUBLIC_URL + "/inversionistas"}`} exact component={RelacionInversionistas} />
          <Redirect exact from="/planes" to="/planes/freemium" />
          <Route path={`${process.env.PUBLIC_URL + "/planes/:page?"}`} render={props =>  <Planes {...props} />} />
          <Redirect exact from="/gobierno-corporativo" to="/gobierno-corporativo/juntadirectiva" />
          <Route path={`${process.env.PUBLIC_URL + "/gobierno-corporativo/:page?"}`} render={props =>  <GobiernoCorporativo {...props} />} />
        </Switch>
      </PageScrollTop>
    </Router>
  );
}

export default App;
