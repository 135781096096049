import React from 'react'
import SEO from "../common/SEO";


// Common
import NavLegal from '../common/header/NavLegal';
import Footer from '../common/footer/Footer';


const CodigoEtica = () => {
    return (
        <>
            <SEO title="Código de ética" />

            <main className="page-wrapper">
                {/* <Nav /> */}
                <NavLegal/>
                <div className="container">
                    <div className="container_legales col-10">
                        <h3 className='main_ttl_legal'>Código de ética</h3>
                        PROPÓSITO 

UBBITT, sus subsidiarias y filiales, creen firmemente que las relaciones que se construyen desde los valores fundamentales de la honestidad, integridad, honradez y responsabilidad, serán fructíferas, ya que generarán el compromiso sólido necesario que trae como resultado la Confianza y el respeto.

Desde UBBITT partimos de la práctica continua de un conjunto de valores, normas y principios reflejados en la cultura de la empresa, cuyo objetivo es alcanzar una mayor armonía con la sociedad, desde una conducta de integridad y profesionalismo de las personas que forman parte de ella.

En razón a ello, el presente Código se desarrolla con la intención de que sirva como referencia para el saber actuar ante una determinada situación y también para dar a conocer a todas aquellas personas con las que se establecen relaciones de negocios cuales son nuestras prácticas con el fin que su actuar se asemeje a los valores que profesamos.

ALCANCE 

Esta Política es aplicable a todos los que guardan alguna relación con UBBITT, así como colaboradores, agentes externos, las partes relacionadas y grupos de interés, entendidos estos como: clientes, proveedores, accionistas, inversionistas, aliados, empleados, contratistas, subcontratistas; y en general a todos aquellos con quienes de manera directa o indirectamente se establezca alguna relación comercial, contractual y/o de cooperación.

VALORES 

Liderazgo: Considerado desde la óptica de inspirar al equipo, mediante el compartir conocimiento y promoviendo el desarrollo de nuevas habilidades. Inspirando con las acciones y con el ejemplo, aún y cuando algunas decisiones no sean del agrado de algunos. Orientar a los colaboradores hacia el desarrollo de la competitividad y la rentabilidad.

Comunicación: Ser siempre congruente entre lo que se habla, se actúa y se escribe. Mantener una comunicación eficaz, con un buen manejo del estrés y del respeto, donde primero se debe escuchar antes de emitir un comentario u opinión, tratando a todos por igual independientemente de su posición.  Procurando comunicar desde una perspectiva positiva.

Honestidad:  El comportamiento basado en la sinceridad es una premisa dentro de la empresa, donde la verdad, la franqueza, la comunicación directa y abierta debe ser un norte. Se deben de asumir las consecuencias de las decisiones tomadas y responder por los actos propios.

Trabajo en equipo: Entendiendo que para el crecimiento personal y empresarial es indispensable la colaboración y apoyo del equipo de trabajo, por lo que existe un compartir de conocimientos y un engranaje entre colaboradores buscando el mayor beneficio, sin que haya lugar a situaciones en las que no exista un trato digno a las personas, por razón de su edad, nivel jerárquico, sexo, orientación sexual o cualquiera otra convicción personal.

Solución de problemas: En UBBITT, se aplica el Principio de la Ley Pareto, según el cual el 80% de los problemas viene del 20% de las causas, por lo que la actividad de la empresa se concentra en actuar aplicando todas las herramientas necesarias para evitar consecuencias, y en el caso de presentarse un problema, el enfoque va hacía la solución, minimizando la complejidad.

Mejoramiento continuo: Aplicamos el Método Kayzen a la filosofía empresarial, promoviendo mejoras continuas en cada área que generen un impacto positivo personal, para quien la realiza, profesional para el equipo de trabajo y general para el entorno.

Adaptabilidad al cambio: Reconocemos que vivimos en un mundo dinámico, donde el cambio es una constante, por lo que la adaptación es parte de nuestro actuar, asumiendo los retos que se presenten, afrontando desafíos y proponiendo las soluciones pertinentes.

Innovación: Buscamos estar siempre a la vanguardia por ello, nos enfocamos en crear, modificar, desarrollar nuevas estrategias, proyectos e ideas que generen un valor agregado.  

Actitud positiva: Mantenemos como filosofía el trabajar desde lo que nos da satisfacción, aprendiendo de los errores, reconociendo los logros, y concentrándose en el lado positivo de las situaciones

PRINCIPIOS ETICOS EMPRESARIALES 

Servicio al cliente: Los clientes son uno de los eslabones más importantes para la empresa, por ello es prioridad garantizar que los servicios prestados sean satisfactorios para el cliente, brindando el apoyo, la orientación o instrucciones respecto a lo que sea requerido, de manera que no sólo exista el cumplimiento del compromiso en la relación contratada sino también generar la empatía correcta para conocer y atacar las necesidades del cliente.

Competitividad y liderazgo: La sana competencia y el liderazgo emergente nos posicionan en el mercado comercial, como una empresa con compromiso al prestar servicios que en calidad, precio y oportunidad son competitivos.

Crecimiento rentable:  El crecimiento enfocado en la innovación, en la fuerza del capital humano, así como en el liderazgo en un mercado competitivo, donde buscamos ser más proactivos para alcanzar las metas y objetivos propuesto mediante el fortalecimiento de las estrategias a seguir.

Calidad: Identificar las necesidades del cliente para satisfacerlas de la forma más amplia posible que se consiguen mediante la planeación, control y mejora continua.  

Compromiso: Interno, generando el sentido de pertenencia de nuestros empleados con la empresa, mediante la vinculación intelectual y emocional, la cual se exterioriza en un compromiso ante los clientes en el servicio que se presta. 

IV. PRINCIPIOS DE CONDUCTA 

Apego a la ley: Todas las actividades que se llevan a cabo en UBBITT se hacen con apego a legislación vigente y a las normativas, reglamentos y políticas internas, las cuales se revisan y se actualizan de tiempo en tiempo, procurando estar al día con las exigencias y requerimientos legales.

Responsabilidad: Basada en los compromisos asumidos con las personas que integran a la empresa, con los clientes y proveedores, e igualmente con la retribución a la sociedad y al medio ambiente que nos rodea. Es la capacidad de actuar con honestidad y ser congruente entre las palabras, decisiones y acciones en función de la brújula moral, de valor o de creencias particulares que cada persona tenga

Respeto:  En UBBITT valoramos a todos por igual, con la debida tolerancia y dignidad propia de cada persona, englobando a cada uno de los colaboradores de la empresa, clientes y proveedores.

Integridad:  Entendida como la congruencia entre las palabras, decisiones y acciones que se ejercen, tomando como norte una actitud basada en la ética y la honestidad.

V. PAUTAS ETICAS Y DE CONDUCTA 

A continuación, se describen pautas generales de ética y comportamiento que son cumplidas y aplicadas por quienes forman parte de la empresa, así como también se indican las conductas que se reconocen como inaceptables (Conductas Prohibidas) desde el marco el presente código.

 FRENTE A LOS ACCIONISTAS E INVERSIONISTAS

La Directiva de UBBITT aplicará sus mayores esfuerzos para el mantenimiento y crecimiento de la actividad comercial de la empresa, mediante el manejo  de criterios de autocontrol y autorregulación como herramienta fundamental para prevenir, detectar, monitorear y mitigar los diferentes riesgos a los  que se puedan estar expuesta, presentando siempre líneas claras financieras, con proyecciones reales donde los accionistas e inversionistas conozcan a profundidad la empresa y se sientas seguros de su inversión.

Por ello, las principales conductas a seguir por la Directiva de la empresa son:

Toma de decisiones sólidas éticas en su fundamentación midiendo los riesgos, los beneficios y las consecuencias de cada una, procurando el mayor provecho y los mejores intereses para la empresa, sin que se vean influenciadas por intereses personales, buscando siempre proteger e incrementar el valor de la inversión.

Materializar las expectativas de rentabilidad de los accionistas de manera sostenida, a través del incremento y diversificación de los ingresos, así como una adecuada administración de los recursos.

Procurar asegurar la permanencia del negocio, buscando oportunidades que permitan maximizar el potencial del mismo en el mediano y largo plazo.

Compromiso de proporcionar a los accionistas, de manera oportuna y de conformidad con el marco regulatorio, toda la información necesaria sobre el negocio, abiertos a las auditorias que se consideren pertinentes.

 FRENTE A LOS COLABORADORES 

UBBITT reconoce que sus activos más importantes son sus colaboradores, razón por la cual respeta y valora la diversidad de ellos y asume el compromiso de generar un ambiente sano y seguro en el que se pueden desarrollar, tanto personal como profesionalmente. En este entendido, en UBBITT se otorgan capacitaciones constantes, de conformidad con el “Training Program” a cargo de la Dirección de Recursos Humanos, compensaciones de acuerdo con el desempeño y en base al respeto a la meritocracia, así como reconocimientos al personal.

Igualmente, en UBBITT, estamos comprometidos a respetar los Derechos Humanos y la integridad de todos nuestros empleados, por lo que formalizamos nuestro compromiso con los derechos humanos y laborales reconocidos en la legislación laboral y Tratados en materia de Derechos Humanos  alineándolos con nuestros principios. Creemos que las empresas solo pueden prosperar en sociedades donde respetan los derechos humanos y reconocemos que la empresa tiene la responsabilidad de respetar los derechos humanos y la capacidad de contribuir positivamente a ellos, prestando particular atención a las situaciones de conflicto y de alto riesgo, de conformidad con los siguientes compromisos en materia de Derechos Humanos:

Reconocer y respetar la dignidad e individualidad de las personas.

Cumplir con todas las leyes aplicables y respetar los Derechos Humanos en todos aquellos lugares donde opera.

Fomentar la concientización de colaboradores y socios comerciales en cuestiones relativas al respeto de los Derechos Humanos.

Valorar los riesgos actuales y potenciales y su impacto en nuestras operaciones.

Adoptar medidas adecuadas para la prevención, mitigación y, cuando resulte procedente, la remediación de impactos adversos.

Proporcionar acceso a mecanismos de queja internos y regulados por las Autoridades para ejecutar un Plan de Acción inmediato.

Información continua y transparentemente acerca de nuestros esfuerzos en el área.

En razón a lo anterior, entre las prácticas laborales que se llevan a cabo en UBBITT y que forman parte de las directrices a seguir en materia de derechos humanos, las cuales se desarrollan en diversas políticas, están:

El Rechazo al trabajo forzoso u obligatorio y al trabajo infantil

Rechazo a la discriminación laboral

Seguridad y salud laboral

Condiciones de trabajo justas y favorables

Privacidad de datos

  FRENTE A CLIENTES Y PROVEEDORES 

CLIENTES

El factor decisivo que lleva a un cliente a elegir a una empresa sobre otra, es el grado de confianza que esa compañía le ha generado a través del tiempo. Por ello en UBBITT los clientes son primordiales, porque de ellos depende nuestro éxito, de allí que las conductas que maneja la empresa frente a ellos van enfocadas en:

Trato profesional, justo y honesto, proporcionando servicios y productos de la mejor calidad, por lo que se fomenta una cultura organizacional orientada al servicio y a mantener un alto nivel de satisfacción de los usuarios.

Compromiso de ofrecer a los intermediarios, emisores de valores, instituciones financieras, público inversionista y clientela en general, servicios y productos de excelencia, con eficiencia, seguridad y transparencia con apego al marco regulatorio.

Innovación y mejora continua de nuestros servicios, productos y reglas, al estar atentos a las tendencias del mercado y a las mejoras tecnológicas, para mantener nuestra competitividad ante un entorno que cambia con gran dinamismo.

Además, se les alienta a nuestros clientes a cumplir con la eliminación del trabajo infantil, el no apoyo al trabajo forzoso, cumplir con la legislación nacional aplicable en materia laboral y a fomentar la no discriminación basada en raza, color, edad, género, orientación sexual, origen étnico, capacidades diferentes u otras en la contratación y en las prácticas relacionadas con el empleo.

Así mismo, promovemos entre nuestros clientes el cumplimiento del marco legal, desarrollando sus actividades bajo el marco normativo vigente.

UBBITT tiene entre sus valores el brindar un rato profesional, justo y honesto, proporcionando servicios y productos de la mejor calidad, por lo que se fomenta una cultura organizacional orientada al servicio y a mantener un alto nivel de satisfacción de los usuarios, excluyendo la negligencia y las descortesías por algún colaborador/a.

PROVEEDORES

Los proveedores son una parte importante para el desarrollo de la actividad comercial de UBBITT, el presente Código tiene como objetivo que nuestros proveedores lo sigan y cumplan para generar buenas relaciones comerciales con la empresa, es por ello que las conductas que se aplican en relación a la contratación de servicios dentro de la empresa, consisten en:

Trato honesto, justo y equitativo en las negociaciones para la adquisición de bienes y servicios, buscando el mejor interés para la empresa dentro de los establecido en este código y en las políticas inherentes.

Relación de beneficios recíprocos. Fomentamos una competencia justa y utilizamos procesos de selección equitativos y transparentes, sustentados en criterios de precio, calidad, rentabilidad y servicio, dando cumplimiento a las políticas internas. Pretendemos obtener de nuestros proveedores únicamente los beneficios correspondientes a la negociación de que se trate, sin prácticas indebidas ni ventajas personales por la asignación de bienes o servicios.

En atención a nuestro compromiso de exigir y ofrecer un trato honesto y justo, respetamos los acuerdos y compromisos establecidos en los contratos suscritos.

Prever y resolver, en su caso, los conflictos de interés que existan o se puedan presentar, antes de involucrarnos en cualquier negociación y en la consecuente adquisición del bien o servicio, atendiendo a las políticas internas.

Prohibición del trabajo forzado, trata de personas y esclavitud, el Proveedor no podrá realizar ninguna conducta mencionada anteriormente, no puede utilizar de ninguna forma la amenaza en sus contrataciones o cualquier otra forma de coerción, siempre debe de buscar el cumplimiento de las disposiciones aplicables al trabajo establecidas en la Ley Federal del Trabajo y la normativa vigente.

Los proveedores de UBBITT se comprometen a seguir con la normativa vigente aplicable a la prestación de sus servicios y a seguir el presente Código, en el caso de incurrir en cualquier violación, se podrán exigir acciones correctivas o en casos extremos se podrá terminar la relación comercial entre el proveedor y UBBITT.

Cualquier violación podrá ser comunicada conforme a los canales de denuncias establecidos, los informes serán confidenciales y se mantendrá el anonimato en todos los casos.

Descritas las conductas que se aplican para Clientes y Proveedores, las relaciones comerciales de UBBITT con proveedores y clientes van a estar enmarcadas y regidas por la Política de Anticorrupción de la empresa.

VI. CONFLICTOS DE INTERÉS

Se produce cuando los intereses personales o financieros de alguno de los colaboradores tienen prioridad sobre los intereses de la organización. Por ende, se espera que el personal de UBBITT use su criterio para actuar, en todo momento y de todas las formas, en el mejor interés debiendo evitar conflictos de intereses reales o aparentes, desempeñar sus roles y conducir su actuación con legalidad, imparcialidad, objetividad, transparencia, certeza, cooperación, ética e integridad, sin entorpecer procesos, aceptar compensaciones y demás conductas que se describen en la Política Anticorrupción de la empresa.

Por ejemplo, un conflicto de intereses puede ocurrir cuando un colaborador o un miembro de su familia recibe un beneficio personal como resultado de su posición dentro de la empresa. También puede surgir un conflicto de intereses a partir de su relación personal con un cliente, proveedor, competidor, socio comercial u otro personal de UBBITT, si esa relación perjudica o puede percibirse que perjudica su criterio comercial objetivo.

Otras áreas específicas de posibles conflictos de intereses, como las relaciones comerciales externas, la participación en juntas directivas, inversiones personales, obsequios, entretenimiento, oportunidades corporativas y ciertas relaciones personales con otro personal de la empresa se analizan con más detalle en la Política Anticorrupción de la empresa, por lo que ante cualquier situación donde se tenga la duda la mejor regla es abstenerse y revisas la política existente.

Si no es posible evitar participar en el evento o actividad que crea el conflicto, el involucrado deberá seguir el siguiente proceso:

Divulgar de inmediato el posible conflicto a la Dirección de Recursos Humanos y a la Dirección Legal, y

Evitar participar en decisiones que podrían dar lugar a la aparición de un conflicto hasta que reciba la orientación adecuada.

Algunos posibles conflictos de interés específicos a tener en cuenta son los siguientes:

Compromisos comerciales y de consultoría externos. Todos los colaboradores de UBBITT deben obtener la aprobación de la Dirección de su área, de la Dirección de Recursos Humanos y de la Dirección Legal antes de (1) iniciar cualquier relación laboral, comercial o de consultoría con otra empresa que sea un competidor actual o potencial de UBBITT o que de otra manera tenga una relación comercial con UBBITT, o (2) aceptar cualquier compromiso de enseñanza con una institución educativa, establecimiento u otra organización. Además, debe evitar realizar negocios de UBBITT con miembros de su familia u otras personas con quienes tenga una relación personal o financiera significativa sin la aprobación previa de las Direcciones antes referidas.

Pertenecer a la Junta Directiva y/o invertir en otras compañías que puedan ser competencia de UBBITT. Alentamos a los colaboradores de UBBITT a participar activamente en la industria y las asociaciones cívicas. Sin embargo, se requiere que aquellos colaboradores que deseen formar parte de Juntas Directivas y/o invertir y/o asesorar cualquier entidad u organización, que sea competencia de UBBITT, obtenga la aprobación de la Dirección de su área y de la Dirección Legal. Se permite cualquier inversión pasiva de no más del dos por ciento (2%) del total de acciones en circulación de una empresa, sin la aprobación de UBBITT, siempre que la inversión no sea tan grande financieramente (ya sea en dólares absolutos o en porcentaje de su total cartera de inversiones) que crea la apariencia de un conflicto de intereses. Sin embargo, cualquier inversión en más del dos por ciento (2%) de una empresa pública o cualquier inversión en una empresa privada que sea un competidor actual o potencial de UBBITT o que tenga una relación comercial con UBBITT requiere la aprobación previa de la Dirección de su área, de la Dirección Legal y de la Dirección Ejecutiva. UBBITT se reserva el derecho de revisar cualquier aprobación previa de inversión para evitar un conflicto de intereses real o aparente.

Regalos y entretenimiento. Recibir cualquier obsequio o regalo en la contratación de algún servicio con el objetivo de agilizar la contratación o dar por visto ciertas circunstancias.

Se podrá considerar apropiado aceptar o proporcionar cierto entretenimiento comercial (como una invitación para asistir a un evento cultural o deportivo local, o una comida de celebración con un socio comercial), siempre que el entretenimiento sea razonable y habitual y fomente una relación comercial; y el costo del entretenimiento no sea excesivo; y no sesgará inapropiadamente la toma de decisiones futuras sobre trabajar con el socio o crear una apariencia de incorrección. Nunca debe dar o recibir efectivo, equivalentes de efectivo (como tarjetas de regalo), préstamos o cualquier artículo que lo obligue a proporcionar algo a cambio, y no debe solicitar activamente regalos o entretenimiento de un cliente o socio comercial actual o potencial. Si bien no puede dar nada de valor a un funcionario del gobierno para obtener o mantener negocios u obtener una ventaja inadecuada, puede proporcionar obsequios, comidas, entretenimiento y viajes o alojamiento modestos a los funcionarios del gobierno cuando haya un propósito legítimo y algo de valor no se proporciona a cambio de ninguna acción u omisión del funcionario.

La Política Anticorrupción de UBBITT regula los obsequios, comidas, viajes y entretenimiento que involucran a funcionarios del gobierno.

Oportunidades corporativas. El personal de UBBITT no puede explotar ni aprovechar las oportunidades comerciales que se descubren mediante el uso de la propiedad, la información o el puesto de UBBITT para beneficio personal a menos que la oportunidad se divulgue por completo a la empresa e UBBITT se niegue a aprovechar esa oportunidad.

Relaciones potencialmente conflictivas: UBBITT no prohíbe las citas entre el personal de UBBITT, ni prohíbe que los familiares trabajen juntos dentro, para o en nombre de la empresa. Sin embargo, si una relación potencialmente conflictiva, romántica o de otro tipo, involucra a dos empleados en una relación de denuncia directa, en la misma cadena de mando, o crea un conflicto de intereses real o aparente, los empleados deben revelar la relación a Recursos Humanos. Si bien ambos empleados tienen la responsabilidad de revelar cualquier conflicto potencial, si usted es un gerente, su falta de divulgación adecuada puede resultar en una disciplina más seria.

Al enterarse de cualquier posible conflicto, UBBITT puede reasignar al menos a una de las personas a un puesto o rol diferente dentro de la empresa. En cualquier caso, cuando su pareja, pariente o cualquier otra persona potencialmente conflictiva esté dentro de su cadena de mando, debe abstenerse de tomar decisiones sobre la compensación, promoción, disciplina o despido de la persona y debe abstenerse de participar en su / su evaluación de desempeño. Si tiene una relación personal o financiera con cualquier proveedor de servicios de UBBITT, como proveedores o trabajadores eventuales, para los cuales tiene responsabilidades relacionadas con el trabajo (por ejemplo, donde es el gerente de asignación de UBBITT, participa en la selección de proveedores, determinación de los términos de un contrato o cesión, etc.) debe divulgar esa relación al área de Legal y abstenerse de tomar cualquier decisión con respecto a ese proveedor de servicios. Además, la Junta Directiva de UBBITT puede adoptar de vez en cuando políticas separadas con respecto a los conflictos de intereses de los directores para abordar las circunstancias particulares que surgen de su función como miembros de la junta. Cualquier política de este tipo reemplazará las pautas de conflictos de intereses anteriores en la medida aplicable.

En UBBITT, estamos comprometidos a respetar los Derechos Humanos y la integridad de todos nuestros empleados, por lo que formalizamos nuestro compromiso con los derechos humanos y laborales reconocidos en la Ley Federal del Trabajo vigente en los Estados Unidos Mexicanos y ante la Comisión Nacional de Derechos Humanos alineada con nuestros principios. Creemos que las empresas solo pueden prosperar en sociedades donde respetan los derechos humanos y reconocemos que la empresa tiene la responsabilidad de respetar los derechos humanos y la capacidad de contribuir positivamente a ellos.

VII. COMUNICACIÓN

Todos los colaboradores de UBBITT deben asegurarse de que todos los registros comerciales y las comunicaciones (incluidos el correo electrónico, los mensajes de texto, los mensajes grupales digitales de UBBITT y los mensajes instantáneos) sean claros y precisos. Recuerde que sus comunicaciones comerciales pueden compartirse o hacerse públicas mediante litigios, investigaciones gubernamentales o publicaciones en los medios de comunicación. Los riesgos potenciales de declaraciones inexactas o engañosas incluyen reclamos de publicidad falsa, tergiversación, incumplimiento de contrato, fraude de valores, divulgación injusta y violaciones antimonopolio. Debe consultar con la Direccióm Legal y el director de su área antes de hacer declaraciones formales o proporcionar información sobre UBBITT, nuestros productos o nuestro negocio y personal de UBBITT a periodistas, bloggers y analistas de la industria a través de cualquier foro público (como un feria o conferencia o su perfil de UBBITT).

Además, no puede dar un endoso u otra declaración en nombre de UBBITT o un endoso personal que identifique su afiliación con UBBITT, excepto cuando sea aprobado por la Dirección Legal. Además, no puede discutir los negocios de UBBITT, incluidas las condiciones financieras, el desempeño comercial o financiero, los productos o las perspectivas comerciales con analistas financieros o inversionistas reales o potenciales sin la aprobación previa del CEO.

Todas las solicitudes de un representante de UBBITT para participar en una conferencia financiera (incluyendo hablar en un panel o asistir a una cena o cualquier evento que se dirija a la comunidad financiera) deben remitirse a Back Office. Si alguno de estos analistas o inversores se comunican con usted, remita dichas consultas al CEO quien tomará las decesiones al respecto.

VIII. DIVULGACIONES PÚBLICAS EN MATERIA FINANCIERA

UBBITT está comprometido con la transparencia e integridad de nuestros informes financieros los cuales podrán ser presentados públicamente o a través de otras comunicaciones. Nuestro CEO y el CFO, así como las personas que desempeñan funciones similares se consideran nuestros “oficiales financieros superiores” y son responsables de garantizar que la divulgación en los informes periódicos de UBBITT sea completa, justa, precisa, oportuna y comprensible.

IX. COMPROMISO DE LA EMPRESA

Los colaboradores de la Sociedad deberán conocer y observar las diferentes Políticas y Procedimientos establecidos y divulgados por la empresa como parte de su arquitectura de gobierno y control para la prevención, identificación, solución y seguimiento de los diferentes riesgos que inciden sobre las actividades desarrolladas por la Sociedad, definidas en el programa de cumplimiento normativo de UBBITT.

Así mismo, los colaboradores se encargarán de conducir su actuar en todo momento con profesionalismo, buena fe, lealtad y diligencia en favor de los mejores intereses de UBBITT, asegurando que todas las actuaciones propias y las responsabilidades asignadas por el cargo desempeñado se desarrollen en el marco de los principios y valores contenidas en éste Código y demás documentos de Gobierno Corporativo de la empresa, tales como políticas y procedimientos.

Comunicarán oportunamente a sus superiores inmediatos todo hecho o irregularidad por parte de otro colaborador, que afecte o pueda lesionar los intereses de la empresa o que resulte violatorio del presente Código.

Se conducirán con prudencia y respeto tanto en el comportamiento, como en el lenguaje en las instalaciones de la empresa, manejando con seriedad, responsabilidad y discreción sus relaciones personales con otros colaboradores de la empresa, cuidando que las mismas no afecten de ninguna manera el desempeño laboral, ni resten la objetividad e independencia requeridas para la adopción de las decisiones que corresponden.

Ante cualquier controversia, primará el diálogo, el interés de resolverlo, el respeto al derecho de defensa y la preservación de los derechos y de la dignidad de los involucrados, dentro del marco de los objetivos y lineamientos propios de UBBITT.

Se comprometen los colaboradores a dar un buen uso a las instalaciones, equipos, sistemas de información y demás elementos de trabajo asignados y como parte de ello, instalar únicamente programas y dispositivos de hardware autorizados por la empresa, respetando los lineamientos definidos para ello y los establecidos en la Política de Seguridad de la Información, respondiendo por los códigos de usuario y contraseñas asignadas para el desempeño de sus responsabilidades y mantenerlas de manera confidencial.

Así mismo, se comprometen a respetar la propiedad intelectual y derechos de autor.

X. PRIVACIDAD Y CONFIDENCIALIDAD

En UBBITT estamos conscientes de la responsabilidad de respetar la privacidad como parte del código ético de todos los que forman parte del grupo.

En ejercicio de la actividad empresarial del grupo se maneja la recopilación, el control y el intercambio de información personal de forma habitual, por ello se cuenta con una política específica para proteger los derechos y responsabilidades asociados con la recopilación y el uso de datos personales.

Los datos personales que recibe la empresa de sus colaboradores, clientes, proveedores o en ejercicio de su actividad comercial únicamente se utilizan con fines legítimos, sin violar los derechos de individuos y grupos. Para ello, se generan los avisos de privacidad necesarios para que cada área correspondiente los de a conocer a los usuarios, evitando la recopilación de datos no autorizados, garantizando la exactitud éstos, conocer su procedencia y protegerlos contra el acceso no autorizado y la divulgación accidental, así como dar a conocer las finalidades de la recolección de datos, el tratamiento que se les da a los mismos y los derechos que el titular de estos datos tienen para ejercer y los mecanismos para hacerlo.

Con relación a la confidencialidad de la información, UBBITT posee información confidencial como secretos comerciales, datos de clientes, estrategias comerciales que no son públicas, información financiera, datos de investigación, obras no publicadas, solicitudes de marca, entre otra información de similar naturaleza, por lo que reitera su compromiso del deber de proteger la confidencialidad, mediante los mecanismos legales y operacionales pertinentes que aseguren el resguardo debido.

X. COMPROMISO AMBIENTAL

UBBITT se compromete a realizar prácticas operativas seguras en materia de medio ambiente, a través de la integración de principios, programas y prácticas relacionadas con el mismo en cada una de las operaciones que realiza, así como dentro de cada área de la empresa.

Lo anterior se desglosa más a fondo en la Política Ambiental de la empresa.
                    </div>
                </div>


                <Footer />
            </main>
        </>
    )
}

export default CodigoEtica;