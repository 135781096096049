import React from "react";

const Video = () => {
    return (
        <div className="video__container">
            <video className="w-100" src="https://res.cloudinary.com/ubbitt-frontend/video/upload/v1648518792/UBBITT.COM/ubbitt_ventas_2022_jfalq9.mp4" controls></video>
        </div>
    )
}

export default Video;