import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
// import Login from "../../components/Login/Login";

const Nav = () => {
  const [show, setShow] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);

  return (
    <nav className="navbar sticky-top">
      <div className="container nav_elements_home">
        <Button className="btn_about_ubbitt" variant="" onClick={handleShow}>
          ¿Qué es Übbitt?
        </Button>

        <Modal className="modal_about_us" show={show} onHide={handleClose}>
          <Modal.Body>
            <Button className="btn btn_close_modal" onClick={handleClose}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
                alt=""
              />
            </Button>
            <h5 className="text-left">¿Qué es Übbitt Store?</h5>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                <video
                  className="w-100"
                  src="https://res.cloudinary.com/ubbitt-frontend/video/upload/v1648518792/UBBITT.COM/ubbitt_ventas_2022_jfalq9.mp4"
                  controls
                ></video>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                <p>
                  <span>Ubbitt es un mercado multiservicio.</span> Existen
                  servicios que en ocasiones no son tan fáciles de entender como
                  servicios financieros, pólizas de seguros , planes de ahorro,
                  seguro de vida, entre otros. Muchos de estos servicios tienen
                  complicados contratos y condiciones.{" "}
                </p>
                <p>
                  En Ubbitt somos especialistas en hacer estas adquisiciones,
                  que en muchos casos son necesarias, muy fáciles y rápidas para
                  ti. Nuestra plataforma Ubbitt es muy fácil de usar. Cotiza en
                  Línea o solicita Atención Personal. Ubbitt te ayudará de forma
                  fácil y rápida a encontrar el servicio que buscas.
                </p>
                <p>
                  Al elegir Atención Personal los Socios expertos Ubbitt
                  atenderán todas tus dudas de forma muy clara y fácil
                  ayudándote a elegir la mejor opción para ti en el servicio que
                  deseas adquirir. Nuestros socios expertos Ubbitt están
                  disponibles vía Línea Telefonica, WhatsApp, Facebook
                  Messenger, Chat Ubbitt y Videollamada de asistencia para
                  compra en línea.
                </p>
                <p>
                  Al terminar tu compra, podrás consultar tu historial de
                  compra, siguientes pagos así como contrato de servicios dentro
                  de nuestra aplicación Ubbitt. Además puedes activar o
                  desactivar pagos recurrentes cargo directo tu tarjeta si así
                  lo deseas, de esta forma todos tus servicios estarán cubiertos
                  en tiempo y forma desde tu cuenta.
                </p>
                <p>
                  En Ubbitt te ayudamos a que todo tus servicios estén en un
                  solo lugar de forma fácil y rápida.{" "}
                  <span>Recuerda tu vida es mas fácil con Ubbitt.</span>
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className="user_toggle_icon" onClick={handleShowLogin}>
          <i className="icon-user_menu"></i>
        </div>
        <Modal
          show={showLogin}
          onHide={handleCloseLogin}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal_login"
        >
          <Modal.Body >
          <Button className="btn btn_close_modal" onClick={handleClose}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
                alt=""
              />
            </Button>
            <form action="">
              <h5>Iniciar sesión</h5>
              <input
                className="form-control"
                type="text"
                placeholder="Correo electrónico"
              ></input>
              <input
                type="password"
                className="form-control"
                id="inputPassword4"
                placeholder="Contraseña"
              ></input>
              <button type="submit" className="btn btn-primary">Iniciar</button>
              <small>¿Olvidaste tú contraseña?</small>
            </form>
            <hr />
            <p>¿Aún no tienes una cuenta? <br /> <span>Regístrate aquí</span></p>
          </Modal.Body>
        </Modal>
      </div>
    </nav>
  );
};
export default Nav;
