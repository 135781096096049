import React, { Component } from "react";

class Beyond extends Component {
  render() {
    return (
      <>
       
        <div className="meetings-iframe-container" data-src="https://meetings.hubspot.com/eric-alejandro/beyond?embed=true"></div>
      </>
    );
  }
}

export default Beyond;
