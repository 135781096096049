export const Products = [
    {
        "id": 1,
        "name" : "Seguro de auto",
        "icon" : "icon-auto",
        "img" : "/assets/images/logos_empresas/a/vendor_mapfre.svg",
        "stars":  125,
        "reviews" : 125,
        "users" : 125,
        "call_center": 528000627373,
        "ecommerce": "https://ecomm-mpfr.ubbitt.com/"
    },
    {
        "id": 2,
        "name" : "Paquete TV + Internet",
        "icon" : "icon-internet",
        "img" : "/assets/images/logos_empresas/a/vendor_totalplay.svg",
        "stars":  125,
        "reviews" : 125,
        "users" : 125,
        "call_center": 0,
        "ecommerce": "https://totalplaypaquete.com/amazon/"
    },
    {
        "id": 3,
        "name" : "Seguro de vida",
        "icon" : "icon-likes",
        "img" : "/assets/images/logos_empresas/a/vendor_ansuz.svg",
        "stars":  125,
        "reviews" : 125,
        "users" : 125,
        "call_center": 0,
        "ecommerce": "https://ansuz.com.mx/"
    },
    {
        "id": 4,
        "name" : "Hotel + avión",
        "icon" : "icon-plane",
        "img" : "/assets/images/logos_empresas/a/vendor_boniviajes.svg",
        "stars":  125,
        "reviews" : 125,
        "users" : 125,
        "call_center": 0,
        "ecommerce": "https://boniviajes.com/"
    }
    ,
    {
        "id": 5,
        "name" : "Seguro de Gastos Médic…",
        "icon" : "icon-medico",
        "img" : "/assets/images/logos_empresas/a/vendor_meddi.png",
        "stars":  125,
        "reviews" : 125,
        "users" : 125,
        "call_center": 0,
        "ecommerce": "https://meddi.mx/"
    }
];

// export default Products;