import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";

// Common
import NavSocios from "../common/header/NavSocios";
import Footer from "../common/footer/Footer";

// Components
import SociosBeneficios from "../components/Socios/SociosBeneficios";
import SociosDescripcion from "../components/Socios/SociosDescripcion";
import SociosForm from "../components/Socios/SociosForm";
import SociosQA from "../components/Socios/SociosQA";
// import SociosRequsitos from "../components/Socios/SociosRequisitos";
import SociosSeguridad from "../components/Socios/SociosSeguridad";
import SociosTestimonial from "../components/Socios/SociosTestimonial";
import SociosPostulacion from "../components/Socios/SociosPostulacion";

const Socios = () => {
  return (
    <>
      <SEO title="Socios" />
      <main className="page-wrapper">
        <NavSocios/>
        <div className="container socios_view">
          <div className="row flex-row-reverse container_socios_view">
            <div className="col-sm-12 col-lg-4">
            <div className="mobile_elm">
                <p className="empresas_txt">Socios</p>
                <hr />
              </div>
            <SociosForm />
            </div>
            <div className="col-sm-12 col-lg-8">
              <SociosDescripcion />
              <SociosTestimonial />
              <SociosBeneficios />
              <SociosSeguridad />
              {/* <SociosRequsitos /> */}
              <SociosPostulacion />
              <SociosQA />
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Socios;
