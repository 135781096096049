import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";

// Common
import NavLegal from "../common/header/NavLegal";
import Footer from "../common/footer/Footer";

const SeguridadPrivacidad = () => {
  return (
    <>
      <SEO title="Políticas de seguridad y privacidad" />

      <main className="page-wrapper">
        <NavLegal />
        <div className="container">
          <div className="container_legales col-10">
            <h3 className="main_ttl_legal">
              Políticas de seguridad y privacidad
            </h3>
            Conocidos y aceptados los Términos que rigen la plataforma de
            UBBITT, damos a conocer nuestra Política de Seguridad y Privacidad,
            la cual usted consintió en aceptar al momento de utilizar la
            plataforma.
            <h2>CONTENIDO</h2>
            En relación con los datos personales otorgados por el usuario. En
            relación con los datos del contacto (lead / clientes potenciales)
            que se originen como parte de los Servicios contratados. En relación
            con los datos personales otorgados por el usuario: Al suscribirse al
            uso del software UBBITT estará bajo la aplicación de la presente
            Política de Seguridad y Privacidad vigente en cada momento (en
            adelante, la Política de Seguridad y Privacidad), debiendo revisar
            dicho texto para comprobar sus actualizaciones y que está conforme
            con él. Esta Política de Privacidad aplica a UBBITT y permite la
            transmisión de determinada información a nuestros proveedores y/o
            terceros necesarios para la operatividad de éste. No aplica a la
            información que nuestros Partners importan a nuestros servicios,
            sean datos de sus clientes o contactos de negocio, usuarios finales
            u otros a los que les pertenecen esos datos. Los datos personales
            que nos aporta el usuario son nombre, apellidos, teléfono, datos de
            identificación oficial, registro de información fiscal, correo
            electrónico, los cuales serán tratados conforme a lo establecido en
            nuestro Aviso de Privacidad, y que serán conservados en una base de
            datos responsabilidad de UBBITT S.A. DE C.V. cuyas finalidades son:
            Facilitar el inicio de sesión en la plataforma; Para cumplir
            obligaciones derivadas de las relaciones jurídicas que surjan con el
            usuario; incluyendo información corporativa y atención al cliente;
            Proveer y entregar los servicios que se requieran, procesar
            transacciones y enviar información relacionada a las mismas. Atender
            las solicitudes que nos plantee. Enviar avisos técnicos,
            actualizaciones, alertas de seguridad y mensajes con carácter
            administrativo; Responder comentarios, preguntas o peticiones hechas
            a través de nuestro servicio al cliente; Monitorizar y analizar los
            datos obtenidos al hacer uso de la plataforma y que vayan de la mano
            de nuestros servicios ofrecidos; Personalizar y mejorar los
            servicios ofrecidos continuamente para que cumplan con los
            estándares deseados por el usuario; Cumplir con nuestras
            obligaciones legales y financieras; Proporcionar información que
            sirva para identificar estadísticas y ser usada para estrategias de
            mejoramiento; Investigar y ayudar a evitar problemas y abusos en
            materia de seguridad de la información. En el caso que nos aporte
            datos personales de terceros, el usuario se responsabiliza de haber
            informado y haber obtenido el consentimiento de éstos para ser
            aportados con las finalidades que se detallan en esta política y/o
            en el Aviso de Privacidad. Recuerde que la seguridad en el uso de
            UBBITT ® también depende de su correcta utilización y conservación
            de determinadas claves confidenciales. UBBITT ® no solicitará la
            contraseña del usuario por ningún medio. El usuario y/o los partners
            que contrate serán los únicos responsables de mantener la contraseña
            de acceso en secreto, así como los únicos responsable por los
            usuarios secundarios a su cargo, por lo que se recomienda la
            supervisión y canales de vigilancia sobre la utilización de UBBITT.
            Por la naturaleza de los servicios ofrecidos a través de UBBITT
            nuestros afiliados, partners y nosotros podemos compartir algunos
            datos con proveedores y/o otras empresas, incluyendo empresas del
            mismo grupo comercial en otros países, para que con el uso de esos
            datos se puedan poner en contacto con el usuario para recibir
            información sobre sus servicios y brindar atención personalizada de
            acuerdo a los intereses del mismo. En todo caso, se verificará que
            dichas empresas cumplan con las mismas protecciones en materia de
            seguridad de datos personales que son tomadas por nosotros. Los
            terceros a los cuales se le podrá transferir la información cumplen
            con nuestros estándares de asociación y confianza por lo que cuando
            sea el caso en que se transmitan los datos, se verificará que dichas
            empresas cumplan con las mismas protecciones en materia de seguridad
            de datos personales que son tomadas por nosotros. En caso de que no
            quiera que sus datos sean compartidos puede realizar el proceso de
            Oposición descrito a continuación. Sin embargo, esto puede afectar
            la prestación de servicios contratada. UBBITT ®, como responsable de
            la base de datos, se compromete a respetar la confidencialidad de
            sus datos personales y a garantizar el ejercicio de sus derechos de
            Acceso, Rectificación, Cancelación y Oposición (derechos ARCO),
            mediante el procedimiento señalado en nuestro Aviso de Privacidad,
            que deberá iniciar con solicitud al correo soporte@ubbitt.com. con
            la siguiente información: i. DATOS DEL TITULAR: Nombre Completo,
            Domicilio Completo, Medios para comunicar la resolución: Teléfono
            y/o Correo electrónico (donde se le comunicará la respuesta a su
            solicitud). ii. DOCUMENTO QUE ACREDITE SU PERSONA. Identificación
            legal. En caso de ser representante legal del titular deberá
            acompañar a su escrito el instrumento público correspondiente en
            original, o en su caso, carta poder firmada ante dos testigos. iii.
            DERECHOS ARCO: Indicar el/los derecho(s) que desea ejercer: Acceso,
            Rectificación, Cancelación y/o Oposición. Asimismo se deberá hacer
            una descripción de los datos personales respecto de los que se busca
            ejercer el/los derecho(s) señalados anteriormente y/o cualquier otro
            comentario que nos ayude a atender mejor su derecho. iv. OTRA
            DOCUMENTACIÓN NECESARIA: Favor de acompañar la documentación que
            considere sustente su solicitud y nos ayude a tramitarla
            convenientemente. En particular, en la solicitud de rectificación de
            datos personales (dato incorrecto, dato correcto y documento
            acreditativo). El usuario (usted) por la presente garantiza que los
            datos personales proporcionados son ciertos y exactos y se
            compromete a notificar cualquier cambio o modificación de los
            mismos. Cualquier pérdida o daño causado a UBBITT o a la persona
            responsable del manejo de la plataforma o a cualquier tercero,
            proveedor y/o afiliado, mediante la comunicación de información
            errónea, inexa8. La información solicitada al usuario por parte de
            UBBITT será para uso exclusivo de la empresa y no se compartirá bajo
            ninguna circunstancia, salvo los casos anteriormente especificados y
            señalados en el Aviso de Privacidad. UBBITT implementará las medidas
            de seguridad, técnicas, administrativas y físicas, necesarias para
            procurar la integridad de sus datos personales y evitar su daño,
            pérdida, alteración, destrucción o el uso, acceso o tratamiento no
            autorizado. Únicamente el personal autorizado, que ha cumplido y
            observado los correspondientes requisitos de confidencialidad, podrá
            participar en el tratamiento de sus datos personales. El personal
            autorizado tiene prohibido permitir el acceso de personas no
            autorizadas y utilizar sus datos personales para fines distintos a
            los establecidos en el Aviso de Privacidad. La obligación de
            confidencialidad de las personas que participan en el tratamiento de
            sus datos personales subsiste aun después de terminada la relación
            con UBBITT ® Por ello, UBBITT® sólo podrá transferir los datos
            personales del usuario, tanto a entidades nacionales como
            extranjeras, sin requerir su consentimiento en los supuestos
            señalados en el Aviso de Privacidad que corresponde a: Proveedores,
            afiliados, partners y/o demás terceros que sean necesarios para
            realizar las estrategias de marketing que se acoplen a los servicios
            solicitados por el usuario, así como los demás servicios contratados
            mediante UBBITT. Autoridades competentes y terceros en los casos
            legalmente previstos. A las empresas del grupo, afiliadas o
            subsidiarias, para que éstas le puedan contactar, directa o
            indirectamente, para llevar a cabo actividades de promoción y/u
            ofrecimiento de productos y/o servicios que dichas empresas pueden
            comercializar y/o prestar de manera conjunta con UBBITT® o de manera
            independiente. Le informamos que las empresas del grupo operamos
            bajo las mismas políticas de privacidad que cumplen con las
            disposiciones aplicables correspondientes. UBBITT asume la
            obligación de mantener confidencial cualquier otra información que
            el usuario proporcione incluyendo aquella información que el usuario
            proporcione a través de boletines, pláticas en línea, así como
            información obtenida a través de cookies lo anterior en términos de
            lo establecido en el artículo 109 de la Ley Federal de los Derechos
            de Autor y de la fracción I del artículo 76 bis de la Ley Federal
            del Protección al consumidor. Siendo que sólo podrá difundirla o
            transmitirla a los terceros autorizados en las presentes políticas y
            en el Aviso de Privacidad. Si el usuario desea eliminar y/o
            recuperar alguna información personal de la cual UBBITT tenga
            conocimiento, podrá hacerlo mediante el proceso establecido para el
            ejercicio de los derechos ARCO en el Aviso de Privacidad. Las
            cookies son archivos de texto que contienen pequeñas cantidades de
            información y que se descargan en el dispositivo del usuario cuando
            visita un Sitio web o plataforma. Posteriormente, las cookies se
            envían de vuelta a la página web de origen (cookies de origen) o a
            otras páginas web (cookies de terceros). Las cookies permiten que
            una página web o plataforma reconozca el dispositivo de un usuario,
            facilitando la navegación, recordando preferencias, mejorando la
            experiencia de usuario y, en general, ayudando a ofrecerle
            información y servicios relacionados con sus intereses. Es necesario
            tener habilitadas las cookies en algunas secciones de nuestra
            plataforma para poder efectuar algunas funciones. *En relación con
            los datos del contacto (lead / clientes potenciales) que se originen
            como parte de los Servicios contratados. La información obtenida en
            el ejercicio de las funciones para el cumplimiento de los servicios
            contratados en la plataforma UBBITT, con relación a los clientes
            potenciales (leads) será entregada en su totalidad al usuario.
            <h2>1)Protección y seguridad de la data:</h2>
            UBBITT resguarda la información obtenida en ejercicio de sus
            funciones en un servidor seguro que reside a través del servicio en
            la nube de RACKSPACE para el almacenamiento de datos. La tecnología
            utilizada por RACKSPACE es altamente escalable y confiable por lo
            que cuenta con una arquitectura moderna de todos los servicios
            conocidos del modelo Cloud, en la cual se evidencia el cumplimiento
            de las normas ISO/IEC 27001 – ISO 9001mediante certificaciones que
            los hacen un servicio robusto en el almacenamiento y protección de
            datos. Así mismo, UBBITT cuenta con el servicio de aplicación de
            seguridad de Veracode para garantizar la seguridad y confianza en la
            plataforma, de modo que dicha aplicación revisa, vigila y detiene
            las potenciales vulnerabilidades de seguridad analizando el código
            fuente, proporcionando información valiosa de análisis estático y
            dinámico para determinar si el código es susceptible de presentar
            fisuras de seguridad que sean explotables por robots o
            desarrolladores malintencionados ya sea internos o externos. La
            inclusión de esta herramienta nos permite proporcionar una
            confiabilidad extraordinaria para la solución UBBITT en conjunto
            para todas las operaciones soportadas actualmente y en el futuro Es
            importante recalcar que ninguna conexión a internet es 100% segura
            por lo que UBBITT se esforzará por guardar y proteger al máximo esta
            información a través de nuestros sistemas de seguridad. Contamos con
            conexiones seguras vía https, así como con protocolos de cifrado
            tales como certificados de seguridad vigentes SSL (Secure Sockets
            Layer) que proporcionan seguridad a la conexión.
            <h2>2)Datos personales: </h2>
            UBBITT manifiesta y reconoce que los datos personales obtenidos y
            comercializados mediante los servicios ofrecidos en la plataforma,
            son recolectados cumpliendo con las obligaciones que en materia de
            tratamiento de datos personales les impone la Ley Federal de
            Protección de Datos Personales en Posesión de los Particulares, en
            especial las relativas a la obtención y autorización al tratamiento
            de los datos personales, mediante el aviso de privacidad y en su
            caso el consentimiento expreso. Por lo anterior, el usuario en
            aceptación a la transmisión de los datos personales que se originen
            por los potenciales clientes (leads) asume tratar los datos conforme
            a su Aviso de Privacidad y al tratamiento impuesto por las leyes
            aplicables en materia de protección de datos. Por su parte, UBBITT
            garantiza que la base de datos que sea recolectada en función del
            ejercicio de los servicios contratados pertenecerá en propiedad al
            usuario, quien será responsable del manejo de esta, librando a
            UBBITT de cualquier responsabilidad sobre el uso de dichos datos.
            Así mismo, UBBITT garantiza que los datos obtenidos (leads) serán
            transferidos en su totalidad al usuario de manera periódica, no
            quedando copia alguna de resguardo en el almacenamiento de datos
            UBBITT. UBBITT sólo podrá reservar para sí, datos no considerados
            como personales, tales como: edades, genero, ubicación, que no sean
            identificables para una persona, para fines estadísticos y de mejora
            continua de la plataforma.
          </div>
        </div>

        <Footer />
      </main>
    </>
  );
};

export default SeguridadPrivacidad;
