import React from "react";
import SEO from "../common/SEO";
import { Tabs, Tab, AppBar } from "@mui/material";
import { Link } from "react-router-dom";

// Common
import NavLegal from "../common/header/NavLegal";
import Footer from "../common/footer/Footer";
import NavProductos from "../common/header/NavProductos";

//Components
import Beyond from "../components/Planes/Beyond";
import Freemium from "../components/Planes/Freemium";
import Premium from "../components/Planes/Premium";

const Planes = (props) => {
  const { match, history } = props;
  const { params } = match;
  const { page } = params;

  const tabNameToIndex = {
    0: "freemium",
    1: "premium",
    2: "beyond"
  };

  const indexToTabName = {
    freemium: 0,
    premium: 1,
    beyond: 2
  };

  //   console.log(page);
  const [selectedTab, setSelectedTab] = React.useState(indexToTabName[page]);

  const handleChange = (event, newValue) => {
    history.push(`/planes/${tabNameToIndex[newValue]}`)
    setSelectedTab(newValue);
  };
  return (
    <>
      <SEO title="Planes" />
      <main className="page-wrapper">
      <NavProductos/>
        <div className="container plans_container">
          <p className="ttl_plan"><span>//</span> Planes</p>
          <Tabs value={selectedTab} onChange={handleChange}>
            <Tab className="tab_plan" label="Ubbitt Freemium" />
            <Tab className="tab_plan" label="Ubbitt Premium" />
            <Tab className="tab_plan" label="Ubbitt Beyond" />
          </Tabs>
          <hr className="separator_tabs" />
          {selectedTab === 0 && <Freemium />}
          {selectedTab === 1 && <Premium />}
          {selectedTab === 2 && <Beyond />}
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Planes;
