import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";

// Common
import NavLegal from "../common/header/NavLegal";
import Footer from "../common/footer/Footer";

const TerminosCondiciones = () => {
  return (
    <>
      <SEO title="Términos y condiciones uso de software" />

      <main className="page-wrapper">
        <NavLegal />
        <div className="container">
          <div className="container_legales col-10">
            <h3 className="main_ttl_legal">
              Términos y condiciones uso de software de Ubbitt
            </h3>
            Agradecemos que haya elegido “UBBITT” (de aquí en adelante, “UBBITT”
            o “software” o “plataforma”) el cual está operado por la empresa
            UBBITT S.A. DE C.V. que se identifica con Registro Federal de
            Contribuyentes clave UBB2102051P2 quien tiene su domicilio en la
            calle Oxford 36 Colonia Juárez Alcaldía Cuauhtémoc Ciudad de México
            C.P. 06600, quien posee todos los derechos correspondientes. Los
            presentes Términos y Condiciones, son un contrato entre usted y
            “UBBITT S.A. DE C.V. o alguna de sus filiales, donde se describen
            sus derechos y las condiciones en virtud de las cuales puede
            utilizar el Software. Es esencial leer el contrato completo, incluso
            los términos de licencia complementarios que acompañan al software,
            tales como Términos de la Licencia de Uso de UBBITT, Código de
            Ética, Aviso de Privacidad, Políticas de Seguridad y Privacidad,
            Políticas PCI, Políticas Anticorrupción y todos los términos
            relacionados que puedan existir, porque todos ellos son importantes
            y, en conjunto, constituyen el contrato que se le aplicará a Usted.
            Estos términos también serán de aplicación a las actualizaciones,
            suplementos y servicios relacionados con el software. Al aceptar el
            presente contrato o al utilizar el software, usted acepta todos
            estos términos, y brinda su consentimiento para la transmisión de
            determinada información durante la activación y durante el uso que
            usted haga del software conforme a la Política de seguridad y
            privacidad del “software”. Si no acepta o cumple estos términos, no
            podrá utilizar el software ni sus funciones. UBBITT no busca con la
            información contenida en este sitio ni con los productos y/o
            servicios que se comercializan ofender a persona alguna, sector de
            la población o entidad gubernamental, ya que toda imagen,
            información, producto, servicio, entre otros, es incluida en este
            software únicamente con el fin de la operatividad de la plataforma.
            UBBITT no discrimina a ninguna persona por cuestiones de religión,
            orientación sexual, situación económica y social o cualesquiera
            otras. Por lo tanto, la información contenida en este software, así
            como los productos y/o servicios que se comercializan no puede ser
            interpretada como discriminatoria en ningún sentido, y UBBITT no
            será responsable por interpretaciones diversas a lo aquí señalado.
            <ul>
              <li>1. Definiciones</li>
              <li>2. Política de Uso Aceptable. </li>
              <li>3. Uso de los servicios </li>
              <li>4. Tarifas y planes de inversión</li>
              <li>5. Vigencia y terminación</li>
              <li>6. Información del cliente</li>
              <li>7. Propiedad intelectual</li>
              <li>8. Confidencialidad</li>
              <li>9. Publicidad</li>
              <li>10. Indemnización</li>
              <li>11. Avisos legales y limitaciones de responsabilidad</li>
              <li>12. Varios</li>
            </ul>
            <h2>1. DEFINICIONES</h2>
            Accesorios. Se refiere a los accesorios adicionales en los productos
            y servicios disponibles para la compra que se señalan en el Anexo de
            Planes de Inversión. Acuerdo o Términos de servicio para clientes.
            Se refiere a estos Términos Principales y a todo el material que se
            menciona en este documento o que se vincula con él. Suscripción de
            plan de inversión. Es el formulario autorizado por UBBITT que se
            crea para suscripción a los servicios a través de la elección del
            Plan de Inversión que hará el cliente, mediante el proceso de pago
            en línea por medio del cual el usuario acepta adherirse al Servicio
            de suscripción del Plan de Inversión que elija con sus términos y
            condiciones. Contiene todos los detalles sobre la inversión,
            incluido el período de suscripción, los beneficios que incluye su
            inversión, en el retorno de la misma, los tiempos y sus tarifas. El
            Usuario encontrará su (s) formulario (s) de Plan de Inversión en la
            sección “Planes” de su cuenta. Período de facturación. Se refiere al
            plazo acordado para pagar las tarifas por adelantado según el
            Formulario de Suscripción al Plan de Inversión. Dicho período podrá
            ser igual o menor al Plazo de Suscripción especificado en el
            formulario. Información confidencial. Es toda la información
            confidencial divulgada por una parte («Parte divulgadora») a la otra
            parte («Parte receptora»), ya sea por vía oral o escrita, que se
            designa como confidencial. La Información confidencial incluye toda
            la información acerca de: clientes y clientes potenciales de la
            parte divulgadora; productos anteriores, presentes o propuestos;
            planes de marketing; diseños de ingeniería y otros; información
            técnica; planes empresariales; oportunidades comerciales; finanzas,
            investigación y desarrollo; y los términos y condiciones de este
            Acuerdo. La Información confidencial no incluye ninguna información
            que (i) sea o se convierta en información de conocimiento público
            sin incumplimiento de alguna obligación contraída con la Parte
            divulgadora; (ii) sea del conocimiento de la Parte receptora antes
            de su divulgación por parte de la Parte divulgadora sin incumplir
            ninguna de las obligaciones contraídas con la Parte divulgadora;
            (iii) sea recibida por un tercero sin incumplimiento de alguna
            obligación contraída con la Parte divulgadora; o (iv) haya sido
            desarrollada independientemente por la Parte receptora. Sujeto a las
            exclusiones anteriores, la Información del cliente se considerará
            Información confidencial de conformidad con este Acuerdo,
            independientemente de que se designe como confidencial o no.
            Contacto (Lead): Hace referencia a un individuo (distinto a un
            Usuario) cuya Información de contacto se almacena en la plataforma y
            cuya información proviene de los servicios proveídos por UBBITT.
            Información de contacto (lead). Puede incluir el nombre, la
            dirección de correo electrónico, el número de teléfono, el nombre de
            usuario online y otros datos similares que los visitantes envían a
            las páginas de destino (landing pages) o que han sido recabados por
            UBBITT o sus proveedores mediante diversas vías. Información del
            cliente. Se refiere a toda la información que se recopila a través
            del Servicio de suscripción a UBBITT. Materiales del cliente. Se
            refiere a todos los materiales que el usuario proporciona o publica,
            carga, introduce o envía para conocimiento de UBBITT mediante el
            Servicio de suscripción. Servicios gratuitos. Referido al Servicio
            de suscripción u otros productos o características que están
            disponibles de manera gratuita o por un periodo de prueba sin cargo.
            Contenidos de UBBITT. Comprende toda la información, datos, texto,
            mensajes, software, sonidos, música, videos, fotografías, gráficos,
            imágenes y etiquetas que se incorporan al Servicio de suscripción o
            a los demás Servicios. Pedido o Formulario de Suscripción al Plan de
            Inversión. Hace referencia al formulario autorizado por UBBITT o al
            proceso de suscripción online mediante el cual el usuario acepta
            adherirse a los planes de inversión y adquirir los Servicios Datos
            personales. Hace referencia a cualquier información relacionada con
            una persona identificada o identificable cuando dicha información
            está incluida dentro de la Información del cliente y protegida de
            manera similar a los datos personales o a la información
            personalmente identificable de conformidad con las Leyes de
            protección de datos. Servicios. Se refiere a los servicios a los
            cuales tendrá acceso el usuario por la adquisición de los Planes de
            Inversión y los cuales podrá conocer a detalle en los términos
            específicos de los planes de inversión. Términos específicos de los
            planes de inversión. Se hace referencia a los términos adicionales
            relacionados con los planes de inversión de UBBITT, referidos tanto
            a los servicios que ofrece la plataforma como a los servicios que
            puedan contratarse de terceros. Dichos términos forman parte del
            Acuerdo y se encuentran en el Anexo relacionado a los Planes de
            Inversión. Información sensible. Comprende números de tarjetas de
            crédito o débito, números de cuentas bancarias o instrucciones de
            transferencia, números de identificación emitidos por el
            instituciones gubernamentales (tales como pasaportes o ID),
            información biométrica, información sobre el estado de salud
            personal (o cualquier otra información amparada por las leyes
            vigentes de protección de datos de salud), y cualquier otra
            información o combinaciones de información que se encuentre incluida
            dentro de la definición de «datos sensibles» en virtud de las leyes
            de protección de datos personales o cualquier otra ley vigente
            relacionada con la privacidad y la protección de los datos. Tarifas
            de suscripción. Se refiere al monto a pagar por los Planes de
            Inversión. Plan de Inversión. Hace referencia al Plan de Inversión
            que haya escogido el cliente, incluyendo todas las herramientas y
            demás servicios a los que el usuario se ha suscrito mediante el
            Formulario de Suscripción al Plan de Inversión, o que se hayan
            proporcionado al usuario de otro modo, las cuales se desarrollan,
            manejan y mantienen por intermedio el software; así como cualquier
            producto o servicio auxiliar que se le suministre al usuario,
            incluido el alojamiento de sitios web. Plazo de suscripción. Hace
            referencia al plazo de los Planes de Inversión y por ende el acceso
            a los servicios correspondiente, según lo que se especifica en el
            Formulario de Suscripción al Plan de Inversión. En el caso de los
            Servicios gratuitos, el Plazo de suscripción corresponderá al
            periodo durante el cual el usuario mantenga una cuenta para acceder
            a dichos servicios. Productos de terceros. Son los productos no
            integrados y los servicios profesionales suministrados por terceros
            que interactúan con los Servicios contratados en el Plan de
            Inversión elegido o se usan en relación con este. Estos productos y
            servicios incluyen las aplicaciones ajenas a UBBITT de las que el
            usuario puede disponer o de las que la plataforma hace uso, como
            proveedores externos y/o plataformas de publicidad y redes sociales,
            necesarias para el fin de la inversión realizada y la retribución
            esperada. Sitios de terceros. Son los sitios web externos a los que
            se vincula desde el Servicio de suscripción, incluidos los Servicios
            de comunicaciones. Valor del Plan de Inversión total comprometido.
            Se refiere al monto total de Tarifas del Plan de Inversión elegido
            por el cliente, pagadas o por pagar durante el o los Plazos de
            suscripción para los servicios de la cuenta en UBBITT, la cual no
            incluye el monto o tarifa de renovación, ni los impuestos
            correspondientes. Usuarios de la cuenta. Se refiere a los que pueden
            ser empleados, representantes, asesores, contratistas o agentes
            autorizados para usar la cuenta para beneficio de usuario y/o
            cliente registrado y que tienen claves de identificación de usuarios
            y contraseñas únicas para el Servicio de suscripción, uso y manejo
            de la cuenta UBBITT. UBBITT S.A.. Hace referencia a la entidad
            contratante correspondiente. Usuario / Usuario Final / Cliente /
            Usted. Hacen referencia a la persona o entidad que usa o recibe los
            Servicios de UBBITT y que se identifica como el cliente en el
            registro de cuenta, factura, inversión, proceso de suscripción
            online o Formulario de Suscripción al Plan de Inversión
            correspondiente. 2. POLÍTICA DE USO ACEPTABLE DEL SITIO Usted acepta
            cumplir con todas las leyes, normas y reglamentos locales,
            estatales, nacionales y extranjeros aplicables en relación a su uso
            de UBBITT. Además, acepta que no hará nada de lo siguiente mientras
            use o accede a la Plataforma: Deshabilitar, piratear, eludir o
            interferir con las características relacionadas con la seguridad del
            Sitio o con cualquier característica que impida o restrinja el uso o
            la copia de cualquier contenido o material de UBBITT; Usar cualquier
            metadato, metaetiqueta u otro texto oculto utilizando un nombre de
            Leyenda, marca registrada, URL o nombre del producto que pertenezca
            o sobre los cuales tenga derechos UBBITT Usar la Plataforma para
            enviar información alterada, engañosa o falsa que identifique la
            fuente; Usar scripts, programas u otros medios automatizados para
            acceder a, o participar en cualquier ‘raspado de datos’ o prácticas
            de recopilación de datos con respecto a la plataforma; Destruir,
            interferir o interrumpir (o intentar interferir o interrumpir) las
            páginas web disponibles en la plataforma, los servidores o las redes
            conectadas a ella o romper cualquier requisito, procedimiento,
            política o normativa de las redes, conectadas a la plataforma;
            Intentar escanear, probar o probar la vulnerabilidad de cualquier
            sistema o red de UBBITT o violar o perjudicar o eludir cualquier
            medida de seguridad o autenticación que proteja y brinde seguridad
            para la plataforma; Intentar descompilar, desensamblar, descifrar o
            aplicar ingeniería inversa a cualquier software utilizado para
            proporcionar la plataforma; Intentar buscar o acceder a la
            plataforma con cualquier motor, software, herramienta, agente,
            dispositivo o mecanismo que no sea software y / o agentes de
            búsqueda proporcionados por UBBITT u otros navegadores web de
            terceros generalmente disponibles (como Microsoft Internet Explorer
            , Mozilla Firefox, Safari, Chrome). Tergiversar o hacerse pasar por
            su afiliación con cualquier persona o entidad, o cometer fraude de
            otra manera; Usar la plataforma de cualquier manera no permitida por
            los Términos de uso; o Instruir o alentar a cualquier otra persona a
            hacer cualquiera de los anteriores o romper o violar cualquier
            término de los Términos de uso. Al hacer uso de UBBITT y acceder a
            sus planes de inversión usted se compromete a hacer uso de esta
            plataforma únicamente para la Inversión y por ende los servicios que
            se adquieran en ella, pudiendo monitorear la inversión realizada y
            los resultados de la misma. Al suscribirse y registrarse como
            usuario de UBBITT, usted declara que posee la capacidad legal o que
            actúa con los poderes necesarios para celebrar el presente contrato
            por su propio derecho o como representante legal de un tercero. Si
            desea conocer más sobre los términos de la Licencia de uso
            concedida, le invitamos a que consulte el Documento: Término de
            Licencia de uso de UBBITT. USO DE LOS SERVICIOS DE UBBITT 3.1 Acceso
            / Registro de usuario. Una vez otorgada la Licencia de Uso de
            UBBITT, adquirida directamente por UBBITT S.A. o por medio de
            cualquiera de sus socios comerciales y/o agentes autorizados, se
            dará a los usuarios acceso a la plataforma para que procedan a Crear
            una cuenta personalizada mediante su Registro de Usuario. Usted
            deberá proporcionar toda la información que le sea solicitada en la
            plataforma, bien sea que la información provenga en ejercicio de su
            propio derecho o en representación de una persona moral o de algún
            tercero. Los datos solicitados serán tratados conforme a lo
            establecido en nuestro Aviso de Privacidad y Política de Seguridad y
            Privacidad que puede consultar en la plataforma. La cuenta creada
            mediante su registro será propia, personal, única e intransferible,
            que identificará con sus datos o los de su representada y una clave
            de seguridad (contraseña) que deberá integrarse con base en las
            especificaciones y/o algoritmos que el sistema de la plataforma le
            indique. Es responsabilidad exclusiva de usted mantener la
            confidencialidad de los datos de acceso, así como de todas las
            operaciones efectuadas en ella. Para concluir el proceso de registro
            de usuario, usted tendrá que leer y aceptar estos Términos y
            Condiciones y todos sus anexos complementarios, bien sea que los
            acepta en nombre propio o actuando en representación de un tercero.
            Se entiende que ha leído, comprendido y aceptado todas las
            Condiciones al momento de pulsar clic en el recuadro desplegado con
            la leyenda “Acepto los Términos y Condiciones de Uso”. Una vez
            concluido el registro se considera que ha leído, entendido y
            aceptado los presentes Términos y sus complementarios. Una vez
            registrado como usuario y con la cuenta activa dentro de UBBITT,
            podrá acceder a la suscripción del Plan de Inversión que desee.
            También la plataforma podrá brindarle acceso a determinados
            Servicios gratuitos en cualquier momento activándolos desde su
            cuenta. Asimismo, algunos o todos los elementos del Servicio de
            suscripción, así como de los servicios contratados en los Planes de
            Inversión, podrán ser suministrados por otros proveedores de
            servicios, a lo cual usted consiente. Como usuario principal podrá
            otorgar a sus propios usuarios afiliados acceso para usar la cuenta
            o autorizarlos para suscribir los Planes de Inversión, así como
            hacer uso de los Servicios adquiridos mediante el Plan adquirido
            siempre y cuando dicho uso, acceso y recepción por parte de estos
            cumplan con los términos del Acuerdo; siendo que el usuario
            principal serás responsable en todo momento del cumplimiento de este
            Acuerdo por parte de sus afiliados y/o dependientes. 3.2.
            Características adicionales. El usuario podrá suscribirse a
            características adicionales de las previstas en los Planes de
            Inversión adquiridos mediante un Pedido adicional o activando dichas
            características en su cuenta de UBBITT (si esta opción está
            disponible). Este Acuerdo se aplicará a todos los Servicios y a
            todas las características adicionales que active o solicite. 3.3.
            Compromiso de tiempo de actividad de servicio. Si el usuario
            requiere información detallada sobre el Compromiso de tiempo de los
            servicios contratados de forma específica, deberá consultar los
            Planes de Inversión donde se determinarán con precisión los
            servicios que adquiere el usuario por medio de su inversión, los
            alcances, así como el retorno de la misma. 3.4 Límites. Las
            limitaciones que se aplican a la suscripción a UBBITT se
            especificarán en el Formulario de Suscripción al Plan de Inversión
            elegido, y en el caso de las Suscripciones o el Acceso a Servicios
            gratuitos, estos límites también podrían designarse sólo desde el
            producto o servicio en sí. Si requiere más información sobre los
            límites que se aplican a su suscripción, consulta los Planes de
            Inversión . Edad, capacidad legal y responsabilidad. El usuario
            manifiesta, independientemente de su País de procedencia y del
            carácter con el que actúe, que él y los usuarios afiliados a los que
            permita el uso de UBBITT tienen la edad legal suficiente, así como
            la autorización y el poder requerido, en el caso de contrataciones a
            beneficio de un tercero (persona moral o física) para usar este
            sitio y suscribir y obligarse ante los presentes Términos y
            Condiciones, así como para hacerse responsable a las obligaciones
            legales que lo unan con la contratación por medios electrónicos
            especificados en estos Términos y Condiciones, y cualquier
            responsabilidad en que incurra por el uso de esta plataforma. El
            usuario comprende que él o su representado, será económicamente
            responsable por todo el uso que él o sus afiliados y/o dependientes
            hagan del software. 3.5. Modificaciones. A fin de que el usuario
            tenga una experiencia satisfactoria, de forma periódica se
            modificará el Servicio de suscripción y/o los servicios o productos
            ofertados y adquiridos en los cuales se encuentre su inversión,
            agregando o eliminando características o funciones, a los solos
            fines de mejorar la calidad y el uso de la plataforma. 3.6.
            Asistencia técnica al cliente. El usuario contará con asistencia
            técnica de forma continúa por medio de un correo electrónico
            soporte@ubbitt.com. 3.7.Uso prohibido y no autorizado. No podrá usar
            el Servicio de suscripción de manera tal que incumpla los términos
            del presente Acuerdo o con cualquier propósito o de cualquier manera
            que se considere ilegal o esté prohibida por este Acuerdo. No podrá
            usar el Servicio de suscripción y por ende acceder a los planes de
            inversión si las leyes del país en el que reside o desde el cual
            accede o usa el Servicio impiden acceder a él o utilizarlo. Los
            servicios ofrecidos en UBBITT no están diseñados para cumplir con
            regulaciones específicas de ningún sector regulado, de modo que
            deberá verificar que el uso de los servicios a los que invertirá no
            afecte las regulaciones a los cuales se encuentre sujeta. En caso de
            que usted pueda notar un uso no autorizado de su cuenta, deberá
            notificar de inmediato al correo:soporte@ubbitt.com. 3.8 Uso
            prohibido de información confidencial. El usuario acepta que el
            registro a UBBITT así como los servicios contratados mediante los
            planes de inversión no ha sido diseñado para procesar ni administrar
            información confidencial y, por lo tanto, acepta no utilizar la
            plataforma para recopilar, administrar o procesar información
            confidencial. UBBITT S.A. no es responsable y rechaza explícitamente
            toda responsabilidad que pudiera surgir si utiliza la plataforma
            para recopilar, procesar o administrar información confidencial y/o
            sensible. 3.9 Responsabilidades del cliente. Para maximizar todo el
            potencial de los Servicios ofrecidos por UBBITT en sus Planes de
            Inversión, así como los demás Servicios y productos que se ofertan,
            se necesita su participación y esfuerzo. Los recursos que podría
            sugerirle UBBITT para maximizar su inversión y obtener el retorno
            garantizado pueden incluir un gestor de proyectos, uno o más
            desarrolladores de contenido, un socio ejecutivo y un recurso
            técnico (o un puesto equivalente). Las responsabilidades que podrían
            requerirse incluyen la planificación de programas de marketing, el
            establecimiento de un calendario de creación de contenidos,
            contenidos en redes sociales, contenido de nutrición de
            oportunidades y otros materiales. Otras responsabilidades podrían
            ser servir de enlace interno entre ventas y marketing, brindar
            objetivos internos de mayor jerarquía para el uso de los Servicios,
            hacer reuniones periódicas de revisión y colaborar con la
            integración de los servicios con otros sistemas de ventas y
            marketing. Estos recursos se recomienda que puedan ser ejercidos por
            uno de los Socios (partners) comerciales debidamente autorizados
            para el manejo, la operación y administración de UBBITT. 3.10
            Servicios gratuitos. Si el usuario se registra para adquirir un
            servicio gratuito de los puede llegar a ofrecer la plataforma, se
            pondrán a su disposición sin cargo el Servicio correspondiente hasta
            (a) la fecha de finalización del periodo gratuito (si no termina
            antes) o (b) la fecha de inicio de la suscripción paga, lo que
            suceda primero. A menos que adquiera el Servicio de Suscripción
            correspondiente antes de que finalice el periodo gratuito, toda su
            información en el uso de dicho Servicio se eliminará de manera
            permanente al finalizar el periodo y no se podrá recuperar. TARIFAS
            Y PLANES DE INVERSIÓN 4.1. Tarifas en los planes de inversión. La
            suscripción a UBBITT de modo inicial será gratuita, y su operación
            podrá ir ajustada a la escogencia y selección de su Plan de
            Inversión. En el Documento: Planes de Inversión, usted podrá conocer
            todos los servicios a los que tiene acceso en base a la inversión a
            realizar en los servicios que desea adquirir. En el caso o supuesto
            que usted quisiera (i) aumentar los límites de leads, de usuarios
            afiliados u otras limitaciones pertinentes; (ii) actualizar los
            productos o los paquetes de inversión adquiridos; (iii) suscribirse
            a características o productos adicionales, o (iv) se acuerde un
            Formulario de suscripción a un nuevo Plan de Inversión, se deberán
            calcular y definir los montos determinados para el caso específico.
            Puede solicitar la información sobre ajustes en las tarifas mediante
            el correo: soporte@ubbitt.com. 4.2. Ajustes de tarifas en el momento
            de la renovación o nueva contratación. Al momento de la renovación
            de un Plan de Inversión o de la nueva contratación de un nuevo Plan,
            se podrán actualizar las tarifas al precio de lista vigente que
            figura en los Planes de Inversión. Al agotarse el monto y/o los
            tiempos que haya adquirido para la inversión, podrá seguir haciendo
            uso de UBBITT sólo en lo referente a la información adquirida por la
            inversión y los servicios que recibió, así como a la administración
            de su cuenta 4.3 Pago con tarjeta de crédito. Si el usuario paga la
            inversión con tarjeta de crédito, autoriza a cargar en su tarjeta o
            cuenta bancaria todas las tarifas pagaderas para el Plan de
            Inversión a los que se suscriba. Asimismo, autoriza a UBBITT a
            recurrir a un tercero para procesar los pagos y acepta la
            divulgación de su información de pago a dicho tercero. 4.4. Pago con
            depósito o transferencia bancaria. El usuario podrá pagar mediante
            depósito o transferencia bancaria a las cuenta que UBBITT designe a
            tales efectos. Para ello deberá remitir los comprobantes de pago a
            UBBITT por medio de su cuenta a los fines de verificar dicho pago.
            En ambos casos, una vez recibido el pago por UBBITT, éste emitirá
            los comprobantes fiscales correspondientes con la información fiscal
            que tenga acreditada en su cuenta. 4.5. Información de pago. Deberá
            mantener actualizada su información de contacto y la información de
            facturación y de tarjeta de crédito (según corresponda). Podrá el
            usuario realizar cambios en su Página de facturación, en su cuenta
            de UBBITT. Las obligaciones de pago no pueden cancelarse y todos los
            montos abonados no son reembolsables, salvo que se establezca alguna
            excepción de manera específica en este Acuerdo o sus anexos. Todas
            las tarifas son de vencimiento y pago por adelantado. 4.6. Impuesto
            a las planes de inversión. En todas las tarifas de los planes de
            inversión se excluyen los impuestos, que se cargarán según
            corresponda. El usuario acepta pagar cualquier impuesto
            correspondiente al plan de inversión adquirido, y/o cualquier otro
            servicio o producto adicional que haya decidido adquirir. 4.7.
            Retenciones. Si el usuario o UBBITT tienen que deducir o retener
            impuestos del pago de la factura, podrán hacerlo de la Tarifa de
            suscripción al Plan de Inversión adquirido en tanto se considere
            adeudada y pagadera como retención impositiva requerida en virtud de
            las leyes fiscales aplicables. No se exigirá que vuelva a pagar el
            importe de deducción, siempre y cuando presente un recibo válido por
            dicho impuesto donde conste el pago de este importe a la autoridad
            impositiva pertinente. El cliente se compromete a cumplir con sus
            obligaciones fiscales, liberando a UBBITT de cualquier
            responsabilidad en este sentido, ya que cada parte será responsable
            del cumplimiento de sus deberes ante la autoridad fiscal. VIGENCIA Y
            TERMINACIÓN 5.1 Plazo de vigencia. El periodo de suscripción a
            UBBITT se ajustará a los Planes de Inversión que decida adquirir el
            cliente, que se especificará en el Formulario de Suscripción al Plan
            de Inversión y, a menos que en este se especifique lo contrario, se
            renovará automáticamente una vez que se realicen los pagos de
            acuerdo al plan de inversión elegido. No obstante a lo anterior, el
            cliente podrá hacer uso de la plataforma sólo fines informativos de
            los servicios adquiridos y de la administración de la cuenta. En
            adición a lo señalado, los servicios adquiridos mediante la
            suscripción del Plan de Inversión sólo podrán ser utilizados y por
            ende estarán vigentes cuando el cliente posea el crédito (inversión)
            suficiente en su cuenta para hacerse acreedor de los servicios. Los
            plazos de los Planes de Inversión estarán sujetos a las
            especificaciones contratadas en el Formulario de suscripción de plan
            de inversión. Sin embargo, estos plazos podrán acortarse cuando los
            objetivos de la inversión planteada se cumplan, habiéndose consumido
            el crédito invertido por el cliente, independientemente de que los
            lapsos contrados para ello sean superiores. 5.2 Cancelación
            anticipada. El usuario puede elegir cancelar su suscripción de forma
            anticipada, en el momento que lo desee, pero no recibirá ninguna
            devolución de tarifas pagadas anteriormente ni Tarifas de Plan de
            Inversión sin utilizar y deberá abonar inmediatamente todas las
            tarifas impagas que adeude hasta finalizar el Plazo, en caso de que
            por motivo excepcional se haya otorgado algún crédito. 5.3
            Terminación por causa justificada. Cualquiera de las partes puede
            poner fin a este Acuerdo o a cualquiera de los Servicios por causa
            justificada de la siguiente manera: (i) con previo aviso de treinta
            (30) días a la otra parte de que se ha cometido una infracción
            importante, siempre que dicha infracción no se haya solucionado al
            finalizar el periodo; o (ii) de manera inmediata, si al cliente se
            le solicita la quiebra o cualquier otro procedimiento relacionado
            con la cesación de pagos, cese de actividades, liquidación o cesión
            de bienes a los acreedores. También se podrá terminar este Acuerdo
            por causa justificada con previo aviso de treinta (30) días si
            determinamos que el usuario está actuando (o ha actuado) de una
            manera que se refleje negativamente sobre UBBITT S.A. o UBBITT o nos
            afecte, o afecte a nuestros prospectos, clientes o proveedores. A
            excepción de estos motivos, el Acuerdo no podrá terminar antes de la
            finalización del Plazo establecido en el Plan de Inversión. 5.5.
            Suspensión. Suspensión por actos prohibidos. Suspenderemos el acceso
            a los Servicios al usuario que: (i) utilice el Servicio de algún
            modo que esté en infracción con las leyes y reglamentos aplicables
            locales, estatales, federales o extranjeros, o que infrinja los
            términos de este Acuerdo; (ii) utilice el servicio de envío de
            correos electrónicos de UBBITT de manera que se produzca un exceso
            de mensajes devueltos, avisos de SPAM o pedidos de baja de los
            destinatarios de una lista de correo; o (iii) intente repetidamente
            publicar o subir material que infringe o se supone que incumple los
            derechos de autor o de marca registrada de cualquier persona o
            entidad. Se podrá revisar y eliminar sin previo aviso la Información
            del cliente o los Materiales del cliente que se determine de buena
            fe que infringen estos términos o la Política de uso aceptable,
            siempre y cuando no tengamos obligación (a menos que las leyes o
            reglamentos exijan lo contrario) de preseleccionar, controlar,
            monitorizar o editar la Información o los Materiales del cliente.
            Suspensión por falta de pago. Enviaremos al usuario un aviso de
            falta de pago por la cantidad que el usuario adeude. A menos que
            pague la cantidad total, se podrá suspender su acceso a uno o todos
            los Servicios de UBBITT, diez (10) días después de dicho aviso. No
            se suspenderá el Servicio en tanto el usuario reclame los cargos
            aplicables de manera razonable y de buena fe y coopere
            diligentemente para resolver el reclamo. Si se suspende el Servicio
            por falta de pago, podría cobrarse una tarifa de reactivación para
            restablecer el Servicio. Suspensión por daño inmediato. Si el
            software o el uso del Servicio: (i) está siendo objeto de ataques de
            denegación de servicio u otra actividad que perturbe el uso; (ii)
            está siendo utilizado para participar en ataques de denegación de
            servicio u otra actividad que perturbe el uso; (iii) está creando
            una vulnerabilidad de la seguridad para el Servicio de suscripción o
            para terceros; (iv) está consumiendo un ancho de banda excesivo; o
            (v) está causando daño a UBBITT, UBBITT S.A. o a terceros; en estos
            casos, previo aviso por vía electrónica o telefónica, podremos
            suspender el acceso al Servicio de UBBITT. Nada de lo establecido en
            esta cláusula limita el derecho de terminación por causa según lo
            antes señalado si determinamos que el usuario está actuando (o ha
            actuado) de manera tal que se refleje negativamente sobre UBBITT,
            UBBITT S.A o nos afecte, o afecte a nuestros prospectos, proveedores
            o clientes. Suspensión y terminación de los Servicios gratuitos. Se
            podrá suspender, limitar o terminar los Servicios gratuitos por
            cualquier motivo y en cualquier momento sin previo aviso. Podemos
            terminar su suscripción a los Servicios gratuitos por falta de
            actividad. 5.6. Consecuencias de la terminación o el vencimiento. Si
            la suscripción de pago termina o vence, seguiremos proporcionando
            los Servicios gratuitos cuando esto aplique. Sin embargo, este puede
            no ser el caso si se le puso fin a su Acuerdo por una causa
            justificada. Continuará sujeto al presente Acuerdo mientras siga
            accediendo a la cuenta de UBBITT. Al momento de terminar o vencerse
            el presente Acuerdo, el usuario dejará de utilizar el Servicio y el
            Contenido UBBITT. Tras la terminación UBBITT no está obligado a
            generar ningún reembolso de cualquier tarifa de inversión pagada por
            adelantado y no utilizada que corresponda al uso del Servicio de
            UBBITT, salvo que se haya acordado algo distinto en los anexos
            respectivos. Si es UBBITT quien termina el presente contrato por
            causa justificada, deberá el usuario abonar inmediatamente todas las
            tarifas impagas que adeude, en caso de que así sea. Esto con
            independencia de las indemnizaciones por daños o perjuicios que
            podrá hacer valer UBBITT. INFORMACIÓN DEL CLIENTE 6.1 Derechos de
            propiedad del Cliente. El usuario es propietario y retiene todos los
            derechos sobre los Materiales y la Información del cliente. Este
            Acuerdo no nos otorga ningún derecho de propiedad sobre los
            Materiales ni la Información del cliente. El usuario autoriza y
            autoriza a nuestros otorgantes de licencias, partners y proveedores
            a usar los Materiales del cliente y la Información del cliente
            solamente cuando sea necesario para brindarle el Servicio de
            suscripción y los Servicios y/o productos contratados mediante los
            Planes de Inversión, de conformidad y en la medida que lo permita
            este Acuerdo. Si el usuario está usando el Servicio de suscripción o
            recibiendo Servicios y/o productos a nombre de otra persona,
            entonces asegura y garantiza que tiene todos los derechos y permisos
            suficientes y necesarios para actuar como tal. 6.2 Limitaciones para
            UBBITT. No utilizaremos ni permitiremos que nadie más haga uso de la
            Información del cliente con el fin de contactarse con cualquier
            persona o empresa, excepto que el usuario indique lo contrario o lo
            permita. Solo utilizaremos la Información del cliente para
            proporcionarle el Servicio de suscripción y los Servicios y/o
            productos contratados según los planes de inversión, y únicamente
            según lo que estipule este Acuerdo y las excepciones de la ley
            vigente. 6.3 Prácticas sobre datos y aprendizaje automático. Se
            monitorizará el uso del Servicio de suscripción por parte de todos
            nuestros clientes y utilizaremos la información que recopilemos de
            manera anónima y global. Se podrá utilizar la Información del
            cliente de forma anónima para el aprendizaje automático. 6.4
            Protección de la Información del cliente. Los términos de las
            Políticas de Privacidad y Seguridad de la Información se incorporan
            en este documento por remisión y se aplicarán en la medida que la
            Información del cliente incluya Datos personales. En el Aviso de
            Privacidad, así como en las Políticas de Privacidad y Seguridad de
            la Información se define cómo procesaremos los Datos personales en
            su nombre, en relación con los Servicios de suscripción que se
            proporcionan según este Acuerdo. Se tomarán todas las medidas
            preventivas administrativas, físicas y técnicas adecuadas desde el
            punto de vista comercial para proteger los Datos personales. 6.5
            Transferencias de datos de los clientes. Nuestros Afiliados y
            nosotros podemos transferir información de nuestros clientes
            (incluidos Datos personales) a otras empresas, incluyendo empresas
            del mismo grupo comercial en otros países. Para ello se respetarán
            que dichas empresas cumplan con las mismas protecciones en materia
            de seguridad de datos personales que son tomadas por nosotros. 6.6
            Retención, eliminación y recuperación de la Información del cliente.
            Si el usuario requiere información sobre nuestros procedimientos
            relacionados con el acceso, la retención, revocación, oposición y
            eliminación de la información de los clientes, consulta la sección
            «Aviso de Privacidad ». PROPIEDAD INTELECTUAL 7.1. De UBBITT: El uso
            y los servicios otorgados en UBBITT están protegidos por las leyes
            de propiedad intelectual, tales como la Ley Federal de Derechos de
            Autor y la Ley Federal de Protección a la Propiedad Industrial de
            los Estados Unidos Mexicanos, así como demás Tratados
            Internacionales vinculantes, por lo que pertenecen y son propiedad
            exclusiva de UBBITT S.A. o bien cuenta con todas las Licencias y
            Autorizaciones para tal uso. En razón a ellos, el usuario acepta que
            no copiará, alquilará, dará en locación, venderá, distribuirá o
            creará, en su totalidad o de manera parcial, y por ningún medio,
            trabajos derivados que se relacionen con el Contenido UBBITT, el
            Servicio de suscripción o los Servicios y/o productos ofertados,
            excepto que tenga nuestra autorización expresa por escrito. Todas
            nuestras marcas, programas, diseños y demás derechos de autor se
            encuentran protegidas y podrán actualizarse en cualquier momento y
            sin previo aviso, no pudiendo el usuario hacer uso de ellas, a menos
            que se le autorice de forma expresa. El usuario deberá respetar
            todos los avisos sobre Derechos de Autor y Derechos de Propiedad
            Industrial y no podrá alterar, ocultar, o destruir ninguno de estos
            avisos. El uso de tal material en cualquier otro sitio web o en
            cualquier ambiente de computadores en red, está prohibido. 7.2. Del
            Usuario: En base a la relación comercial que el usuario acepta en
            los presentes Términos y condiciones, consciente en otorgar una
            licencia de uso específico de sus marcas comerciales, logotipos y
            lemas comerciales, párrafos, fotografías y diseños gráficos, de su
            titularidad y/o de los terceros que tengan la autorización
            respectiva de la licencia y sublicencia de uso, que sean
            absolutamente necesarios a los fines de los servicios contratos en
            esta plataforma. En atención a ello, la licencia de uso de la
            referida propiedad intelectual estará limitada a fines netamente
            comerciales, siendo una licencia no exclusiva, gratuita,
            intransferible, sin facultad para sub – licenciar u autorizar a
            terceras partes, y revocable, de la cual sólo UBBITT podrá hacer uso
            para los fines comerciales convenidos en estos Términos y
            Condiciones exclusivamente para fines publicitarios, informativos y
            de promoción, estando vigente solo durante el tiempo que persista la
            relación con el usuario. Cualquier uso distinto de los derechos de
            propiedad industrial e intelectual antes señalados, dará lugar a la
            revocación del uso concedido de forma inmediata. CONFIDENCIALIDAD
            8.1. La Parte receptora deberá: (i) proteger la privacidad de la
            Información confidencial de la Parte divulgadora empleando los
            mismos cuidados que le brinda a su propia información confidencial,
            pero dentro de lo razonable; (ii) no utilizar la Información
            confidencial de la Parte divulgadora para otro propósito fuera del
            ámbito de este Acuerdo; (iii) no divulgar la Información
            confidencial de la Parte divulgadora a terceros (excepto a los
            terceros proveedores de servicios que utilizamos para proporcionar
            algunos o todos los elementos del Servicio de suscripción o de los
            Servicios y/o productos contratados; y (iv) limitar el acceso a la
            Información confidencial de la Parte divulgadora a aquellos
            empleados, contratistas y representantes que necesitan dicho acceso
            para fines consistentes con este Acuerdo y que han firmado acuerdos
            de confidencialidad con la Parte receptora que contengan
            protecciones no menos estrictas que las del presente documento. 8.2.
            La Parte receptora podrá revelar Información confidencial de la
            Parte divulgadora si es necesario hacerlo en virtud de cualquier ley
            federal, estatal o local, estatuto, norma o reglamento, citación o
            proceso legal; siempre y cuando (i) la Parte receptora proporcione a
            la Parte divulgadora aviso inmediato de cualquier solicitud que
            reciba para divulgar Información confidencial, con la antelación
            suficiente para permitir que la Parte divulgadora se oponga a la
            solicitud u obtenga una orden de protección judicial pertinente o,
            si dicho aviso está prohibido por ley, la Parte receptora deberá
            divulgar la cantidad mínima de Información confidencial que se
            requiera que se divulgue de conformidad con el amparo legal; y (ii)
            en ningún caso, la Parte receptora divulgará Información
            confidencial a una parte que no sea una agencia estatal, excepto en
            virtud de una orden válida de un tribunal judicial competente en la
            que se solicite la divulgación específica. PUBLICIDAD El usuario
            concede el derecho de agregar su nombre y la marca de su empresa a
            UBBITT dentro de la lista de clientes dentro de la plataforma para
            la publicidad que sea necesaria a los fines de la relación comercial
            que se inicia, en los términos señalados en el numeral 7.2 de los
            presentes Términos y Condiciones. 10. INDEMNIZACIÓN El usuario
            deberá indemnizar, defender y librar a UBBITT S.A. y nuestros
            Afiliados, de toda responsabilidad, a su cargo, contra cualquier
            reclamo, juicio, acción o procedimiento de terceros (cada uno, la
            «Acción») iniciado en contra nuestra (y de nuestros funcionarios,
            directores, empleados, representantes, proveedores de servicios,
            otorgantes de licencias, partners y asociados) por un tercero no
            asociado a nosotros y nuestros Afiliados, en la medida en que dicha
            Acción se base en o surja de: (a) el uso no autorizado o ilegal que
            haga el usuario o sus Afiliados del Servicio de suscripción y
            servicios y/o productos contratados; (b) incumplimiento o violación
            de este Acuerdo por parte del usuario o de sus Afiliados; (c) la
            utilización que haga el usuario o sus Afiliados de Productos de
            terceros; o d) el uso no autorizado del Servicio de suscripción por
            parte de cualquier persona que utilice su información de usuario. Le
            notificaremos por escrito dentro de los treinta (30) días siguientes
            al momento de tener conocimiento de dicho reclamo; le daremos el
            control exclusivo de la defensa o del arreglo de tal reclamo; y le
            proporcionaremos (a tus expensas) toda la información y asistencia
            que razonablemente solicite para manejar la defensa o el arreglo del
            reclamo. No deberá aceptar ningún acuerdo que (i) imponga una
            obligación sobre nosotros; (ii) nos obligue a reconocer los hechos;
            o (iii) imponga una responsabilidad no cubierta por estas
            indemnizaciones o nos imponga restricciones sin nuestro
            consentimiento previo por escrito. 11. AVISOS LEGALES; EXENCIÓN DE
            RESPONSABILIDAD 11.1 Exclusión de garantías. Sin pretender limitar
            las obligaciones que nos corresponden en la sección de protección de
            Información del Cliente de este Acuerdo, nosotros y nuestros
            afiliados, agentes y partners no hacemos declaraciones sobre, ni
            garantizamos la conveniencia, fiabilidad, disponibilidad,
            puntualidad, seguridad o exactitud de los servicios, los datos
            disponibles a partir de dicho servicio, el contenido de UBBITT o los
            servicios y/o productos contratados para cualquier propósito. Así
            mismo, las interfaces de programación de aplicaciones (API) podrían
            no estar disponibles en todo momento. En la medida en que la ley lo
            permita, el servicio de suscripción, el contenido de UBBITT y los
            servicios y productos ofrecidos en él, se suministran «tal como
            están» sin ningún tipo de garantía o condición. Excepcionalmente,
            podrán fijarse algunas garantías en relación a la inversión y el
            retorno, las cuales se terminarán en los formularios de suscripción
            del plan de inversión adquirido por el cliente o en cualquier otro
            anexo para esos efectos. 11.2 Exclusión de daños indirectos. En la
            medida que lo permita la ley, bajo ninguna circunstancia se hará
            responsable a alguna de las partes o a sus afiliados por los daños
            indirectos, accidentales, punitorios o consecuentes, o por la
            pérdida de ganancias, ingresos u oportunidades de negocios que
            puedan surgir directa o indirectamente de este Acuerdo, ya sea que
            la acción fuera contractual o por delito civil e independientemente
            de la teoría de responsabilidad. Estas limitaciones no se aplicarán
            si solo el usuario usa los Servicios Gratuitos. 11.3 Limitación de
            responsabilidad. Exceptuando la responsabilidad del usuario por el
            pago de las tarifas, la responsabilidad que surge de las
            obligaciones de la sección «indemnización» y su responsabilidad por
            la violación de los derechos de propiedad intelectual, en caso de
            que, a pesar de los otros términos de este Acuerdo, se determine que
            una de las partes o sus afiliados tiene alguna responsabilidad con
            respecto a la otra, a sus afiliados o a un tercero, las partes
            acuerdan que la responsabilidad total de una parte o sus afiliados
            se limitará a una cantidad igual al monto total pagado o debido por
            el Servicio de suscripción durante el periodo de doce meses anterior
            al hecho que originó el reclamo. Sin embargo, estas limitaciones no
            se aplicarán a al usuario si solo usa los Servicios Gratuitos y, en
            este caso, si determinamos que el usuario o un tercero son
            responsables debido al uso de los Servicios Gratuitos, nuestra
            responsabilidad global se limitará a los cien (100) dólares
            estadounidenses. 11.4 Productos de terceros. Junto con nuestros
            afiliados, proveedores y partners negamos toda responsabilidad
            respecto de los productos de terceros que se utilizan en UBBITT.
            11.5 Aceptación de limitación de responsabilidad. El usuario
            entiende y acepta que sin su consentimiento a esta limitación de la
            responsabilidad, no se podrá brindar el servicio de suscripción. 12.
            OTROS 12.1 Modificación; no exención. UBBITT S.A. DE C.V. se reserva
            el derecho a modificar la totalidad o parte de este Acuerdo y se
            publicará una versión revisada la cual será informada al usuario. La
            versión revisada entrará en vigor y será vinculante a partir del día
            hábil siguiente a su publicación. Le enviaremos un aviso por correo
            electrónico o mediante una notificación en la plataforma. Si no
            acepta alguna modificación a este Acuerdo, deberá enviarnos una
            notificación por escrito dentro de los treinta (30) días posteriores
            al envío del aviso de revisión. Si no envía dicha notificación, la
            suscripción seguirá rigiéndose por los términos y condiciones del
            Acuerdo anteriores a la modificación hasta la próxima fecha de
            renovación, tras lo cual se aplicarán los términos vigentes
            publicados. Sin embargo, si ya no podemos suministrarle la
            suscripción con los términos anteriores a la modificación (por
            ejemplo, si las modificaciones son exigidas por la ley o son el
            resultado de cambios generales en el producto), entonces
            rescindiremos el Acuerdo o los Servicios de suscripción afectados
            previo aviso. Ningún retraso en el ejercicio de algún derecho o
            recurso ni la falta de objeción constituirá una exención a tal
            derecho o recurso o cualquier otro derecho o recurso. Una exención
            ocasional no constituirá una exención a ningún derecho o recurso en
            el futuro. 12.2 Fuerza mayor o caso fortuito. Ninguna de las partes
            será responsable de incumplimiento o demora de cumplimiento si la
            causa es: un acto de guerra, hostilidad o sabotaje; fuerza mayor,
            corte de luz eléctrica, Internet o telecomunicaciones que no sea
            causado por la parte comprometida; restricciones del gobierno; o
            cualquier otro evento fuera del control razonable de la parte
            comprometida. Cada una de las partes hará todos los esfuerzos
            razonables por mitigar el efecto de un evento de fuerza mayor o caso
            fortuito. 12.3 Acciones permitidas. A excepción de las acciones por
            falta de pago o incumplimiento de los derechos de propiedad de una
            parte, ninguna acción, independientemente de su forma, que surja de
            este Acuerdo o en relación con él, podrá ser interpuesta por ninguna
            de las partes a más de un (1) año después de la causa de acción.
            12.4 Relación entre las partes. Las partes acuerdan que no existe
            entre las partes ningún vínculo que implique una empresa conjunta,
            sociedad, empleo o representación. 12.5 Cumplimiento de la ley. El
            suministro del Servicio de suscripción y los Servicios y productos
            ofertados, se hará conforme a las leyes locales y federales de los
            Estados Unidos Mexicanos (cuando corresponda). Así mismo, cumplimos
            con toda la normativa vigente en materia de Protección de Datos, a
            los fines de asegurar el consentimiento de la información que se
            genera en la prestación de servicios contratados. Nos reservamos el
            derecho de revelar en todo momento cualquier información que sea
            necesaria a fin de responder lo solicitado por la ley, el
            reglamento, un proceso legal o una petición gubernamental. Cumplimos
            en todo momento con nuestro Código de Conducta que se encuentra
            publicado en la plataforma a los fines de las relaciones con
            terceros, agentes, proveedores, partners y/o clientes. Así mismo, el
            usuario acepta que conoce y se acoge a la Política Anticorrupción,
            la cual forma parte del presente Acuerdo. El usuario por su parte
            deberá cumplir con todas las leyes en el uso del Servicio de
            suscripción El usuario garantiza que cumplirá con los programas de
            sanciones administrados por la Oficina de Control de Activos
            Extranjeros (OFAC) del Departamento del Tesoro de Estados Unidos en
            su uso y recepción del Servicio de suscripción y Servicios de
            consultoría, como parte de nuestra política Anticorrupción, por lo
            que no podrá exportar, reexportar ni transferir directa ni
            indirectamente el Servicio de suscripción o los Servicios y/o
            productos contratados a países o personas prohibidas ni podrás
            permitir el uso de dichos Servicios a países o personas prohibidas.
            12.6 Divisibilidad. Si se determina que alguna de las partes de este
            Acuerdo o de un Formulario de pedido es inválida o no es susceptible
            de reclamo judicial conforme a la ley aplicable, en ese caso, la
            disposición aludida se considerará reemplazada por la disposición
            válida y ejecutable que mejor refleje el objetivo de la disposición
            original, y el resto de este Acuerdo seguirá en vigencia. 12.7
            Avisos. Para UBBITT S.A: Se enviará una notificación a la dirección
            de contacto establecida en el documento en la parte inicial de este
            Acuerdo, y se considerará entregada a partir de la fecha de su
            recepción. Para el usuario: Su dirección, tal como se suministró en
            la información de la cuenta de Suscripción de UBBITT. Podemos enviar
            notificaciones electrónicas mediante una notificación general a
            través del Servicio de suscripción y también podemos enviarlas
            específicamente por correo electrónico, a las direcciones
            registradas a nombre del usuario en la información de la cuenta o
            mediante el centro de notificaciones del Servicio de suscripción,
            así como también podemos notificarle mediante llamadas telefónicas a
            los números de teléfono que figuran en los registros de información
            de la cuenta. Debe mantener actualizada toda la información de su
            cuenta. 12.8 Totalidad del acuerdo. Este Acuerdo (incluido cada
            Formulario de plan de inversión), junto con el Términos de la
            Licencia de Uso de UBBITT, Código de Ética, Aviso de Privacidad,
            Políticas de Seguridad y Privacidad, Políticas PCI, Políticas
            Anticorrupción y todos los términos relacionados que puedan existir,
            constituyen el acuerdo total entre UBBITT S.A. para el Servicio de
            Suscripción y los Servicios y/o productos ofrecidos, y reemplaza
            cualquier otra propuesta y acuerdos, ya sean electrónicos, verbales
            o escritos que pudieran existir entre las partes. UBBITT S.A. se
            opone y rechaza cualquier término adicional o diferente que
            proponga, incluidos los que figuran en su orden de compra o pedido,
            la aceptación o la plataforma. Nuestras obligaciones no dependen de
            la entrega de ninguna funcionalidad o característica del Servicio de
            suscripción, ni dependen de ningún comentario público oral o escrito
            hecho por nosotros con respecto a la funcionalidad o características
            futuras del Servicio de suscripción. 12.9 Cesión. El usuario no
            podrá ceder o transferir este Acuerdo sin nuestro consentimiento
            previo por escrito, a menos que ceda este Acuerdo a un sucesor en
            caso de fusión, reorganización, venta de todos o sustancialmente
            todos sus activos, cambio de control u aplicación de la ley, y
            siempre y cuando dicho sucesor no pertenezca a nuestra competencia.
            Por otra parte, UBBITT S.A. se reserva el derecho de ceder el
            presente Acuerdo a cualquier afiliado o partner o en el caso de
            fusión, reorganización, venta de todos o sustancialmente todos
            nuestros activos, cambio de control u aplicación de la ley. Así
            mismo, podrá transmitir, ceder, gravar, subcontratar o de cualquier
            otro modo transferir un Contrato o alguno de los derechos u
            obligaciones derivados del mismo, en cualquier momento durante la
            vigencia del presente contrato. Para evitar cualquier duda, dichas
            transmisiones, cesiones, gravámenes u otras transferencias no
            afectarán los derechos que, en su caso, el usuario, , tiene
            reconocidos por ley ni anularán, reducirán o limitarán de cualquier
            otra forma los derechos, tanto expresos como tácitos, que le
            hubiésemos podido otorgar. 12.10 Sin terceros beneficiarios. Nada de
            lo establecido en este Acuerdo, de manera expresa o implícita, tiene
            la intención de conferir, ni le conferirá, a ninguna persona o
            entidad ajena al mismo, ningún derecho, beneficio o recurso de
            ninguna clase, en virtud de este Acuerdo. 12.11 Contrato de
            servicios. Este Acuerdo representa un contrato de provisión de
            servicios mediante la inversión, y no un contrato de venta de
            productos. No se aplicarán a este Acuerdo las disposiciones
            relativas a la compra venta de productos, si no a las referidas a la
            prestación de servicios del Código de Comercio. 12.12 Autoridad.
            Cada parte declara y garantiza a la otra que tiene plenos poderes y
            autoridad para celebrar el presente Acuerdo y que este Acuerdo es
            vinculante para dicha parte y ejecutable de acuerdo con sus
            condiciones. Además, el usuario asegura y declara que tiene la
            capacidad de garantizar el cumplimiento de los términos de este
            Acuerdo por parte de sus Usuarios afiliados. 12.13 Seguros. Durante
            la vigencia de este Acuerdo, a nuestras expensas, recurriremos a
            aseguradores para obtener y mantener en pleno vigor y efecto un
            seguro contra terceros con la cobertura en: (i) Seguro de
            responsabilidad comercial con cobertura en cada incidente; (ii)
            Responsabilidad civil contra daños (seguro de responsabilidad
            cibernética por errores y omisiones), y (iii) Seguro contra todo
            riesgo. 12.14 Vigencia. Las siguientes secciones continuarán
            existiendo tras el vencimiento o la terminación de este Acuerdo:
            «Definiciones», «Tarifas», «Uso prohibido y no autorizado»,
            «Cancelación anticipada», «Terminación por causa justificada»,
            «Suspensión por actos prohibidos”» «Suspensión por falta de pago»,
            «Suspensión por daño inmediato», «Suspensión y terminación de los
            Servicios gratuitos», «Consecuencias de la terminación o el
            vencimiento», «Propiedad intelectual», «Derechos de propiedad del
            cliente», «Confidencialidad», «Publicidad», «Indemnización»,
            «Exenciones de responsabilidad; limitaciones de responsabilidad»,
            «Varios» y «Entidad contratante y ley aplicable». 12.15 Prelación.
            En caso de conflicto entre los términos del Acuerdo y un Pedido,
            regirán los términos de Pedido, pero solo en ese Pedido específico.
            12.16 Ley Aplicable. En caso de controversia, respecto de la
            interpretación, cumplimiento y ejecución de los presentes Términos y
            Condiciones, las partes expresamente se acogen a la jurisdicción de
            los tribunales competentes de la Ciudad de México, renunciando en
            consecuencia a cualquier fuero que, en razón de su domicilio
            presente o futuro pudiera corresponderles. Para todo lo no previsto
            en el presente contrato, las partes están de acuerdo en que se
            apliquen las disposiciones conducentes del Código Civil Federal, así
            como las de la Ley Federal de Derechos de Autor, Ley Federal de
            Protección a la Propiedad Industrial, Ley Federal de Protección de
            Datos Personales en Posesión de los Particulares, y demás
            disposiciones que rijan en los Estados Unidos Mexicanos. 12.17
            Declaración De Acuerdo. El usuario afirma que es plenamente capaz y
            competente para celebrar los términos, condiciones, obligaciones,
            afirmaciones, representaciones y garantías establecidas en estos
            Términos y Condiciones de uso, y para cumplir con ellos. Si se
            determina que alguna disposición de estos Términos de uso es ilegal,
            nula o por cualquier razón inaplicable, entonces dicha disposición
            se considerará separable de estos Términos de uso y no afectará la
            validez y aplicabilidad de las disposiciones restantes.
          </div>
        </div>

        <Footer />
      </main>
    </>
  );
};

export default TerminosCondiciones;
