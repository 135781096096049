import React from "react";
import Accordion from "react-bootstrap/Accordion";

const SociosQA = () => {
  return (
    <div className="socios__qa">
      <div className="text-center">
        <p className="subttl_section">
          <span className="text-center">// </span>
          ¿Tienes dudas?
        </p>
        <h5 className="ttl_section">Preguntas frecuentes</h5>
      </div>
      <Accordion defaultActiveKey={["0"]} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Acerca de mi proceso</Accordion.Header>
          <Accordion.Body>
            <h6>¿Cuánto dura el proceso?</h6>

            <p>Únicamente te tomará una hora de tu día</p>

            <h6>¿Qué documentos necesito para mi registro?</h6>

            <p>
              Ünicamente contar con foto o archivo pdf legible de tu CV, INE/IFE
              y CURP
            </p>

            <h6>
              ¿Quién me avisará después de realizar mi registro si puedo ser
              socio Ubbit?
            </h6>

            <p>
              Al concluir tu registro y pruebas satisfactoriamente, nuestro
              equipo se pondrá en contacto contigo para comunicarte toda la
              información referente a tu ingreso y firma de contrato.
            </p>

            <h6>
              ¿Cómo puedo ponerme en contacto con alguien si tengo problemas en
              el registro?
            </h6>

            <p>
              Da click en el ícono de whatsapp para que alguien de nuestro
              equipo te ayude a continuar con tu registro
            </p>

            <h6>
              ¿Qué pasa si no cumplo con los requerimientos para ser socio
              Ubbit?
            </h6>

            <p>
              Te lo comunicaremos a tú correo electrónico, y de igual forma,
              mantendremos tus datos para posiciones futuras.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Acerca de socios Ubbitt</Accordion.Header>
          <Accordion.Body>
            <h6>¿Cuál es el horario?</h6>

            <p>
              6 horas al día, turno matutino o vespertino de lunes a sábado. Los
              sábados no trabajas si llegas a tu objetivo semanal.
            </p>

            <h6>¿Tengo las prestaciones de ley?</h6>

            <p>Si, desde el primer día.</p>

            <h6>¿El trabajo es presencial?</h6>

            <p>Si, por ahora únicamente tenemos ésta modalidad.</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Socio modo remoto</Accordion.Header>
          <Accordion.Body>
            <h6>Actualmente esta modalidad no se encuentra activa, estamos trabajando para que pronto este habilitada.</h6>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default SociosQA;
