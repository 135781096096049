import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";

// Common
import NavLegal from "../common/header/NavLegal";
import Footer from "../common/footer/Footer";

const CodigoEtica = () => {
  return (
    <>
      <SEO title="Seguridad de datos" />

      <main className="page-wrapper">
        <NavLegal/>
        <div className="container">
          <div className="container_legales col-10">
            <h3 className="main_ttl_legal">
              POLÍTICAS DE SEGURIDAD DE DATOS PARA LA INDUSTRIA DE TARJETA DE
              PAGO (PAYMENT CARD INDUSTRY DATA SECURITY STANDARD: PCI)
            </h3>
            Conocidos y aceptados los Términos que rigen la plataforma de
            UBBITT, damos a conocer nuestra de Seguridad de datos para la
            industria de Tarjeta De Pago (Payment Card Industry Data Security
            Standard: Pci), la cual usted consintió en aceptar al momento de
            utilizar la plataforma. Por medio de la presente política se
            explican los requerimientos de seguridad de la información que se
            aplican a los cobros con tarjetas de crédito, y que deben de cumplir
            todos los empleados de UBBITT S.A. DE C.V. en conjunto con las
            Políticas de Privacidad Seguridad de la Información. UBBITT S.A. DE
            C.V., en lo adelante, UBBITT, se compromete a garantizar el
            cumplimiento de las presentes políticas de seguridad de datos para
            la industria de tarjeta de pago, en lo adelante PCI. Todos los
            empleados están obligados a adherirse a las políticas descritas en
            el presente documento. Es importante hacer del conocimiento del
            usuario que UBBITT S.A. DE C.V., sólo es un intermediario entre el
            usuario tercero y el beneficiario final del cobro que se haga por
            medio del software de UBBITT, por lo que la política aquí descrita
            sólo opera a los fines de la protección, seguridad y
            confidencialidad de la información en conjunto con la política de
            Seguridad de la Información.
            <h2>Contenido </h2>
            {/* OBSERVANCIA LEGAL

ALCANCE

POLÍTICAS Y RESPONSABILIDADES

CLASIFICACIÓN DE LA DATA Y POLÍTICA DE CONTROL

POLÍTICA DE CONFIGURACIÓN DEL SISTEMA

POLÍTICA DE USO DE TECNOLOGÍAS CRÍTICAS

PLAN Y PROCEDIMIENTOS DE RESPUESTA A INCIDENTES

OBSERVANCIA LEGAL */}
            <ul>
              <li>OBSERVANCIA LEGAL</li>
              <li>ALCANCE</li>
              <li>POLÍTICAS Y RESPONSABILIDADES</li>
              <li>CLASIFICACIÓN DE LA DATA Y POLÍTICA DE CONTROL</li>
              <li>POLÍTICA DE CONFIGURACIÓN DEL SISTEMA</li>
              <li>POLÍTICA DE USO DE TECNOLOGÍAS CRÍTICAS</li>
              <li>PLAN Y PROCEDIMIENTOS DE RESPUESTA A INCIDENTES</li>
              <li>OBSERVANCIA LEGAL</li>
            </ul>
            La PCI por sus siglas en inglés, es un programa que busca el
            establecimiento de estándares de seguridad mínimos, creados por las
            grandes compañías que tienen como principal actividad el cobro con
            tarjetas de crédito, para ofrecer a los comerciantes y proveedores
            de servicios, un enfoque completo y unificado que busca salvaguardar
            la información del titular de la tarjeta de crédito. Los estándares
            establecidos por la PCI aplican a todos los que realicen pagos con
            tarjetas, comerciantes y proveedores de servicio que almacenen,
            procesen o transmitan data de los titulares de tarjetas de crédito.
            Los lineamientos aplican para todos los métodos de procesamiento de
            tarjetas de crédito, desde lo manual hasta lo computarizado; de los
            cuales la mayoría aplica a los sitios web de e-commerce, y los
            sitios de venta de producto que procesan tarjetas de crédito y que
            se encuentran en línea.
            <h2>ALCANCE</h2>
            La presente política de PCI aplica para todos los “componentes del
            sistema”, que se definen como cualquier componente de la red,
            servidor, o aplicación que este incluida en o conectada a la fuente
            de información de UBBITT.
            <h2>POLÍTICAS Y RESPONSABILIDADES</h2>
            <h2>APLICACIÓN DE LAS POLÍTICAS</h2>
            Todos los empleados, contratantes, vendedores y terceros que usen,
            mantengan o manejen información de UBBITT deberán seguir está
            política, sin menoscabo de las políticas de seguridad que posean.
            <h2>USUARIOS</h2>
            Cada persona que tenga que hacer uso de los equipos e información de
            UBBITT y de sus recursos debe de conocer y asumir la importancia de
            sus responsabilidades, siempre salvaguardando esos recursos, en
            concordancia con la Política de Seguridad de la Información. El
            personal debe de procurar la información en caso de que se presenten
            disrupciones que amenacen la viabilidad de los sistemas. El personal
            asumirá cuales son las consecuencias que se deriven de sus acciones
            respecto al cumplimiento de las prácticas y políticas de seguridad
            de la información, actuando de acuerdo a lo establecido en cada una
            de ellas. En el caso de recepción de información, clasificarán la
            información que por su carácter deba de ser clasificada y no lo
            esté. Si como limitar la distribución de esta información solo a las
            áreas que deban de conocerla.
            <h2>ROLES ASIGNADOS</h2>
            UBBITT asigna el manejo de la información confidencial a un grupo
            reducido de personas, que por el carácter de su rol dentro de la
            empresa manejan los datos sensibles, estas personas son las únicas
            autorizadas por el carácter del rol que desempeñan. A todo el
            personal se le asignan roles apropiados antes de que puedan ejercer
            permisos y tengan acceso a los recursos de la información. Cada rol
            tiene un acceso específico dependiendo de la información que se
            maneje en el puesto, según las políticas de clasificación de la
            información establecidas en las Políticas de Seguridad de la
            Información. Los roles se basan en el trabajo individual del
            personal, su clasificación y función dentro de UBBITT. La Dirección
            de Seguridad de Tecnología de la información, es responsable de
            asignar roles de acuerdo al nivel de privilegio y proveerá un
            respaldo documental de la autorización de cada rol.
            <h2>CLASIFICACIÓN DE LA DATA Y POLÍTICA DE CONTROL</h2>
            <h2>APLICABILIDAD DE LA POLÍTICA</h2>
            Toda la data almacenada e ingresada en los sistemas de información
            de UBBITT, que sea manejada por los empleados o por un tercero, debe
            de seguir lo establecido en en esta política y en la políticas de
            clasificación de la información establecidas en las Políticas de
            Seguridad de la Información. . Las excepciones a esta política solo
            serán permitidas si han sido aprobadas previamente por el Director
            Ejecutivo de UBBITT y se cuente con un documento en físico.
            <h2>CLASIFICACIÓN DE LA DATA</h2>
            Toda la data almacenada en los recursos y equipos de UBBITT deberá
            tener asignado un nivel de clasificación correspondiente a la
            información, a su dueño y a su creador. Este nivel será usado para
            determinar que empleados tendrán el acceso permitido a la data.
            <h2>CATEGORIAS DE INFORMACIÓN INTERNA DE UBBITT</h2>
            Ampliando la clasificación prevista en la Política de Seguridad de
            la Información, señalamos como definición para las categorías de
            información, las siguientes: Confidencial– Aplica a la información
            sensible de negocios que tiene como único propósito el ser usado
            estrictamente por UBBITT. La revelación de dicha información sin la
            autorización correspondiente puede dañar seriamente y afectar a la
            par a la empresa, socios de negocios y a sus consumidores.
            <br />
            La información confidencial comprende:
            {/* Contraseñas

Llaves De Encriptación

Información Del Titular De La Tarjeta

Información De La Cuenta Bancaria

Propiedad Intelectual */}
            <ul>
              <li>Contraseñas</li>
              <li>Llaves De Encriptación</li>
              <li>Información Del Titular De La Tarjeta</li>
              <li>Información De La Cuenta Bancaria</li>
              <li>Propiedad Intelectual</li>
            </ul>
            Sensible– aplica a la información menos sensible de negocios que es
            estrictamente utilizada para el uso dentro de UBBITT. La revelación
            de dicha información sin la autorización correspondiente puede dañar
            seriamente y afectar a la par a la empresa, socios de negocios y a
            sus consumidores.
            <br />
            La información sensible comprende:
            <ul>
              <li>Estudios Internos De Mercado</li>
              <li>Reportes De Auditoría</li>
            </ul>
            <h2>Privada</h2>– Aplica a toda la información personal que se tenga
            y sea usada por UBBITT. La revelación de dicha información sin la
            autorización correspondiente podrá dañar seriamente a la empresa y a
            sus trabajadores. Ejemplos de información privada: políticas y
            procedimientos, información de recursos humanos, etc.
            <h2>Pública</h2>- Aplica a toda aquella información que no se
            clasifique dentro de las tres clasificaciones mencionadas
            anteriormente. la divulgación de dicha información sin la
            autorización correspondiente no tendrá un impacto perjudicial en la
            empresa. la divulgación de esta información debe de estar autorizada
            por el ceo. PCI data- Se entiende como la clase de tarjetas de
            crédito y data de transacciones identificadas y protegidas bajo el
            estándar de seguridad de datos de la industria de tarjetas de pago
            (PCI DSS). Se definen dos tipos de data: data del titular de la
            tarjeta que puede ser almacenada despues de la autorización de una
            transacción y data sensible de autenticación que no debe de ser
            almacenada después de una transacción autorizada. Data del titular
            de la tarjeta.- aplica a la data tomada de las tarjetas de crédito,
            tomada por el pago de servicios. la data especificada en la version
            del pci dss version 32 comprende:
            {/* Número De Cuenta Primario

Nombre Del Titular De La Tarjeta

Código De Servicio

Fecha De Expiración */}
            <ul>
              <li>Número De Cuenta Primario</li>
              <li>Nombre Del Titular De La Tarjeta</li>
              <li>Código De Servicio</li>
              <li>Fecha De Expiración</li>
            </ul>
            Data sensible de autenticación.- Aplica a toda la data de tarjeta de
            crédito requerida para la autenticación y procesamiento de las
            transacciones hechas con tarjetas de crédito. La data especificada
            en la version del PCI DSS VERSION 32 comprende:
            <ul>
              <li>Full Track Data</li>
              <li>Cav2/Cvc27cvv2/Cid</li>
              <li>Pin/Pin Block</li>
            </ul>
            <h2>ACCESO A LA DATA</h2>
            Toda la data calificada como confidencial o sensible, en especial la
            referente a las PCI debe de ser protegida por medio de controles de
            acceso que aseguren que la misma no sea revelada, modificada,
            eliminada o cambie su disponibilidad. Los controles de acceso deben
            de monitorear el acceso que se tenga a esa data e identificar quien
            y cuando tuvo acceso a la información. Todos los accesos del sistema
            deben de estar configurados para negar el acceso excepto a aquellos
            que no se encuentran autorizados, esto derivado de su rol dentro de
            la empresa. Los accesos al sistema o aplicaciones que manejen
            información confidencial, sensible o privada, deben de seguir los
            procesos de solicitud de acceso a la data. Todas las solicitudes
            requieren la aprobación de la Dirección de Seguridad de Tecnología
            de la información y del formato de solicitud de autorización,
            conforme al procedimiento previsto en las Políticas de Seguridad de
            la Información.
            <h2>POLÍTICA DE CONFIGURACIÓN DEL SISTEMA</h2>
            <h2>APLICABILIDAD DE LA POLÍTICA</h2>
            Todos los servidores y dispositivos de UBBITT, ya sea gestionados
            por empleados o por terceros, deben apagarse de acuerdo con esta
            política. Se permitirán exenciones de esta política sólo si es
            aprobado por adelantado y por escrito por el Director Ejecutivo de
            UBBITT.
            <h2>PROPÓSITO DEL SISTEMA</h2>
            Todos los sistemas informáticos deben designarse para un único
            propósito primario cuando sea posible (por ejemplo, los servidores
            web, los servidores de bases de datos y otros deben implementarse en
            servidores separados). Ningún sistema multiusos puede, bajo ninguna
            circunstancia almacenar, transmitir o procesar datos confidenciales
            o sensibles.
            <h2>
              SOLICITUD DE PROCESAMIENTO DE INFORMACIÓN DE TARJETA DE CRÉDITO
            </h2>
            Todas las solicitudes de UBBITT que se ocupan del procesamiento o
            recuperación de la información del titular de la tarjeta, deben
            configurarse de una manera que sólo pueda mostrar los primeros seis
            o los últimos cuatro dígitos, como el número máximo de dígitos que
            se mostrarán. Si la aplicación está diseñada para un propósito
            específico en el que debe mostrarse la numeración completa, la
            aprobación debe ser dada por el Director de Seguridad de Tecnología
            de la información. En todos los casos que muestran la numeración
            completas o enmascarados debe limitarse al menor número de los
            usuarios posibles y sólo el personal con una necesidad comercial
            legítima puede ver más de los primeros seis y/o últimos cuatro
            dígitos de la numeración.
            <h2>SOLICITUDES DE ALMACENAMIENTO DE TARJETAS DE CRÉDITO</h2>
            Todas las solicitudes de UBBITT que se ocupan del almacenamiento de
            la información del titular de la tarjeta, deben configurarse de una
            manera que no conserve datos prohibidos del titular de la tarjeta,
            como datos completos de seguimiento y códigos de validación de
            tarjetas, además que la tarjeta no debe presentar valores, pines o
            bloques de pasadores. Todo el acceso a los dispositivos de
            almacenamiento en red deben tener su autenticación y comunicación
            cifrada. La numeración de la tarjeta debe hacerse ilegible a través
            de uno de los siguientes componentes: Fuertes funciones hash
            unidireccionales (índices hash) con sales de Truncamiento Fichas y
            pads de índice (los pads deben almacenarse de forma segura)
            Criptografía fuerte con procesos de gestión de claves asociados y
            Procedimientos En el curso normal del negocio, los siguientes
            elementos de datos pueden ser almacenados: El nombre del titular de
            la tarjeta Número de tarjeta y/o cuenta principal Fecha de caducidad
            o Código de seguridad Para minimizar el riesgo, sólo se almacenaran
            solo estos elementos de datos según sea necesario para el negocio.
            <h2>DETECCIÓN DE CAMBIOS</h2>
            Para las soluciones de detección de cambios, se podrá contar con el
            software de supervisión de la integridad de los archivos que se
            instalan en todos los sistemas críticos que contienen datos
            confidenciales. El software de soluciones de detección de cambios
            debe supervisar toda la actividad del usuario en el sistemas. Las
            herramientas de informes y alertas están habilitadas para alertar al
            personal clave cuando se han realizado cambios en los archivos
            críticos (por ejemplo: Ejecutables del sistema, ejecutables de
            aplicaciones, archivos de configuración, contenido de archivos,
            registros y archivos de auditoría). El software está configurado
            para realizar archivos críticos de comparaciones al menos
            semanalmente.
            <h2>POLÍTICA DE USO DE TECNOLOGÍAS CRÍTICAS</h2>
            <h2>APLICABILIDAD DE POLÍTICA</h2>
            Todos los usuario de tecnologías críticas implementadas en redes de
            UBBITT, ya sean empleados o contratistas, deben seguir esta
            política. Las exenciones de esta política sólo se permitirán si el
            Director de Seguridad de Tecnología de la Información lo apruebe por
            adelantado y por escrito. Actualmente, “tecnologías críticas”
            incluyen, pero no se limitan a, acceso remoto y tecnologías
            inalámbricas, portátiles, tabletas, medios electrónicos extraíbles,
            y el uso de Internet dentro de la Empresa. Esta política se
            modificará en el futuro para incluir cualquier nueva “tecnología
            crítica” utilizada.
            <h2>ACCESO A DATOS DE TARJETA DE CRÉDITO.</h2>
            Si hay datos de tarjeta de crédito disponibles a través de
            conexiones de módem de acceso telefónico remoto se deben tomar
            precauciones especiales. Se prohíbe lo siguiente: Se prohíbe el
            almacenamiento de la información de la empresa en discos duros
            locales, disquetes y otros medios. Las funciones de corte, pegado e
            impresión de PC remotos están prohibidas durante la conexión.
            <h2>PLAN Y PROCEDIMIENTOS DE RESPUESTA A INCIDENTES</h2>
            <h2>IDENTIFICACIÓN DE INCIDENTES.</h2>
            Los empleados deben ser conscientes de sus responsabilidades en la
            detección de incidentes de seguridad para facilitar el plan de
            respuesta a incidentes y los procedimientos. Todos los empleados
            tienen la responsabilidad de ayudar en los procedimientos de
            respuesta a incidentes dentro de sus áreas particulares de
            responsabilidad. Algunos ejemplos de incidentes de seguridad que un
            empleado podría reconocer en sus actividades diarias incluyen, pero
            no se limitan a: Robo, daños o acceso (por ejemplo, inicios de
            sesión no autorizados, papeles que faltan en su escritorio,
            cerraduras rotas, archivos de registro que faltan, alerta de guardia
            de seguridad, evidencia de vídeo de un robo o no programado /
            entrada física no autorizada ), fraude e Información inexacta dentro
            de bases de datos, registros, archivos o registros en papel;
            comportamiento anormal del sistema (por ejemplo, reinicio del
            sistema no programado, mensajes inesperados, errores anormales en
            los archivos de registro del sistema o en terminales),
            notificaciones de eventos de seguridad (por ejemplo, alertas de
            integridad de archivos, alarmas de detección de intrusiones, alarmas
            de seguridad física como alarmas contra incendios, alarmas
            ambientales, alertas de desastres naturales), adición de
            dispositivos no autorizados (como puntos de acceso inalámbricos no
            autorizados, memorias USB “gratuitas” desconocidas). Todos los
            empleados, independientemente de las responsabilidades laborales,
            deben ser conscientes de identificar posibles incidentes y a quién
            notificar en estas situaciones. En todos los casos, cada empleado
            debe reportar incidentes de acuerdo con las políticas de seguridad
            de la información.
            <h2>COMPROMISO CON TARJETAS DE CRÉDITO – RESPUESTA ESPECIAL.</h2>
            Para cualquier incidente que implique posibles compromisos de
            información de tarjetas de crédito, la Dirección de Seguridad de
            Tecnología de la Información utilizará el siguiente procedimiento:
            Contener y limitar la exposición. Llevar a cabo una investigación
            exhaustiva de la pérdida sospechosa o confirmada o el robo de la
            información de la cuenta dentro de las 24 horas posteriores. Para
            facilitar la investigación deberá : a. Registrar todas las acciones
            realizadas (por ejemplo, cuaderno enlazado, cámara de vídeo, etc.)
            b. Utilizar técnicas de cadena de custodia durante todas las
            transferencias de equipos e información relacionada con el
            incidente. c. No acceder ni alterar los sistemas comprometidos (por
            ejemplo, no inicie sesión ni cambie las contraseñas; no inicie
            sesión como ROOT). d. No apague la máquina comprometida. En su
            lugar, aísle los sistemas comprometidos de la red (por ejemplo,
            desenchufe el cable de red, desactive el puerto del conmutador,
            aísle en un entorno contenido) e. Utilizar procedimientos de
            recuperación ante desastres / continuidad del negocio para recuperar
            los procesos de negocio . f. Conservar registros y pruebas
            electrónicas . g. Si utiliza una red inalámbrica, cambie el SSID en
            el dispositivo AP y otras máquinas que puedan estar utilizando esta
            conexión (con la excepción de cualquier sistema que se cree que es
            comprometido). h. Estar en alerta máxima y monitorear todos los
            sistemas de información del titular de la tarjeta. Alertar a todas
            las partes necesarias. Asegúrese de notificar: a. Respuesta a
            Incidentes Internos o Externos o Equipo Forense , si aún no están
            involucrados b. Banco comprometido c. Autoridades de investigación
            penal nacionales (si los datos de pago PCI están en peligro) Siga
            los procedimientos apropiados para cada asociación de tarjetas que
            la Empresa utiliza para los servicios de tarjetas de crédito: Visa/
            Master Card/ American Express: Proporcione las cuentas comprometidas
            a estas marcas financieras, y su departamento de Fraude en un plazo
            de diez (10) días hábiles. Los números de cuenta deben enviarse de
            forma segura según las instrucciones del Departamento de Fraude
            correspondiente. Es fundamental que se proporcionen todas las
            cuentas potencialmente comprometidas. El departamento de fraude
            distribuirá los números de cuenta comprometidos a los emisores y
            garantizará la confidencialidad de la información. Consulte la
            documentación de cada marca financiera para las actividades
            adicionales que se deben realizar. Esa documentación se puede
            encontrar en el portal de cada una de las marcas. Póngase en
            contacto con su banco comercial para obtener detalles específicos
            sobre qué hacer después de un compromiso.
          </div>
        </div>

        <Footer />
      </main>
    </>
  );
};

export default CodigoEtica;
