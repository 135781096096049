import React from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import MxFlag from "./MxFlag";

let options = [
  {
    leng: "ESP",
  },
  {
    leng: "ESP",
  },
  {
    leng: "ENG",
  },
];

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row row_links">
            <div className="col-sm-12 col-md-6  col-lg-3 mr-0 ml-0">
              <ul>
                <li>
                  <h4>Acerca de Ubbitt</h4>
                </li>
                <li>
                  <Link to="#">Quiénes somos</Link>
                </li>
                

                <li>
                  <Link to="/inversionistas"> Relación con inversionistas</Link>
                </li>
              </ul>
            </div>
            <div className="col-sm-12 col-md-6  col-lg-3 mr-0">
              <ul>
                <li>
                  <h4>Plataforma</h4>
                </li>

                <li>
                  <a href="https://cliente-mapfre.ubbitt360.com/login/index" target="_blank">Ubbitt 360</a>
                </li>
                <li>
                  <a href="https://soyubbitt.ubbittsyntel.ubbitt.com/login/" target="_blank">Soy Ubbitt</a>
                </li>
                {/* <li>
                  <Link to="/">Ubbitt Store</Link>
                </li> */}
                <li>
                  <Link to="/cibersecurity">Ciberseguridad</Link>
                </li>
              </ul>
            </div>
            <div className="col-sm-12 col-md-6  col-lg-3 mr-0">
              <ul>
                <li>
                  <h4>Legales</h4>
                </li>
                <li>
                  <Link to="/gobierno-corporativo/">Gobierno corporativo</Link>
                </li>
                <li>
                  <Link to="/aviso-de-privacidad">Aviso de privacidad</Link>
                </li>
                <li>
                  <Link to="/terminos-licencia-de-uso-ubbitt/">
                    Términos y licencia de uso
                  </Link>
                </li>
                {/* <li>
                  <Link to="/codigo-de-etica">Código de ética</Link>
                </li> */}
                {/* <li>
                  <Link to="/politica-de-anticorrupcion">Anticorrupción</Link>
                </li> */}
                <li>
                  <Link to="/politicas-de-seguridad-de-datos">
                    Seguridad de datos
                  </Link>
                </li>
                <li>
                  <Link to="/politicas-de-seguridad-y-privacidad">
                    Seguridad y privacidad
                  </Link>
                </li>
                <li>
                  <Link to="/terminos-y-condiciones-del-uso-del-software-de-ubbitt">
                    Términos y condiciones
                  </Link>
                </li>
               
                {/* <a className="mail" href="mailto:info@ubbitt.com">
                  Contáctanos
                </a> */}
              </ul>
            </div>
            <div className="col-sm-12 col-md-6  col-lg-3 mr-0 mr-0">
              <ul>
                <li>
                  <h4>¡Únete a Ubbitt!</h4>
                </li>
                <li>
                  <Link className="btn_tools" to="/empresas">
                  Ubbit en tu empresa
                  </Link>
                </li>
                <li>
                  <Link className="btn_tools" to="/soy-ubbitt">
                  Conviértete en Socio Ubbitt
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div className="container">
          <div className="col-12 social_media">
            <img
              className="logo_footer"
              src={`${process.env.PUBLIC_URL}/assets/images/logo_ubbitt_coral.svg`}
            />
            <div className="contact">
              {/* <a className="mail" href="mailto:webmaster@example.com">info@ubbitt.com</a> */}
              <div className="social_icons">
                <a href="https://www.instagram.com/ubbitt_/" target="_blank">
                  <i className="icon-ig"></i>
                </a>
                <a
                  href="https://www.facebook.com/%C3%9Cbbitt-101226642140217/"
                  target="_blank"
                >
                  <i className="icon-fb"></i>
                </a>
                <a href="https://twitter.com/ubbitt_" target="_blank">
                  <i className="icon-twitter"></i>
                </a>
              </div>
              <span className="separator_span">|</span>
              <MxFlag/>
              <Form.Select aria-label="Default select example" defaultValue="">
                {/* {options.map((item, i) => (
                  <option value={i}>{item.leng}</option>
                ))} */}
                <option value="0">ESP</option>
                <option value="" disabled>ESP</option>
                <option  value="" disabled>ENG</option>
              </Form.Select>
            </div>
          </div>
        </div>
        <hr />
        <div className="copyright">
          <span>Copyright © 2021 Übbitt. All Rights Reserved.</span>
        </div>
        {/* <ScrollTop /> */}
      </footer>
    </>
  );
};

export default Footer;
