import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";

// Common
import NavLegal from "../common/header/NavLegal";
import Footer from "../common/footer/Footer";

const TerminosLicencia = () => {
  return (
    <>
      <SEO title="Términos licencia de uso UBBITT" />

      <main className="page-wrapper">
        <NavLegal />
        <div className="container">
          <div className="container_legales col-10">
            <h3 className="main_ttl_legal">Términos licencia de uso UBBITT</h3>
            Bienvenido a la Plataforma de UBBITT® contenida en el Sitio Web:
            Home XD en lo sucesivo Software, Plataforma o UBBITT, el cual está
            operado por la empresa UBBITT S.A. DE C.V. que se identifica con
            Registro Federal de Contribuyentes clave UBB2102051P2 quien tiene su
            domicilio en Oxford 36 Colonia Juárez Alcaldía Cuauhtémoc, Ciudad de
            México C.P. 06600, en adelante, UBBITT®, quien posee todos los
            derechos correspondientes. Agradecemos que haya elegido “UBBITT®”
            como Programa de Cómputo para su uso comercial, por medio de alguno
            de nuestros socios comerciales y/o agentes autorizados para la
            distribución y comercialización del mismo. En el presente contrato
            de licencia entre usted y UBBITT S.A. DE C.V. se describen sus
            derechos y las condiciones en virtud de las cuales puede utilizar el
            Software. Es esencial leer el contrato completo y los términos
            relacionados que puedan existir, porque todos ellos son importantes
            y, en conjunto, constituyen el contrato que se le aplicara a usted.
            Estos términos de licencia también se aplicarán a las
            actualizaciones, suplementos y servicios relacionados con el
            Software. Al aceptar el presente contrato o al utilizar el Software,
            usted acepta todos estos términos, y brinda su consentimiento para
            la transmisión de determinada información durante la activación y
            durante el uso que usted haga de UBBITT conforme a la Política de
            privacidad. Si no acepta o cumple estos términos, no podrá utilizar
            el software ni sus funciones. En caso de que usted no esté de
            acuerdo en sujetarse a estos términos y condiciones del uso de la
            licencia que tendrá sobre UBBITT, debe suspender inmediatamente su
            uso y abstenerse de acceder a la Plataforma. Los términos y
            condiciones que se establecen en este documento están sujetos a
            cambio sin previo aviso, por lo que el uso continuo de la plataforma
            incluso posterior a la publicación de los cambios realizados a los
            Términos y Condiciones significará que Usted acepta dichos cambios.
            Por favor regrese periódicamente a esta página para consultar
            cualquier cambio.
            <h2>DERECHOS EXCLUSIVOS DEL SITIO Y USO PERMITIDO. </h2>
            UBBITT® le otorga una licencia individual, limitada, intransferible,
            revocable, no sub licenciable y no exclusiva para instalar y
            utilizar el Programa de Cómputo (software o plataforma) denominado
            “UBBITT” de acuerdo a los presentes términos y condiciones. Esta
            Plataforma y su contenido, de manera enunciativa más no limitativa:
            los textos, párrafos, enunciados, combinación específica de
            palabras, letras o elementos fonéticos, gráficos, iconos de botones,
            marcas, logotipos, obras de diseño gráfico, fotografías, avisos
            comerciales, patentes, método comercial, combinación específica de
            colores y formas, material editorial, formatos impresos, formatos de
            búsqueda, descargas digitales, compilaciones de datos, códigos
            fuente, software, etc. así como los productos o servicios ofrecidos,
            son propiedad exclusiva de UBBITT ®, por tanto Usted no puede
            copiar, reproducir, volver a publicar, cargar, modificar, transmitir
            o distribuir dicho contenido sin tener la autorización previa y por
            escrito de UBBITT ®, excepto, en los casos (a) en que Usted
            descargue para ver e imprimir el material que se encuentra contenido
            en la plataforma ó (b) descargue o imprima el material que le sea
            enviado a su correo electrónico por parte de UBBITT®. En ambas
            situaciones Usted única y exclusivamente podrá utilizarlo para su
            uso personal, quedando estrictamente prohibida cualquier
            reproducción total o parcial del Sitio o cualquier uso de su
            contenido destinado al lucro comercial.
            <h2>DURACIÓN Y TERMINACIÓN</h2>
            La presente Licencia y Términos de uso entran en vigor a partir de
            la fecha en que accede por primera vez a la Plataforma y se registra
            para su uso, y permanecerá vigente hasta su finalización de acuerdo
            con estos Términos y Condiciones de uso. UBBITT® puede rescindir de
            inmediato estos Términos de uso y / o su acceso y uso de la
            Plataforma, o cualquier parte de la misma, en cualquier momento y
            por cualquier motivo, con o sin causa, sin previo aviso. UBBITT®,
            también puede rescindir estos Términos de uso de inmediato si usted
            no cumple con algún término o disposición. Tras la terminación de
            estos Términos de uso por cualquiera de las partes, su derecho a
            usar la Plataforma cesará de inmediato, por lo que UBBITT® tendrá la
            facultad de bloquear la licencia de uso otorgada de forma remota,
            inhabilitando su funcionabilidad y manteniendo en resguardo la
            contraseña de acceso en el caso de que posteriormente sea
            reactivada.
            <h2>DERECHOS DE AUTOR. </h2>
            UBBITT S.A. DE C.V. es el titular de los Derechos de Autor, morales
            y patrimoniales, sobre UBBITT®, de conformidad con la Ley Federal
            del Derecho de Autor, por lo que de conformidad con el artículo 105
            de la citada ley, lo autoriza en este acto a realizar una copia de
            dicho programa sólo y exclusivamente a los fines de que sean
            destinadas como resguardo para sustituir la copia legítimamente
            adquirida, cuando ésta no pueda utilizarse por daño o pérdida. La
            copia de respaldo deberá ser destruida cuando cese su derecho para
            utilizar UBBITT®. Así mismo, de conformidad con el artículo 106 de
            la ley de marras, Usted se obliga a abstenerse de: (a) reproducir,
            modificar, adaptar o crear trabajos derivados de cualquier parte de
            UBBITT®.; (b) alquilar, arrendar, distribuir, vender, sublicenciar,
            transferir o proporcionar acceso a UBBITT® a un tercero; (c)
            utilizar UBBITT® en beneficio de terceros; (d) incorporar UBBITT® a
            un producto o servicio que proporcione a un tercero no autorizado;
            (e) interferir con cualquier mecanismo de clave de UBBITT® o eludir
            los mecanismos destinados a limitar su uso; (f) realizar ingeniería
            inversa, desensamblar, descompilar, traducir o intentar obtener o
            derivar el código fuente, las ideas subyacentes, los algoritmos, los
            formatos de archivo o las API no públicas de UBBITT®, excepto en la
            medida expresamente permitida por la ley aplicable en los casos
            legalmente previstos (y entonces solo con previo aviso a UBBITT S.A.
            DE C.V.; (g) eliminar u ocultar cualquier aviso de propiedad u otro
            contenido en UBBITT®; (h) utilizar UBBITT® para análisis competitivo
            o para crear productos competitivos; (i) alentar o ayudar a un
            tercero a hacer cualquiera de los anteriores. GARANTÍAS. SOPORTE
            TÉCNICO Y MANTENIMIENTO Garantías del uso: En el uso de UBBITT no
            hay garantía certera de que su uso sea ininterrumpido, completo,
            presente fallas en el sistema, esté libre de errores, o que
            cualquier problema sea corregido, o que la plataforma o cualquier
            información, software u otro material accesible desde el mismo, está
            libre de virus u otros componentes dañinos. UBBITT® no garantiza
            ninguna representación con respecto al uso o los resultados del uso
            de la plataforma, salvo las acordadas en los términos y condiciones
            de los servicios que se contraten, ya sea en términos de su
            compatibilidad con hardware u otro software o equipo, y Usted asume
            toda responsabilidad y riesgo por su uso del sitio y la información
            y su confianza al respecto, por ello Usted no podrá imputar
            responsabilidad alguna ni exigir pago por lucro cesante o cualquier
            daño en general, en virtud de perjuicios resultantes de dificultades
            técnicas o fallas en los sistemas o en Internet.
            <h2>PROPIEDAD INTELECTUAL</h2>
            Queda entendido por Usted que toda la propiedad intelectual,
            relacionada a topografías de semiconductores, distribución de
            circuitos, diseños, secretos comerciales y otros derechos de
            propiedad intelectual en, o relacionados UBBITT®, tales como marcas,
            know- how, imágenes, plantillas, textos, entre otros de similar
            naturaleza, son y quedarán en propiedad exclusiva del propietario, y
            que se encuentran protegidos por las disposiciones de la Ley Federal
            del Derecho de Autor y la Ley Federal de Protección a la Propiedad
            Industrial de la legislación mexicana, y las de los tratados
            internacionales aplicables y, por esta razón, no podrá ser utilizado
            a no ser que ello se haga para propósitos de respaldo o para
            sustituir copias defectuosas. En las excepciones antes señaladas,
            Usted deberá anotar explícitamente que se trata de una copia
            respaldo de UBBITT®, quedando estrictamente prohibido reproducir,
            fotocopiar o de cualquier otra forma duplicar el contenido del
            manual y/o instructivo de uso que acompaña a UBBITT®. Usted no podrá
            tomar acción alguna que perjudique los derechos de propiedad aquí
            expresados ni adquirirá ningún derecho en UBBITT®, salvo los
            derechos limitados de uso especificados en este contrato. UBBITT
            S.A. DE C.V. será dueño de todos los derechos en cualquier copia,
            traducción, modificación, adaptación o derivación de UBBITT®,
            incluyendo cualquier mejora o cambio del mismo.
            <h2>PRODUCTOS DE TERCEROS.</h2>
            UBBITT® podrá optar por utilizar o adquirir otros productos o
            servicios de terceros, incluidas Aplicaciones de terceros o la
            implementación, personalización, capacitación u otros servicios a
            los fines de cumplir con el buen desarrollo de la plataforma y de
            sus servicios. Por ello, Usted reconoce y acepta que estos terceros
            pueden acceder o utilizar sus datos según sea necesario para la
            interoperación y mejora de productos y servicios ofrecidos o
            contratados. Esto puede incluir transmitir, transferir, modificar o
            eliminar sus datos, o almacenar sus datos en sistemas pertenecientes
            a terceros proveedores u otros terceros. El uso de sus datos por
            parte de cualquier proveedor externo está sujeto al acuerdo
            aplicable entre UBBITT® y dicho proveedor externo, en donde se
            verificarán las políticas de seguridad de la información, así como
            las prácticas de seguridad o privacidad de cualquier proveedor
            externo o sus productos o servicios.
            <h2>DEFENSAS E INDEMNIZACIONES.</h2>
            UBBITT® no tendrá ninguna responsabilidad por reclamos basados en la
            combinación, operación, uso o utilización de la Plataforma en virtud
            del presente contrato con productos, datos o programas no
            suministrados por UBBITT® o de proveedores externos, o que tengan
            origen en la alteración o modificación del software no efectuadas
            por UBBITT® o el uso de éste en un ambiente distinto al ambiente de
            operación especificado en estos términos. Usted deberá defender y
            mantener indemne a UBBITT S.A. DE C.V. de y contra cualquier
            pérdida, costo, responsabilidad o daño (incluidos los honorarios de
            abogados) que surjan de o estén relacionados con cualquier reclamo
            presentado contra la empresa (a) que surja o esté relacionado con su
            incumplimiento en los términos señalados en este contrato o
            cualquier reclamación o disputa presentada por terceros no
            autorizados por UBBITT® que surja de su uso de UBBITT®; (b) por un
            tercero relacionado con cualquier contenido o datos que no sean de
            UBBITT® utilizados por Usted o sus dependientes en relación con
            UBBITT®
            <h2>VIRUS, PIRATERÍA Y OTROS ATAQUES INFORMÁTICOS</h2>
            Usted no debe realizar un uso indebido de esta plataforma mediante
            la introducción intencionada en la misma de virus, troyanos,
            gusanos, bombas lógicas o cualquier otro programa o material
            tecnológicamente perjudicial o dañino. Usted no tratará de tener
            acceso no autorizado a UBBITT®, al servidor en que dicha página se
            encuentra alojada o a cualquier servidor, ordenador o base de datos
            relacionada con el Software. Usted se compromete a no atacar esta
            plataforma a través de un ataque de denegación de servicio o de un
            ataque de denegación de servicio distribuido. El incumplimiento de
            esta cláusula podría llevar aparejada la comisión de infracciones
            tipificadas por la normativa aplicable. Informaremos de cualquier
            incumplimiento de dicha normativa a las autoridades competentes y
            cooperaremos con ellas para descubrir la identidad del atacante.
            Asimismo, en caso de incumplimiento de la presente cláusula, dejará
            inmediatamente de estar autorizado a usar esta plataforma No seremos
            responsables de cualquier daño o pérdida resultante de un ataque de
            denegación de servicio, virus o cualquier otro programa o material
            tecnológicamente perjudicial o dañino que pueda afectar a su
            ordenador, equipo informático, datos o materiales como consecuencia
            del uso de esta página web o de la descarga de contenidos de la
            misma o a los que la misma redireccione.
            <h2>COMUNICACIONES </h2>
            Mediante el uso de esta plataforma, Usted acepta que todas las
            comunicaciones con nosotros sean electrónicas. Nos pondremos en
            contacto con Usted por medio de su cuenta en UBBITT® o en su correo
            electrónico o le facilitaremos información colgando avisos en esta
            plataforma. A efectos contractuales, Usted consiente en usar este
            medio electrónico de comunicación y reconoce que todo contrato,
            notificación, información y demás comunicaciones que le enviemos de
            forma electrónica cumplen con los requisitos legales de ser por
            escrito. Esta condición no afectará a sus derechos reconocidos por
            ley.
            <h2>NOTIFICACIONES</h2>
            Le podremos enviar notificaciones bien a su cuenta en la plataforma
            o al correo electrónico. Se entenderá que las notificaciones han
            sido recibidas y han sido correctamente hechas en el mismo instante
            en que se cuelguen en su cuenta dentro de UBBITT®, ó 24 horas
            después de haberse enviado un correo electrónico. Para probar que la
            notificación ha sido hecha, será suficiente con probar, que fue
            debidamente entregada en la cuenta en la plataforma o en el buzón
            electrónico de la dirección de correo electrónico especificada.
            <h2>CESIÓN DE DERECHOS Y OBLIGACIONES</h2>
            Los presentes términos de uso de la licencia de UBBITT® es
            vinculante tanto para usted como para UBBITT S.A. DE C.V. así como
            para nuestros respectivos sucesores, cesionarios y causahabientes,
            Usted no podrá transmitir, ceder, gravar o de cualquier otro modo
            transferir el presente contrato o sus términos o alguno de los
            derechos u obligaciones derivados del mismo, sin haber obtenido
            nuestro consentimiento previo por escrito. Por su parte, UBBITT S.A.
            DE C.V. podrá transmitir, ceder, gravar, subcontratar o de cualquier
            otro modo transferir todos o alguno de los derechos u obligaciones
            derivados del mismo, en cualquier momento durante la vigencia de los
            presentes términos. Para evitar cualquier duda, dichas
            transmisiones, cesiones, gravámenes u otras transferencias no
            afectarán los derechos que, en su caso, usted, como consumidor,
            tiene reconocidos por ley ni anularán, reducirán o limitarán de
            cualquier otra forma las garantías, tanto expresas como tácitas, que
            le hubiésemos podido otorgar.
            <h2>ACONTECIMIENTOS FUERA DE NUESTRO CONTROL</h2>
            No seremos responsables por ningún incumplimiento o retraso en el
            cumplimiento de alguna de las obligaciones señaladas en estos
            términos en relación con el uso y disponibilidad de UBBITT® cuya
            causa se deba a acontecimientos que están fuera de nuestro control
            razonable (“Caso Fortuito o Causa de Fuerza Mayor”).
            <h2>RENUNCIA</h2>
            La falta de requerimiento por nuestra parte del cumplimiento
            estricto por su parte de alguna de las obligaciones asumidas por
            usted en virtud de los presentes términos o la falta de ejercicio
            por nuestra parte de los derechos o acciones que nos pudiesen
            corresponder en virtud de dicho Contrato o de las Condiciones, no
            supondrá renuncia ni limitación alguna en relación con dichos
            derechos o acciones ni le exonerará a Usted de cumplir con tales
            obligaciones. Ninguna renuncia por nuestra parte a un derecho o
            acción concreto supondrá una renuncia a otros derechos o acciones
            derivados de estos términos. Ninguna renuncia por nuestra parte a
            alguna de las presentes Condiciones o a los derechos o acciones
            derivados del presente documento surtirá efecto, a no ser que se
            establezca expresamente que es una renuncia y se formalice y se le
            comunique a Usted de conformidad con lo dispuesto en el apartado de
            Notificaciones anterior.
            <h2>DATOS PERSONALES </h2>
            La información o datos personales que nos facilite sobre Usted o que
            se generen por los servicios que se contraten por medio de esta
            plataforma serán tratados con arreglo a lo establecido en nuestro
            Aviso de Privacidad y Políticas de Seguridad y Privacidad. Al hacer
            uso de este Sitio usted consiente el tratamiento de dicha
            información y datos y declara que toda la información o datos que
            nos facilite son veraces y se corresponden con la realidad.
            <h2>ACUERDO </h2>
            Los presentes términos de uso de la licencia de UBBITT® y todo
            documento a que se haga referencia expresa en las mismas constituyen
            el acuerdo íntegro existente entre usted y UBBITT S.A. de C.V. en
            relación con el objeto de las mismas y sustituyen a cualquier otro
            pacto, acuerdo o promesa anterior convenida entre usted y UBBITT
            S.A. de C.V. verbalmente o por escrito.
            <h2>LEGISLACIÓN Y JURISDICCIÓN APLICABLE.</h2>
            Para todo lo no previsto en los presentes términos, las partes están
            de acuerdo en que se apliquen las disposiciones conducentes que
            resulten aplicables de la legislación mexicana, así como las de la
            Ley Federal de Derechos de Autor, Ley Federal de Protección a la
            Propiedad Industrial, Ley Federal de Protección de Datos Personales
            en Posesión de los Particulares, y demás disposiciones que rijan en
            los Estados Unidos Mexicanos. Así mismo se someten expresamente a la
            jurisdicción de los Juzgados y Tribunales de la Ciudad de México que
            conforme a derecho deban conocer el asunto sobre el que es firmado
            el presente acuerdo, con renuncia a su propio fuero en caso de que
            este les aplique y sea procedente. Última actualización: Agosto 2021
          </div>
        </div>

        <Footer />
      </main>
    </>
  );
};

export default TerminosLicencia;
