import React from "react";
import { Link } from "react-router-dom";

const Planes = () => {
  return (
    <div className="plans__container">
      <h5 className="ttl_section text-center">Planes y precios</h5>

      <div className="row row_plans">
        <div className="col-sm-12 col-lg-4">
          <div className="card__plan-option">
            <small>Freemium</small>
            <p className="plan__name">CPA 15%</p>

            <hr />
            <div className="list_details">
              <p className="details">
                <span>Objective:</span> <br /> Eliminate fix sales costs, <br />
                increase sales convertion & cashflow.
              </p>
              <p className="details">
                <span>Design for:</span>
                <br />
                Organic and existing Flow of sales.
              </p>
              <p className="details">
                <span>Features:</span>
                <br /> Ubbitt Messenger
                <br /> On Demand Sales Contact
                <br /> Center
                <br /> Mkt Automation
                <br /> Ciber Security
                <br /> Landing Pages
                <br /> Digital Transformation of Sales Process
                <br /> E-learning
                <br /> Cloud Comunication Services
              </p>

              <p className="details">
                <span>Platforms:</span>
                <br />
                Ubbitt Messenger
                <br /> Ubbitt360
                <br /> Ubbitt Job Market Place
                <br /> Ubbitt App
              </p>
              <p className="details">
                <span>KPIS that Matter:</span>
                <br /> Conversion,
                <br /> Service Level,
                <br />
                CPA
              </p>
            </div>
           
            <Link to="/planes/freemium">Más info</Link>
          </div>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div className="card__plan-option">
            
            <small>Premium</small>
            <p className="plan__name"><span className="start">Starting at</span><br/>$20k usd/Monthly <br/><span className="last">*3 Months Investment</span></p>

            <hr />
            <div className="list_details">
              <p className="details">
                <span>Objective:</span> <br /> Eliminate fix sales costs, <br />
                increase sales convertion & cashflow.
              </p>
              <p className="details">
                <span>Design for:</span>
                <br />
                Organic and existing Flow of sales.
              </p>
              <p className="details">
                <span>Features:</span>
                <br /> Ubbitt Messenger
                <br /> On Demand Sales Contact
                <br /> Center
                <br /> Mkt Automation
                <br /> Ciber Security
                <br /> Landing Pages
                <br /> Digital Transformation of Sales Process
                <br /> E-learning
                <br /> Cloud Comunication Services
              </p>

              <p className="details">
                <span>Platforms:</span>
                <br />
                Ubbitt Messenger
                <br /> Ubbitt360
                <br /> Ubbitt Job Market Place
                <br /> Ubbitt App
              </p>
              <p className="details">
                <span>KPIS that Matter:</span>
                <br /> Conversion,
                <br /> Service Level,
                <br />
                CPA
              </p>
            </div>
            <Link to="/planes/premium">Más info</Link>
          </div>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div className="card__plan-option">
            <small>Beyond</small>
            <p className="plan__name">CPA 15%</p>

            <hr />
            <div className="list_details">
              <p className="details">
                <span>Objective:</span> <br /> Eliminate fix sales costs, <br />
                increase sales convertion & cashflow.
              </p>
              <p className="details">
                <span>Design for:</span>
                <br />
                Organic and existing Flow of sales.
              </p>
              <p className="details">
                <span>Features:</span>
                <br /> Ubbitt Messenger
                <br /> On Demand Sales Contact
                <br /> Center
                <br /> Mkt Automation
                <br /> Ciber Security
                <br /> Landing Pages
                <br /> Digital Transformation of Sales Process
                <br /> E-learning
                <br /> Cloud Comunication Services
              </p>

              <p className="details">
                <span>Platforms:</span>
                <br />
                Ubbitt Messenger
                <br /> Ubbitt360
                <br /> Ubbitt Job Market Place
                <br /> Ubbitt App
              </p>
              <p className="details">
                <span>KPIS that Matter:</span>
                <br /> Conversion,
                <br /> Service Level,
                <br />
                CPA
              </p>
            </div>
            <Link to="/planes/beyond">Más info</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Planes;
