import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default class SociosTestimonial extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 2,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
        };
        return (

            <div className="socios__testimonial">
                <p className="subttl_section">
                    <span>// </span>
                    Testimonios
                </p>
                <h5 className="ttl_section">
                Qué opinan los socios expertos Ubbitt
                </h5>
                <Slider className="slider__testimonial" {...settings}>
                    <div className="profile__testimonial">
                        <div className="card__testimonial">
                            <div className="d-flex">
                                <img src="https://ubbitt.com/soy.ubbitt.com/wp-content/uploads/2021/05/juan.jpg" alt="" />
                                <h5>Juan</h5>
                            </div>
                            <p>'Yo decido cuánto ganar y con horario súper flexible'</p>
                        </div>
                    </div>
                    <div className="profile__testimonial">
                        <div className="card__testimonial">
                            <div className="d-flex">
                                <img src="https://ubbitt.com/soy.ubbitt.com/wp-content/uploads/2021/05/denisse.jpg" alt="" />
                                <h5>Denisse</h5>
                            </div>
                            <p>'Principalmente el ambiente y la oportunidad de ganar lo que nosotros queremos'</p>
                        </div>
                    </div>
                    <div className="profile__testimonial">
                        <div className="card__testimonial">
                            <div className="d-flex">
                                <img src="https://ubbitt.com/soy.ubbitt.com/wp-content/uploads/2021/05/charlie.jpg" alt="" />
                                <h5>Charlie</h5>
                            </div>
                            <p>
					        	'Aprendo de todas las personas que me rodean, me gusta la interacción clientes y me gusta ganar dinero'</p>
                        </div>
                    </div>
                    <div className="profile__testimonial">
                        <div className="card__testimonial">
                            <div className="d-flex">
                                <img src="https://ubbitt.com/soy.ubbitt.com/wp-content/uploads/2021/05/luz.jpg" alt="" />
                                <h5>Luz Gabriela</h5>
                            </div>
                            <p>
					        	'El ambiente es muy agradable, el horario me permite tener dos empleos y alcanzar mis metas'					        </p>
                        </div>
                    </div>
                </Slider>
            </div>
        );
    }
}

//  export default SociosTestimonial;