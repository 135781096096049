import React from "react";

const SociosBeneficios = () => {
    return (
        <div className="socios__beneficios">
            <p className="subttl_section">
                <span>// </span>
                Beneficios
            </p>
            <h5 className="ttl_section">
                El mejor lugar para trabajar
            </h5>
            <p className="info">Inviertes en Übbitt y gestionamos tu estrategia de marketing digital para lograr el ROI esperado. Puedes realizar los pagos en la página de forma fácil y segura.</p>
            <div className="row col-11">
                <div className="col-sm-12  col-md-4">
                    <div className="card__beneficios">
                        <h4>
                            <span>
                                <i className="icon-comisiones"></i>
                            </span>
                            Comisiones
                        </h4>
                        <hr />
                        <p>Todos nuestros socios están generando entre 10 y 25 mil pesos al mes en comisiones.</p>
                    </div>
                </div>
                <div className="col-sm-12  col-md-4">
                    <div className="card__beneficios">
                        <h4>
                            <span>
                                <i className="icon-horarrios"></i>
                            </span>
                            Horarios
                        </h4>
                        <hr />
                        <p>Trabaja sólo 6 horas al día. Turno matutino o vespertino a tu eleccion.</p>
                    </div>
                </div>
                <div className="col-sm-12  col-md-4">
                    <div className="card__beneficios">
                        <h4>
                            <span>
                                <i className="icon-estabilidad"></i>
                            </span>
                            Estabilidad
                        </h4>
                        <hr />
                        <p>Trabaja en un lugar estable y con el mejor ambiente labioral.</p>
                    </div>
                </div>
                <div className="col-sm-12  col-md-4">
                    <div className="card__beneficios">
                        <h4>
                            <span>
                                <i className="icon-premios"></i>
                            </span>
                            Fines de semana
                        </h4>
                        <hr />
                        <p>Logra tus metas semanales y no trabajes los sábados.</p>
                    </div>
                </div>
                <div className="col-sm-12  col-md-4">
                    <div className="card__beneficios">
                        <h4>
                            <span>
                                <i className="icon-contratacion"></i>
                            </span>
                            Contratación directa
                        </h4>
                        <hr />
                        <p>Contrataciones directas con sueldo base quincenal.</p>
                    </div>
                </div>
                <div className="col-sm-12  col-md-4">
                    <div className="card__beneficios">
                        <h4>
                            <span>
                                <i className="icon-prestaciones"></i>
                            </span>
                            Prestaciones de ley
                        </h4>
                        <hr />
                        <p>Todas las prestaciones de ley desde el momento en el que ingresas.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SociosBeneficios;