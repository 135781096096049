import React from "react";

const SociosPostulacion = () => {
  return (
    <div className="socios__beneficios">
      <p className="subttl_section">
        <span className="text-center">// </span>
        Registro
      </p>
      <h5 className="ttl_section">Proceso para ser socio Ubbitt</h5>

      <div className="row col-11">
        <div className="col-sm-12  col-md-4">
          <div className="card__beneficios">
            <h4>
              <span className="step_number">01</span>
              Registro
            </h4>
            <hr />
            <p>Regístrate con tus datos para ponernos en contacto contigo.</p>
          </div>
        </div>
        <div className="col-sm-12  col-md-4">
          <div className="card__beneficios">
            <h4>
              <span className="step_number">02</span>
              Documentación
            </h4>
            <hr />
            <p>Envía la documentación solicitada.</p>
          </div>
        </div>
        <div className="col-sm-12  col-md-4">
          <div className="card__beneficios">
            <h4>
              <span className="step_number">03</span>
              Pruebas
            </h4>
            <hr />
            <p>Realiza las pruebas psicométricas. No te tomará más de 30 min.</p>
          </div>
        </div>
        <div className="col-sm-12  col-md-4">
          <div className="card__beneficios">
            <h4>
              <span className="step_number">04</span>
              Validación
            </h4>
            <hr />
            <p>Nuestro equipo validará tus pruebas y la documentación enviada.</p>
          </div>
        </div>
        <div className="col-sm-12  col-md-4">
          <div className="card__beneficios">
            <h4>
              <span className="step_number">05</span>
              Ingreso
            </h4>
            <hr />
            <p>Nos pondremos en contacto contigo si los resultados son satisfactorios.</p>
          </div>
        </div>
        <div className="col-sm-12  col-md-4">
          <div className="card__beneficios">
            <h4>
              <span className="step_number">
                <i className="icon-partner"></i>
              </span>
              Bienvenido
            </h4>
            <hr />
            <p>Formarás parte del mejor equipo para trabajar.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SociosPostulacion;
