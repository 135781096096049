import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const SociosSeguridad = () => {
  const settings_brands = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="socios__seguridad">
      <p className="subttl_section">
        <span>// </span>
        Sanitización y seguridad
      </p>
      <h5 className="ttl_section">Plan de crecimiento Ubbitt</h5>
      <p className="info">
        Empiezas en el primer nivel, como un Pingüino, pero cada mes tendrás la
        oportunidad de subir al nivel que quieras, de acuerdo a tus metas
        alcanzadas.
      </p>

      <div className="row">
        <div className="col-6 col-md-4">
          <div className="socios_level_card">
            <div className="header_level one">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/view_socios/pinguino.svg`}
                alt=""
              />
              <span>Pinguino</span>
            </div>
            <div className="info_level ">
              <h6>KPI´s</h6>
              <p>
                Monthly sales: <span>$200,000.00</span>
              </p>
              <p>
                Conversion: <span>5% </span>
              </p>
              <p>
                Collection: <span>70%</span>
              </p>
              <p>
                {" "}
                Quality: <span>80%</span>
              </p>
              <h6>Bennefits </h6>
              <p>
                Commission: <span>3%</span>
              </p>
              <p>
                Payroll: <span>$4,530.30</span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-4">
          <div className="socios_level_card">
            <div className="header_level two">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/view_socios/espada.svg`}
                alt=""
              />
              <span>Pez espada</span>
            </div>
            <div className="info_level">
              <h6>KPI´s</h6>
              <p>
                Monthly sales: <span>$250,000.00</span>
              </p>
              <p>
                Conversion: <span>6%</span>
              </p>
              <p>
                Collection: <span>75%</span>
              </p>
              <p>
                {" "}
                Quality: <span>85%</span>
              </p>
              <h6>Bennefits </h6>
              <p>
                Commission: <span>3.5%</span>
              </p>
              <p>
                Payroll: <span>$4,697.83</span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-4">
          <div className="socios_level_card">
            <div className="header_level three">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/view_socios/delfin.svg`}
                alt=""
              />
              <span>Delfín</span>
            </div>
            <div className="info_level">
              <h6>KPI´s</h6>
              <p>
                Monthly sales: <span>$300,000.00</span>
              </p>
              <p>
                Conversion: <span>7% </span>
              </p>
              <p>
                Collection: <span>75% </span>
              </p>
              <p>
                {" "}
                Quality: <span>85%</span>
              </p>
              <h6>Bennefits </h6>
              <p>
                Commission: <span>4% </span>
              </p>
              <p>
                Payroll: <span>$4,865.37</span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-4">
          <div className="socios_level_card">
            <div className="header_level four">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/view_socios/pulpo.svg`}
                alt=""
              />
              <span>Pulpo</span>
            </div>
            <div className="info_level">
              <h6>KPI´s</h6>
              <p>
                Monthly sales: <span>$350,000.00</span>
              </p>
              <p>
                Conversion: <span>8%</span>
              </p>
              <p>
                Collection: <span>80%</span>
              </p>
              <p>
                {" "}
                Quality: <span>90%</span>
              </p>
              <h6>Bennefits </h6>
              <p>
                Commission: <span>4.5%</span>
              </p>
              <p>
                Payroll: <span>$5,032.90</span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-4">
          <div className="socios_level_card">
            <div className="header_level five">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/view_socios/ballena.svg`}
                alt=""
              />
              <span>Ballena</span>
            </div>
            <div className="info_level">
              <h6>KPI´s</h6>
              <p>
                Monthly sales: <span>$400,000.00</span>
              </p>
              <p>
                Conversion: <span>9%</span>
              </p>
              <p>
                Collection: <span>5%</span>
              </p>
              <p>
                Quality: <span>90%</span>
              </p>
              <h6>Bennefits </h6>
              <p>
                Commission: <span>5%</span>
              </p>
              <p>
                Payroll: <span>$5,200.43</span>
              </p>
            </div>
            <div className="mini_badge">
              <span>1 Sábados libres</span>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-4">
          <div className="socios_level_card">
            <div className="header_level six">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/view_socios/tiburon.svg`}
                alt=""
              />
              <span>Tiburón</span>
            </div>
            <div className="info_level">
              <h6>KPI´s</h6>
              <p>
                Monthly sales: <span>$450,000.00 </span>
              </p>
              <p>
                Conversion: <span>10% </span>
              </p>
              <p>
                Collection: <span>85%</span>
              </p>
              <p>
                {" "}
                Quality: <span>95%</span>
              </p>
              <h6>Bennefits </h6>
              <p>
                Commission: <span>5%</span>
              </p>
              <p>
                Payroll: <span>$5,702.43</span>
              </p>
            </div>
            <div className="mini_badge">
              <span>2 Sábados libres</span>
            </div>
          </div>
        </div>
      </div>
      <p className="extra_info">
        Con Ubbitt genera ingresos todos los días de forma segura y con las
        mejores y más reconocidas empresas. Además tienes la estabilidad
        económica que quieres y la flexibilidad en horarios que siempre has
        buscado.
      </p>

      <Slider className="brand_slider" {...settings_brands}>
        <div className="brand__img">
          <img
            className="w-100"
            src={`${process.env.PUBLIC_URL}/assets/images/logos_empresas/a/vendor_mapfre.svg`}
            alt=""
          />
        </div>
        <div className="brand__img">
          <img
            className="w-100"
            src={`${process.env.PUBLIC_URL}/assets/images/logos_empresas/a/vendor_boniviajes.svg`}
            alt=""
          />
        </div>
        <div className="brand__img">
          <img
            className="w-100"
            src={`${process.env.PUBLIC_URL}/assets/images/logos_empresas/a/vendor_ansuz.svg`}
            alt=""
          />
        </div>
        <div className="brand__img">
          <img
            className="w-100"
            src={`${process.env.PUBLIC_URL}/assets/images/logos_empresas/a/vendor_meddi.png`}
            alt=""
          />
        </div>
        <div className="brand__img">
          <img
            className="w-100"
            src={`${process.env.PUBLIC_URL}/assets/images/logos_empresas/a/vendor_totalplay.svg`}
            alt=""
          />
        </div>
      </Slider>
    </div>
  );
};

export default SociosSeguridad;
