import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


const Clientes = () => {
  const settings_brands = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="clientes__container">
      <h5 className="ttl_section">Clientes felices / 2021</h5>
      <Slider className="brand_slider" {...settings_brands}>
        <div className="brand__img">
          <img
            className="w-100"
            src={`${process.env.PUBLIC_URL}/assets/images/logos_empresas/a/vendor_mapfre.svg`}
            alt=""
          />
        </div>
        <div className="brand__img">
          <img
            className="w-100"
            src={`${process.env.PUBLIC_URL}/assets/images/logos_empresas/a/vendor_boniviajes.svg`}
            alt=""
          />
        </div>
        <div className="brand__img">
          <img
            className="w-100"
            src={`${process.env.PUBLIC_URL}/assets/images/logos_empresas/a/vendor_ansuz.svg`}
            alt=""
          />
        </div>
        <div className="brand__img">
          <img
            className="w-100"
            src={`${process.env.PUBLIC_URL}/assets/images/logos_empresas/a/vendor_meddi.png`}
            alt=""
          />
        </div>
        <div className="brand__img">
          <img
            className="w-100"
            src={`${process.env.PUBLIC_URL}/assets/images/logos_empresas/a/vendor_totalplay.svg`}
            alt=""
          />
        </div>
      </Slider>
    </div>
  );
};

export default Clientes;
