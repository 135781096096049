import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
// import emailjs from "emailjs";
import Estados from "../../data/estados/estados";

const SociosForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    alert(JSON.stringify(data));
  };
  console.log(errors);
  return (
    <div className="socios__form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h5 className="text-center">¡Registrate ya!</h5>
        <div className="form-group">
          <input
            type="text"
            required
            className="form-control"
            placeholder="Nombre"
            name="nombre"
            {...register("Nombre", { required: true })}
          />
        </div>

        <div className="form-group">
          <input
            type="text"
            required
            className="form-control"
            placeholder="Apellido paterno"
            name="apellidoP"
            {...register("Apellido paterno", { required: true })}
          />
        </div>

        <div className="form-group">
          <input
            type="text"
            required
            className="form-control"
            placeholder="Apellido materno"
            name="apellidoM"
            {...register("Apellido materno", { required: true })}
          />
        </div>

        <div className="form-group">
          <input
            placeholder="Email"
            name="email"
            type="email"
            required
            className="form-control"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              },
            })}
          />
        </div>

        <div className="form-group">
          <input
            type="text"
            required
            className="form-control"
            placeholder="Telefono"
            name="tel"
            {...register("Telefono", {})}
          />
        </div>

        <div className="form-group">
          <select
            className="form-control"
            required
            id="exampleFormControlSelect1"
            name="estado"
            {...register("Estado", { required: true })}
            defaultValue=""
          >
            <option value="" disabled>
              Estado
            </option>
            {Estados.map((item, i) => (
              <option value={item.clave} key={i}>
                {item.nombre}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <input
            type="text"
            className="form-control"
            required
            placeholder="Ciudad"
            name="ciudad"
            {...register("Ciudad", { required: true })}
          />
        </div>
        <div className="form-group form-check">
          <input
            type="checkbox"
            className="form-check-input"
            required
            id="exampleCheck1"
            placeholder="Acepto Terminos y Condiciones"
            {...register("Acepto Terminos y Condiciones", { required: true })}
          />
          <label className="form-check-label" htmlFor="exampleCheck1">
            Acepto{" "}
            <Link to="/terminos-y-condiciones-del-uso-del-software-de-ubbitt"  target="_blank">
              Términos y Condiciones
            </Link>{" "}
          </label>
        </div>
        <input className="btn btn_form_socios" type="submit" />
      </form>
      <small>
        ¿Ya tienes una cuenta? <br />{" "}
        <Link to="#">Inicia sesión en Soy Ubbitt</Link>
      </small>
    </div>
  );
};

export default SociosForm;
