import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { Container, Nav } from "react-bootstrap";


const NavProductos = () => {
  return (
    
    <Navbar expand="md" className="nav_join sticky-top">
      <Container className="nav_legal_content">
        <Navbar.Brand href="/"><img src={`${process.env.PUBLIC_URL}/assets/images/logo_ubbitt_nav.svg`} alt="" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav_links_container nav_productos">
            {/* <Nav.Link href="/">Store</Nav.Link> */}
            <Nav.Link className="active_menu" href="/empresas">Empresas</Nav.Link>
            <Nav.Link href="/soy-ubbitt">Socios</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default NavProductos;
