import React from "react";
import SEO from "../common/SEO";

// Common
import NavLegal from "../common/header/NavLegal";
import Footer from "../common/footer/Footer";

const Anticorrupcion = () => {
  return (
    <>
      <SEO title="Política de privacidad" />

      <main className="page-wrapper">
        <NavLegal />

        <div className="container">
          <div className="container_legales col-10">
            <h3 className="main_ttl_legal">POLÍTICA ANTICORRUPCIÓN</h3>
            Conocidos y aceptados los Términos que rigen la plataforma de
            UBBITT, damos a conocer nuestra Política Anticorrupción, la cual
            usted consintió en aceptar al momento de utilizar la plataforma.
            <h2>CONTENIDO</h2>
            <h2>I. PROPÓSITO</h2>
            <h2>II. ALCANCE</h2>
            <h2>III. DEFINICIONES</h2>
            <h2>IV. ACTIVIDADES PROHIBIDAS</h2>
            <h2>V. REGALOS, COMIDAS, VIAJES Y ENTRETENIMIENTO</h2>
            <ul>
              <li>v.i.- Prohibiciones</li>
              <li>v.ii.- Prácticas aceptables</li>
              <li>v.iii.- Viajes</li>
              <li>v.iv.- Entretenimiento</li>
              <li>v.v. Donativos</li>
            </ul>
            <h2>VI. SOCIOS COMERCIALES</h2>
            <h2>VII. INFORMACIÓN Y NO REPRESALIAS</h2>
            <h2>VIII. MEDIDAS DISCIPLINARIAS</h2>
            <h2>PROPÓSITO</h2>
            UBBITT, sus subsidiarias y filiales, a través de la presente
            política se compromete en atender el cumplimiento de los
            requerimientos legales anticorrupción, mediante la adopción de una
            filosofía basada en realizar negocios con altos estándares de
            honestidad, integridad, honradez y responsabilidad, como principios
            fundamentales que evidencian el compromiso de actuar apegados a la
            integridad empresarial y trasladándola a todos los miembros de la
            compañía. Mediante este actuar UBBITT asume un compromiso público
            con la gestión y el desempeño responsable mediante un actuar ético y
            transparente ante sus grupos de interés y la conducción de sus
            negocios de una manera socialmente responsable, actuando con una
            filosofía de Cero Tolerancia a actos que contraríen sus principios
            organizacionales.
            <h2>ALCANCE </h2>
            Esta Política es aplicable a todos los colaboradores de UBBITT, así
            como a todas las partes relacionadas y grupos de interés, entendidos
            estos como: clientes, proveedores, accionistas, inversionistas,
            aliados, empleados, contratistas, subcontratistas; y en general a
            todos aquellos con quienes de manera directa o indirectamente se
            establezca alguna relación comercial, contractual, laboral o de
            cooperación.
            <h2>DEFINICIONES</h2>
            Corrupción: Es todo hecho, tentativa u omisión deliberada para
            obtener un beneficio para sí o para terceros en detrimento de los
            principios organizacionales, independiente de los efectos
            financieros sobre las empresas. Soborno: Consiste en ofrecer,
            prometer, dar, aceptar o solicitar una contraprestación, económica o
            de con cualquier otro tipo de cosa de valor, con el propósito de
            dirigir la conducta de alguien para obtener ventaja de índole
            comercial, contractual, reglamentaria o personal, para motivar a
            alguien a abstenerse de realizar un acto relacionado a sus funciones
            o para que abusen de su influencia. El soborno y la corrupción son
            delitos penales que afectan tanto a quienes participan en forma
            activa como pasiva en la comisión de estos hechos, así como a las
            organizaciones a quienes representan. Cosas de valor: Es un concepto
            amplio y puede incluir dinero en efectivo, equivalentes de efectivo
            (por ejemplo, tarjetas de regalo), comidas, entretenimiento, viajes,
            obsequios, empleo, contratos, servicios en especie, o cualquier otro
            tipo de bienes o servicios con valor económico tangible,
            contribuciones a fines benéficos o a otras organizaciones sin ánimo
            de lucro y patrocinios promocionales. Servidor/Funcionario público:
            Cualquier empleado de un órgano de gobierno, electo o designado como
            integrante de los Poderes Ejecutivo, Legislativo y Judicial, órganos
            constitucionales autónomos, empresas en las que tenga participación
            el Estado o agencias de investigación, perteneciente a cualquier
            nivel de gobierno (federal, nacional, estatal, provincial o
            municipal) o cualquier partido político, funcionario de un partido
            político o candidatos para cualquier puesto de elección popular.
            Socio Comercial: Terceros actuando en nombre o por cuenta de la
            Compañía o de cualquier entidad propiedad de, o controlada por la
            Compañía, que puedan interactuar con agentes externos, especialmente
            en el caso en que estos sean Servidores/Funcionarios Públicos; o
            cualquier alianza en la que la Compañía tenga intereses económicos,
            en los que intervengan, entre otros, a modo de ejemplo: agentes,
            brokers, intermediarios, asesores, consultores, representantes,
            socios de jointventures, co-inversores, franquiciados, proveedores
            autorizados, agencias de viaje, transportistas autorizados o agentes
            de aduanas y/o abogados actuando en nombre o por cuenta de la
            Compañía. Colaborador: Cualquier persona vinculada laboralmente con
            la Compañía a través de un contrato de tiempo determinado o
            indeterminado.
            <h2>ACTIVIDADES PROHIBIDAS</h2>
            Cuando de corrupción se trata, la Compañía no tendrá ningún distingo
            entre servidores públicos y particulares: El soborno y la corrupción
            no son tolerados sin importar los involucrados. Ofrecimiento de
            beneficios indebidos. Los colaboradores de UBBITT no deben
            (directamente o a través terceros) ofrecer o realizar sobornos a
            servidores públicos o particulares (ni a los familiares de éstos ni
            a terceros) con la intención de inducirlos a usar su posición o
            poder para ayudar a la Empresa a obtener una ventaja indebida.
            Recepción de beneficios indebidos. Los colaboradores no deben
            solicitar o aceptar (o intentar realizar dichas conductas), ya sea
            de manera directa o indirecta, a través de algún familiar o tercero,
            ninguna cosa de valor de un servidor público o un particular por el
            que, a cambio, pudiera dar como resultado una ventaja aparente e
            indebida. Queda claro, que para la Compañía no existe cantidad
            aceptable para un soborno.
            <h2>REGALOS, COMIDAS, VIAJES Y ENTRETENIMIENTO.</h2>
            <h2>V.I.- Prohibiciones</h2>
            Los colaboradores nunca deben: i) Ofrecer regalos, comidas, viajes o
            entretenimiento a servidores públicos o a particulares (o sus
            familiares o terceros) con la intención de influir o inducir al
            receptor a hacer cualquier cosa que ayude a la Empresa a asegurar o
            mantener cualquier beneficio o ventaja indebidos. Ii) Pedir, recibir
            o aceptar regalos, comidas, viajes o entretenimiento a servidores
            públicos o a particulares (o sus familiares y terceros), sabiendo o
            suponiendo que, a cambio, dichas personas esperan recibir cualquier
            beneficio o ventaja indebidos.
            <h2>V.II.- Prácticas aceptables </h2>
            Los regalos e invitaciones de negocios nunca deben ser ofrecidos o
            aceptados con propósitos ilícitos y deben ser, en todos los casos:
            i) Conformes a las leyes y regulaciones, tanto en el país del que
            realiza la invitación, como del que la recibe, ii) Entregados o
            aceptados sin espera de reciprocidad: la intención o propósito que
            persigue el regalo o invitación debe ser simplemente la de construir
            la relación de negocio dentro de unos estándares normales de
            cortesía y no la de influir en la persona encargada de adoptar una
            determinada decisión de negocio, iii) Aceptables socialmente: otras
            personas (tales como compañeros, competidores o prensa) deberían
            estar de acuerdo en que el regalo o invitación es razonable y que se
            podría comunicar sin temor a un posible reproche social; iv)
            Consistentes con los intereses de los negocios de la Compañía, así
            como con las prácticas habituales del negocio, v) Ocasionales y no
            excesivos, conforme a los estándares locales o de la industria, vi)
            Registrados en documentación precisa, apropiada y con detalle
            razonable. Se deberá tener en cuenta que los clientes o proveedores
            de la Compañía pueden tener sus propias normas internas sobre esta
            materia y que es posible que no coincidan con los estándares
            establecidos en esta norma. En consecuencia, se deberá considerar
            esta circunstancia a la hora de ofrecer regalos o invitaciones a
            consejeros, directivos y empleados de estas empresas con el fin de
            evitar que su propia reputación o la de su empresa puedan verse
            comprometidas. No se considerará irregular la aceptación por parte
            de los consejeros, directivos y empleados de la Compañía, dentro de
            los usos sociales empresariales, de invitaciones a eventos
            promocionales o de negocio, en los que la entidad o la empresa
            organizadora (distinta de cualquier compañía de UBBITT) asuma los
            gastos razonables de desplazamiento, alojamiento y/o manutención,
            siempre que el objeto de dicha invitación sea exclusivamente la
            presentación de productos o servicios de dicha entidad o empresa
            organizadora y la invitación no sea individual, sino dirigida a un
            colectivo de clientes o potenciales clientes.
            <h2>V.III. – Viajes. </h2>
            Por ningún motivo un colaborador pagará vacaciones ni tours de
            ningún tipo a particulares, incluyendo familiares y amigos de éstos;
            sólo podrá invitar un viaje a un particular, incluyendo transporte
            aéreo, hospedaje y transportación local, siempre y cuando: Sea para
            un fin justificado de negocios, como demostrar productos o
            servicios, capacitaciones y conferencias, o para inspecciones de
            instalaciones u operaciones de la Empresa. Los gastos deben ser
            conforme a lo necesario y no deben existir gastos irrelevantes o
            extravagante. En el viaje no deben incluirse amigos ni familiares
            del invitado; El viaje no implique desviarse o aprovechar la visita
            a lugares no planeados (estos desvíos son innecesarios cuando
            requieren extender el viaje original a otros lugares o impliquen
            realizar gastos innecesarios o injustificables). La invitación al
            viaje no incluye entregar efectivo como viático para gastar por día.
            <h2>V.IV.- Entretenimiento.</h2>
            Un colaborador puede obsequiar boletos para eventos de
            entretenimiento a un particular, siempre que: El colaborador esté
            presente durante el evento; El valor del boleto del evento no exceda
            a lo irracional o extravagante. El lugar no sea inapropiado, y La
            finalidad del evento sea para promover, demostrar o explicar los
            productos o servicios de la Empresa. Un colaborador puede aceptar
            boletos para eventos de entretenimiento de un particular, siempre y
            cuando al evento asista la persona que le hizo la invitación.
            Cualquier evento de entretenimiento que reciba un colaborador debe
            ser reportado a la Dirección de la empresa.
            <h2>V.V.- Donativos </h2>
            La Empresa no usará donativos como un medio para encubrir actos de
            corrupción o soborno.
            <h2>SOCIOS COMERCIALES </h2>
            La Compañía dispone de diversas normas que establecen disposiciones
            obligatorias sobre las relaciones con proveedores y otros Socios
            Comerciales. La Compañía no contratará o realizará negocios con un
            Socio Comercial si cree que hay riesgo de que éste vaya a violar las
            leyes aplicables en materia de anticorrupción o las prohibiciones
            establecidas en esta Política. Antes de establecer una relación de
            negocios con cualquier Socio Comercial que vaya a interactuar en
            nombre de la Compañía, con especial énfasis en caso de interactuar
            con Funcionarios/Empleado Públicos, el área de Compras (en el caso
            de negociaciones dentro de su ámbito de actuación) o el área de
            Clientes (en contrataciones fuera del ámbito del área de Compras),
            deberán realizar una valoración apropiada del Socio Comercial a este
            respecto y se asegurarán de que: (a) el contrato con el Socio
            Comercial contiene obligaciones y garantías anticorrupción, así como
            el derecho de de dar por terminado el contrato en caso de
            incumplimiento de dichas obligaciones y garantías; (b) el Socio
            Comercial certifica el cumplimiento de las leyes en materia de
            anticorrupción.
            <h2>INFORMACIÓN Y NO REPRESALIAS</h2>
            Todos los colaboradores y/o empleados de UBBITT, así como socios
            comerciales y proveedores que tengan conocimiento o sospecha de que
            esta política pueda haber sido violada deberán notificar a la
            Dirección de Recursos Humanos y/o a la Gerencia Legal de forma
            inmediata. La empresa no tolerará represalias contra las personas
            que informen de buena fe. Toda persona que tenga conocimiento de lo
            que cree ser una forma de represalia debe informar a la Dirección de
            Recursos Humanos y/o a la Gerencia Legal.
            <h2>MEDIDAS DISCIPLINARIAS</h2>
            Los empleados de UBBITT que violen esta política estarán sujetos a
            medidas disciplinarias, que pueden incluir el despido y también
            pueden ser objeto de enjuiciamiento penal individual y / o
            enjuiciamiento civil en las jurisdicciones pertinentes. Así mismo,
            los socios empresariales y/o colaboradores que violen esta política
            están sujetos a la terminación de todas las relaciones comerciales
            con UBBITT, sin perjuicio de las acciones penales o civiles que
            puedan ejercerse.
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Anticorrupcion;
