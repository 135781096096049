import React, { Component } from "react";

class Freemium extends Component {
  render() {
    return (
      <>
        
        <div className="meetings-iframe-container" data-src="https://meetings.hubspot.com/paulina-valdez/demo-freemium?embed=true"></div>
      </>
    );
  }
}

export default Freemium;
