import React from "react";

const Solucion = () => {
  return (
    <div className="soluction__container">
      <p className="subttl_section">
        <span>// </span>
        Solución
      </p>
      <h5 className="ttl_section">¿Cómo funciona?</h5>
     
      <p className="info">
        Nuestra plataforma crea campañas de adquisición de ventas para
        proveedores de servicios intangibles de industrias como seguros, banca,
        finanzas, telecomunicaciones, insurtechs, fintechs, viajes, entre otras.
      </p>
      <p className="info">
        Los socios expertos de ventas funcionan como una fuerza de ventas On
        Demand a gran escala lista para lograr los objetivos de ventas masivos
        más ambiciosos.
      </p>
      <p className="info">
        
        Nuestro principal objetivo es crear una Experiencia Centrada en el
        cliente diseñada para el usuario final. Fácil, amigable y rápida para
        adquirir cualquier servicio de nuestro Marketplace.
      </p>
      <p className="info">
        Nuestra plataforma otorga agilidad a las industrias de servicios de gran
        escala, reduciendo los costos de adquisición de clientes, mejorando y
        acelerando el flujo de caja de ventas, cartera de clientes y cobranza.
      </p>
      <img
            className="como_funciona-img"
            src={`${process.env.PUBLIC_URL}/assets/images/view_empresas/como_funciona.svg`}
            alt=""
          />
    </div>
  );
};

export default Solucion;
